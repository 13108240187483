import restApi from "@axios";

export default {
  buscarEscalas({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING");
      restApi
        .get("/escalas/initialize")
        .then((response) => {
          commit("SET_LOADING");
          if (response.data) {
            commit("setEscalas", response.data.data.escalas);
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => reject(error));
  },
  buscarEscalaSelecionada({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      restApi
        .get(`/escalas/buscar-escala-selecionada/${uuid}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  /**
   * @description Busca por todos os dados e trás somente aqueles que passarem na validação (Se houver) e retorna somente as colunas solicitadas.
   *
   * @param data Deverá conter obrigatoriamente uma propriedade chamada **columns** que será um array de strings, sendo as strings o nome das colunas que deseja ser retornadas.
   * @example - { columns: ['id', 'nome'], (optional) where: [['id_empresa', 1]], (optional) orWhere: [['id_empresa', 2]] }
   *
   * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
   */
  getDataByColumn({ commit }, data) {
    commit("SET_LOADING");
    return new Promise(function (resolve, reject) {
      restApi
        .post("/escalas/get-data-by-column", data)
        .then((response) => {
          if (response.data) {
            commit("setEscalas", response.data.data);
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        })
        .finally(() => {
          commit("SET_LOADING");
        });
    });
  },
  salvarEscala({ commit }, data) {
    return new Promise((resolve, reject) => {
      restApi
        .post(data.url, data)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  deletarEscala({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      restApi
        .delete(`/escalas/${uuid}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  setEscala({ commit }, data) {
    commit("setEscala", data);
  },
};
