import restApi from '@axios'

export default {
    setCompetencia({ commit }, data) {
        commit('setCompetencia', data)
    },
    initialize({ commit }) {
        return new Promise(function (resolve, reject) {
            restApi.get('/avd/competencia/initialize').then(response => {
                if (response.data) {
                    commit('setCompetencias', response.data.data)
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },
    findByUuid({ commit }, uuid) {
        return new Promise(function (resolve, reject) {
            restApi.get(`/avd/competencia/find-by-uuid/${uuid}`).then(response => {
                if (response.data) {
                    commit('setCompetencia', response.data.data)
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },
    findByColumns({ commit }, data) {
        return new Promise(function (resolve, reject) {
            restApi.post("/avd/competencia/find-by-columns", data).then(response => {
                if (response.data) {
                    commit('setCompetencias', response.data.data)
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },
    /**
     * @description Busca por todos os dados da competência e relacionados.
    */
    getAllData({ commit }, uuid) {
        return new Promise(function (resolve, reject) {
            restApi.get(`/avd-competencia/get-all-data/${uuid}`).then(response => {
                if (response.data) {
                    commit('setCompetencia', response.data.data)
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            })
        })
    },
    save({ commit }, data) {
        return new Promise(function (resolve, reject) {
            restApi.post(data.url, data).then(response => {
                if (response.data) {
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },
    remove({ commit }, uuid) {
        return new Promise(function (resolve, reject) {
            restApi.delete(`/avd-competencia/${uuid}`).then(response => {
                if (response.data) {
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },

    // Função do comportamentos
    removeComportamento({ commit }, data) {
        return new Promise(function (resolve, reject) {
            restApi.delete(`/avd-comportamento/${data.id}`).then(response => {
                if (response.data) {
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    }
}