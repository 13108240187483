import restApi from '@axios';

export default {
  initialize({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING");
      restApi
        .get("/propostas/initialize")
        .then((response) => {
          commit("SET_LOADING");
          if (response.data) {
            commit("setPropostas", response.data.data.propostas);
            commit("setProdutos", response.data.data.produtos);

            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => reject(error));
  },
  buscarMotivos({ commit }) {
    return new Promise((resolve, reject) => {
      restApi
        .get("/motivos/initialize")
        .then((response) => {
          if (response.data) {
            commit("setMotivos", response.data.data.motivos);
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  save({ commit }, proposta) {
    return new Promise((resolve, reject) => {
      restApi
        .post("/propostas", proposta)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  edit({ commit }, proposta) {
    return new Promise((resolve, reject) => {
      restApi
        .post(`/propostas/save`, proposta)
        .then((response) => {
          if (response.data) {
            console.log(response.data.data);
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  deletarProposta({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      restApi
        .delete(`/propostas/${uuid}`)
        .then((response) => {
          if (response.data) {
            commit("deletarProposta", uuid);
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  getPropostaByUuid({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      restApi
        .get(`/propostas/get-proposta-uuid/${uuid}`)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  findByColumns({ commit }, data) {
    return new Promise((resolve, reject) => {
      restApi
        .post(`/propostas/find-by-columns`, data)
        .then((response) => {
          if (response.data) {
            commit('setPropostas', response.data.data);
            resolve(response.data.data);
          } else {
            resolve('NAO_AUTORIZADO')
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  }
};
