export default {
  cargo: {
    nome: '',
    id_filial: null,
    id_setor: null,
    salario: '',
    descricao: '',
    state: 'A'
  },
  cargos: [],
  cargosOfByColumns: [],
};
