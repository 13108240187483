export default {
  SALVAR_MOTIVO(state, data) {
    state.dataMotivo.push(data);
  },
  SET_MOTIVOS(state, data) {
    state.dataMotivo = data;
  },
  SET_LISTA_SUBMOTIVOS(state, data) {
    state.listaSubmotivos = data;
  },
  SET_LOADING(state) {
    state.loading = !state.loading;
  },
};
