export default {
  getPergunta(state) {
    return state.pergunta;
  },

  // Get de perguntas do modelo
  getPerguntasFatoresExternos: (state) => {
    return state.perguntas.FE;
  },
  getPerguntasFavorabilidade: (state) => {
    return state.perguntas.FI.FAV;
  },
  getBeneficios: (state) => {
    return state.perguntas.FI.BEN;
  },
  getDesmotivacional: (state) => {
    return state.perguntas.FI.DES;
  },
  getMotivacional: (state) => {
    return state.perguntas.FI.MOT;
  },
  getAllPerguntas: (state) => {
    return state.perguntas;
  },
};
