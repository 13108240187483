import restApi from '@axios'

export default {
    initialize({ commit }) {
        return new Promise(function (resolve, reject) {
            restApi.get('papel/initialize').then(response => {
                if (response.data) {
                    commit('setPapeis', response.data.data)
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    }
}