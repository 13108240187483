import SecureLS from "secure-ls";
const ls = new SecureLS();

export default {
  getMenus: (state) => {
    return ls.get("menu") || state.menus;
  },
  getMenusPorColunas: (state) => {
    return state.menusPorColunas;
  },
  getItensMenus: (state) => {
    return state.itensMenus;
  },
  getLoading: (state) => {
    return state.loadingMenu;
  },
  getGruposAcesso: (state) => {
    return state.gruposAcesso;
  },
  getEmpresas: (state) => {
    return state.empresas;
  },
  getEquipes: (state) => {
    return state.equipes;
  },
  getSetores: (state) => {
    return state.setores;
  },
  getCargos: (state) => {
    return state.cargos;
  },
  getPapeis: (state) => {
    return state.papeis;
  },
  getLoadingEquipes: (state) => {
    return state.loadingEquipes;
  },
  getLoadingCargos: (state) => {
    return state.loadingCargos;
  },
  getLoadingSetores: (state) => {
    return state.loadingSetores;
  },
  getLoadingPapeis: (state) => {
    return state.loadingPapeis;
  },
  getPermissoes: (state) => {
    return state.permissoes;
  },
  getLoadingPermissoes: (state) => {
    return state.loadingPermissoes;
  },
  getListaMenus: (state) => {
    return state.listaMenus;
  },
  getLoadingGrupoAcesso: (state) => {
    return state.loadingGrupoAcesso;
  },
  getTabsMenu: (state) => {
    return state.tabsMenu;
  },
};
