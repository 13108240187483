// import SecureLS from "secure-ls";

// let ls = new SecureLS();

export default {
  avaliacao: {
    titulo: "",
    descricao: "",
    id_cliente: null,
    id_responsavel: null,
    id_filial: null,
    id_escala: null,
    periodicidade: "",
    data_hora_inicio: "",
    dataFinal: "",
    avaliacoes: {},
    tecnica: 'I',
    comportamental: 'I',
    tipo_cliente: ""
  },
  fullDataAvaliacao: {
    avaliacao: {},
    colaboradores: [],
  },
  selecaoIdsFiliaisSetoresCargosTimes: {
    filiais: [],
    setores: [],
    cargos: [],
    times: [],
  },
  colaboradores: [],
  filiais: [],
  minhasAvaliacoes: [],
  avaliacoes: [],
  dataPainelDeBordo: [],
  dataCompetencias: [],
  tabsPainel: [
    {
      id: 1,
      nome: "Minhas avaliações",
      permissao: true,
      // ls.get("userKey").user && ls.get("userKey").user.role == "colaborador"
      //   ? true
      //   : true,
      componente: () =>
        import("../../../modules/avd/painel/components/tabs/MinhasAvaliacoes"),
    },
    {
      id: 2,
      nome: "Avaliações",
      permissao: true,
      // ls.get("userKey").user && ls.get("userKey").user.role == "colaborador"
      //   ? false
      //   : true,
      componente: () =>
        import("../../../modules/avd/painel/components/tabs/Avaliacoes"),
    },
    {
      id: 3,
      nome: "Painel de bordo",
      permissao: true,
      // ls.get("userKey").user && ls.get("userKey").user.role == "colaborador"
      //   ? false
      //   : true,
      componente: () =>
        import("../../../modules/avd/painel/components/tabs/PainelDeBordo"),
    },
  ],
  permissaoDoUsuario: {
    role: "Colaborador",
    // ls.get("userKey").user && ls.get("userKey").user.role
    //   ? ls.get("userKey").user.role
    //   : "",
  },
  dadostotalizadores: [],
  totalizadores: [
    {
      key: 1,
      titulo: "Agendadas",
      color: "#23D4AA",
    },
    {
      key: 2,
      titulo: "Abertas",
      color: "#4B95EC",
    },
    {
      key: 3,
      titulo: "Canceladas",
      color: "#EE2C2C",
    },
    {
      key: 4,
      titulo: "Concluídas",
      color: "#18B744",
    },
  ],
  tabPainel: [],
  tabsVisualizarAvaliacao: [
    {
      id: 1,
      nome: "Visão geral",
      componente: () =>
        import(
          "./../../../modules/avd/painel/components/tabs/components/componentsVisualizarAvaliacao/VisaoGeralComponent.vue"
        ),
    },
    {
      id: 2,
      nome: "Autoavaliação",
      componente: () =>
        import(
          "./../../../modules/avd/painel/components/tabs/components/componentsVisualizarAvaliacao/AutoavaliacaoComponent.vue"
        ),
    },
    {
      id: 3,
      nome: "Avaliação de pares",
      componente: () =>
        import(
          "./../../../modules/avd/painel/components/tabs/components/componentsVisualizarAvaliacao/AvaliacaoParesComponent.vue"
        ),
    },
    {
      id: 4,
      nome: "Avaliação de líder",
      componente: () =>
        import(
          "./../../../modules/avd/painel/components/tabs/components/componentsVisualizarAvaliacao/AvaliacaoLiderComponent.vue"
        ),
    },
    {
      id: 5,
      nome: "Avaliação de gestor",
      componente: () =>
        import(
          "./../../../modules/avd/painel/components/tabs/components/componentsVisualizarAvaliacao/AvaliacaoGestorComponent.vue"
        ),
    },
  ],
  tabsVisualizarConfiguracaoAvaliacao: [
    // {
    //   id: 1,
    //   nome: "Informações gerais",
    //   componente: () =>
    //     import(
    //       "../../../modules/avd/avaliacao/components/tabs/InformacoesGeraisComponent.vue"
    //     ),
    // },
    // {
    //   id: 2,
    //   nome: "Colaboradores",
    //   componente: () =>
    //     import(
    //       "./../../../modules/avd/avaliacao/components/tabs/ColaboradoresComponent.vue"
    //     ),
    // },
    {
      id: 1,
      name: "Painel",
      scopeValidation: "PAINEL",
      disabled: false,
      component: () =>
        import("@/modules/avd/avaliacao/components/tabs/PainelComponent"),
    },
    {
      id: 2,
      name: "Avaliações",
      scopeValidation: "AVALIACOES",
      disabled: true,
      // component: () => import("@/modules/avd/avaliacao/components/tabs/AvaliacoesComponent"),
    },
    {
      id: 3,
      name: "Setores",
      scopeValidation: "SETORES",
      disabled: false,
      component: () =>
        import("@/modules/avd/avaliacao/components/tabs/SetoresComponent.vue"),
    },
    {
      id: 4,
      name: "Cargos",
      scopeValidation: "CARGOS",
      disabled: false,
      component: () =>
        import("@/modules/avd/avaliacao/components/tabs/CargosComponent.vue"),
    },
    {
      id: 5,
      name: "Colaboradores",
      scopeValidation: "COLABORADORES",
      disabled: false,
      component: () =>
        import(
          "@/modules/avd/avaliacao/components/tabs/ColaboradoresComponent"
        ),
    },
    {
      id: 6,
      name: "Competências",
      scopeValidation: "COMPETENCIAS",
      disabled: false,
      component: () =>
        import(
          "@/modules/avd/avaliacao/components/tabs/CompetenciasComponent.vue"
        ),
    },
    {
      id: 7,
      name: "Notificações",
      scopeValidation: "NOTIFICACOES",
      disabled: true,
      component: () =>
        import("@/modules/avd/avaliacao/components/tabs/NotificacoesComponent"),
    },
    {
      id: 8,
      name: "Configurações",
      scopeValidation: "CONFIGURACOES",
      disabled: true,
      component: () =>
        import(
          "@/modules/avd/avaliacao/components/tabs/ConfiguracoesComponent"
        ),
    },
  ],
  dataVisualizarAvaliacao: [],
  responsaveis: [],
  filiais: [],
  setores: [],
  cargos: [],
  times: [],
  colaboradores: [],
  statusAvaliacao: {
    A: { text: "Ativo", value: "A", color: 'accent' },
    I: { text: "Inativo", value: "I", color: 'secondary' },
    E: { text: "Edição", value: "E", color: 'warning' },
    F: { text: "Finalizado", value: "F", color: 'success' },
  },
  tiposAvaliacao: {
    AP: {
      id: 1,
      nome: "Avaliação de pares",
    },
    AA: {
      id: 2,
      nome: "Auto avaliação",
    },
    AL: {
      id: 3,
      nome: "Avaliação do Líder",
    },
    AG: {
      id: 4,
      nome: "Avaliação do Gestor",
    },
    AC: {
      id: 5,
      nome: "Avaliação do Cliente",
    },
  },
  filiaisDaAvaliacao: [],
  setoresDaAvaliacao: [],
  timesDaAvaliacao: [],
  cargosDaAvaliacao: [],
  uuidDaAvaliacao: "",
  // Comportamento

  comportamentos: [],

  // Competencias
  competencias: [],

  // Gerais
  loading: false,

  // Modelo
  modelos: [],
  modeloSelecionado: {},
  AvdComportamental: [],
  AvdTecnica: [],
  competenciasComportamentais: [],
  comportamentosComportamentais: [],
  competenciasComportamentaisSelect: [],
  competenciasTecnicas: [],
  competenciasTecnicasSelect: [],
  comportamentosTecnicas: [],
  tabsForm: [
    {
      id: 1,
      name: "Configurações",
      ref: "AVD",
      scopeValidation: "AVD",
      disabled: false,
      icon: 'mdi-cog',
      component: () =>
        import("@/modules/avd/avaliacao/components/tabs/forms/AvaliacaoComponent"),
    },
    {
      id: 2,
      name: "Colaboradores",
      ref: "CONFIG",
      scopeValidation: "CONFIG",
      disabled: false,
      icon: 'mdi-account-group',
      component: () =>
        import("@/modules/avd/avaliacao/components/tabs/forms/ColaboradoresComponent"),
    },
    {
      id: 3,
      name: "Configuração Comportamental",
      ref: "CONCOM",
      scopeValidation: "CONCOM",
      disabled: true,
      icon: 'mdi-file-account',
      component: () =>
        import("@/modules/avd/avaliacao/components/tabs/forms/ComportamentalColaboradores"),
    },
    {
      id: 4,
      name: "Comportamental",
      ref: "COM",
      scopeValidation: "COM",
      disabled: true,
      icon: 'mdi-account-arrow-up-outline',
      component: () =>
        import("@/modules/avd/avaliacao/components/tabs/forms/ComportamentalComponent"),
    },
    {
      id: 5,
      name: "Técnica",
      ref: "TEC",
      scopeValidation: "TEC",
      disabled: true,
      icon: 'mdi-account-arrow-right-outline',
      component: () =>
        import("@/modules/avd/avaliacao/components/tabs/forms/TecnicaComponent"),
    }
  ],
  tabFormSelected: 1,
  currentStepper: 1,
  isShowStepper: true

  // Formulário
  // modeloRegistro: []
};
