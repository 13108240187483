import PropostaRouter from "@/modules/administrativo/modules/propostas/router"
import EmpresaRouter  from "@/modules/administrativo/modules/empresas/router"; 
import ConfiguracoesModulesRouter from '@/modules/administrativo/modules/configuracoes/modules/router';
import MotivosModulesRouter from '@/modules/administrativo/modules/motivos/router'
import SubmotivosModulesRouter from '@/modules/administrativo/modules/submotivos/router'

const routes = [
    {
        path: '/adm/dashboard',
        name: 'dashboard-adm-dev',
        meta: {
            layout: "content",
            requiresAuth: true,
            resource: 'dashboard-adm-dev',
            action: 'access',
        },
        component: () => import(`@/modules/administrativo/views/Dashboard.vue`)
    },
    {
        path: '/adm/painel',
        name: 'painel-adm',
        meta: {
            layout: "content",
            requiresAuth: true,
            resource: 'dashboard-adm',
            action: 'access',
        },
        component: () => import(`@/modules/administrativo/views/PainelAdm.vue`)
    },
    ...PropostaRouter,
    ...EmpresaRouter,
    ...ConfiguracoesModulesRouter,
    ...MotivosModulesRouter,
    ...SubmotivosModulesRouter

]

export default routes