export default {
  getCargo: (state) => {
    return state.cargo
  },
  getCargos: (state) => {
    return state.cargos
  },
  getCargosOfByColumns: (state) => {
    return state.cargosOfByColumns;
  },
};
