
export default {
    setCandidatoFormacao(state, data) {
        state.candidatoFormacao = data
    },
    setCandidatosFormacoes(state, data) {
        state.candidatosFormacoes = data
    },
    setQuantidadeFormacao(state, data) {
        state.quantidadeFormacao = data
    }
}