import Vue from "vue";
import moment from "moment";
import { VueMaskFilter } from "v-mask";

function formatNumberInCurrency(value, locale = 'pt-BR', options = { style: 'currency', currency: 'BRL' }) {
  if (value == undefined || value == null || value.toString().trim() == "") {
    return new Intl.NumberFormat(locale, options).format(Number(0));
  }

  return new Intl.NumberFormat(locale, options).format(Number(value));
}

Vue.filter('VMask', VueMaskFilter);

Vue.filter("capitalize", (value) => {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1).toLocaleLowerCase();
});

Vue.filter("numbersOnly", (value) => {
  return value.toString().replace(/\D+/g, "");
});

Vue.filter("cpfMask", (value) => {
  return (
    value.substr(0, 3) +
    "." +
    value.substr(3, 3) +
    "." +
    value.substr(6, 3) +
    "-" +
    value.substr(9, 2)
  );
});

Vue.filter("cnpjMask", (value) => {
  if (!value) return;

  return value
    .toString()
    .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
});

Vue.filter("cpfMaskWithAsterisk", (value) => {
  return "***" + "." + "***" + "." + "***" + "-" + value.substr(9, 2);
});

Vue.filter("dateFormatBrForTable", (value, originalFormat) => {
  let date = moment(value, originalFormat, true);
  if (date.isValid()) {
    return date.format("DD/MM/YY");
  }
  return value;
});

Vue.filter("dateFormatBr", (value, originalFormat) => {
  let date = moment(value, originalFormat, true);
  if (date.isValid()) {
    return date.format("DD/MM/YYYY");
  }
  return value;
});

Vue.filter("dateMonthFormatBr", (value, originalFormat) => {
  let date = moment(value, originalFormat, true);
  if (date.isValid()) {
    return date.format("MM/YYYY");
  }
  return value;
});

Vue.filter("dateFormatDatabase", (value, originalFormat) => {
  let date = moment(value, originalFormat, true);
  if (date.isValid()) {
    return date.format("YYYY-MM-DD");
  }
  return value;
});

Vue.filter("dateMonthFormatDatabase", (value, originalFormat) => {
  let date = moment(value, originalFormat, true);
  if (date.isValid()) {
    return date.format("YYYY-MM");
  }
  return value;
});

Vue.filter("dateTimeFormatBr", (value, originalFormat, format = "DD/MM/YYYY HH:mm:ss", locale = "pt-br") => {
  moment.locale(locale);

  let date = moment(value, originalFormat, true);
  if (date.isValid()) {
    return date.format(format);
  }
  return "";
});

Vue.filter("timeFormatBr", (value, originalFormat) => {
  let time = moment(value, originalFormat, true);
  if (time.isValid()) {
    return time.format("HH:mm");
  }
  return value;
});

Vue.filter("timeFormatDatabase", (value, originalFormat) => {
  let time = moment(value, originalFormat, true);
  if (time.isValid()) {
    return time.format("HH:mm:ss");
  }
  return value;
});

Vue.filter("numberFormatBr", (value) => {
  return formatNumberInCurrency(value);
});

Vue.filter("numberRacionalFormatBr", (value) => {
  if (value == undefined || value == null || value.toString().trim() == "") {
    return new Intl.NumberFormat("pt-BR").format(Number(0));
  }
  return new Intl.NumberFormat("pt-BR").format(Number(value));
});

Vue.filter("currencyToDouble", (value) => {
  return parseFloat(
    value
      .toString()
      .replace(/[^0-9,]+/g, "")
      .replace(",", ".")
  );
});

Vue.filter("onlyOneSpace", (value) => {
  value = value.trim();
  return value.replace(/\s\s+/g, " ");
});

Vue.filter("roleTypeToPtBr", (value) => {
  value = value.trim();
  const roleTypes = {};
  roleTypes["CLT"] = { name: "CLT" };
  roleTypes["INTERNSHIP"] = { name: "Estágio" };
  roleTypes["APPRENTICE"] = { name: "Aprendiz" };
  roleTypes["CNPJ"] = { name: "CNPJ" };
  return roleTypes[value].name;
});

Vue.filter("filterTipoInstituicao", (value) => {
  const retorno = !value
    ? null
    : value
      .toString()
      .split(",")
      .map((el) => {
        return el
          .split("_")
          .map(
            (el2) => el2.charAt(0).toUpperCase() + el2.slice(1).toLowerCase()
          )
          .join(" ");
      })
      .join(", ");

  return retorno;
});

Vue.filter("yesOrNo", (value) => {
  value = value.trim();
  const yesOrNoArray = {};
  yesOrNoArray["S"] = { name: "Sim" };
  yesOrNoArray["N"] = { name: "Não" };
  return yesOrNoArray[value].name;
});

Vue.filter("statusToPtBr", (value) => {
  value = value.trim();
  const yesOrNoArray = {};
  yesOrNoArray["A"] = { name: "Ativo" };
  yesOrNoArray["I"] = { name: "Inativo" };
  return yesOrNoArray[value].name;
});

Vue.filter("payrollPostingTypesAction", (value) => {
  value = value.trim();
  const types = {};
  types["CREDIT"] = { name: "Crédito" };
  types["DISCOUNT"] = { name: "Desconto" };
  return types[value].name;
});

Vue.filter("secondsToMinutes", (value) => {
  return moment.utc(value * 1000).format("mm:ss");
});

Vue.filter("hideDigits", (value) => {
  return "****" + value.substr(-4);
});

Vue.filter("truncate", (text, length, clamp) => {
  clamp = clamp || "...";
  var node = document.createElement("div");
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
});

Vue.filter("secondsToHourMinutesSeconds", (value) => {
  var horas = Math.floor(value / 3600);
  var minutos = Math.floor((value % 3600) / 60);
  var segundos = value % 60;
  minutos = minutos < 10 ? "0" + minutos : minutos;
  segundos = segundos < 10 ? "0" + segundos : segundos;
  horas = horas < 10 ? "0" + horas : horas;

  return horas + ":" + minutos + ":" + segundos;
});

Vue.filter("numberFormatBrWithoutCurrency", (value) => {
  return new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" })
    .format(Number(value))
    .replace("R$", "");
});

Vue.filter("unixTimeStampToDateBr", (value) => {
  let dateTime = new Date(value * 1000).toISOString(true).split("T");
  let newDate = moment(`${dateTime[0]}`, "YYYY-MM-DD", true);
  let hour = dateTime[1].substr(0, 8);
  if (newDate.isValid() && hour) {
    return `${newDate.format("DD/MM/YYYY")} ${hour}`;
  }
  return value;
});

Vue.filter("maskForCel", (value) => {
  if (!value) return '';

  value = value.replace(/\D/g, ""); //Remove tudo o que não é dígito
  value = value.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
  value = value.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos

  return value;
});

Vue.filter("maskForTel", (value) => {
  if (!value) return '';

  value = value.replace(/\D/g, ""); //Remove tudo o que não é dígito
  value = value.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
  value = value.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos

  return value;
});

Vue.filter("removeTagsOfText", (value) => {
  if (!value) return "";

  return value.replace(/(<([^>]+)>)/gi, "");
});

Vue.filter("doubleToCurrency", (value) => {
  if (value == 0) {
    return "0,00";
  }

  value = parseFloat(value);
  value = value.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  value = value.split(".").join("*").split(",").join(".").split("*").join(",");
  return value;
});

Vue.filter("nomeAbreviado", (value) => {
  if (!value) return '';

  const arrayNome = value.split(' ')

  let nomeAbreviado;

  if (arrayNome.length == 1) {
    nomeAbreviado = arrayNome[0]
  } else {
    nomeAbreviado = `${arrayNome[0]} ${arrayNome[arrayNome.length - 1]}`
  }

  return nomeAbreviado
});

Vue.filter('processFloat', (value, format = String) => {
  if (!value) return '0';

  let result = ''
  switch (format) {
    case 'RE':
      result = formatNumberInCurrency(value);
      break
    case 'DO':
      const optionsDO = { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol' };

      result = formatNumberInCurrency(value, 'en-US', optionsDO);
      break
    case 'UN':
      const optionsUN = {}

      result = formatNumberInCurrency(value, 'pt-BR', optionsUN);
      break
    case 'PO':
      const optionsPO = {
        style: 'percent',
        minimumFractionDigits: 1,
        maximumFractionDigits: 2
      };

      result = formatNumberInCurrency(value, 'en-GB', optionsPO);
      break
  }

  return result;
})