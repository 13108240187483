import moment from "moment";

// Function Internal
function formatNumberInCurrency(value, locale = 'pt-BR', options = { style: 'currency', currency: 'BRL' }, noSymbol = false) {
  if (value == undefined || value == null || value.toString().trim() == "") {
    value = 0;
  }

  let numberFormatted = new Intl.NumberFormat(locale, options).format(Number(value));

  if (noSymbol) {
    numberFormatted = numberFormatted.replace(/\u00A0/g, ' ').split(' ')[1]
  }

  return numberFormatted
}


export function currencyBrToNumber(string) {
  if (!string) return 0;

  return Number(
    string
      .replace(/[^0-9,-]+/g, "")
      .replace(",", ".")
      .trim()
  );
}
export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export function convertCurrencyToDouble(string) {
  string = string.split(".").join("");
  string = string.split(",").join(".");
  return Number(string.replace(/[^0-9.]/g, ""));
}

export function numbersOnly(string) {
  return string.toString().replace(/\D+/g, "");
}

/**
 * @description Captura a data atual e devolve em formato padrão da linguagem.
 * @example Irá retornar 2022-01-01
 *
 * @author Matheus E. França <matheusefranca1727@gmail.com>
 */
export function getDate() {
  const date = new Date();

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  const fullDate = `${year}-${month}-${day}`;

  return fullDate;
}
export function getDateTimeNow() {
  const date = new Date();

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const fullDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return fullDate;
}

/**
 * @description Captura a data atual e adiciona dia para posterior. Necessário a biblioteca MomentJs
 * @example Irá retornar 2022-01-01
 *
 * @author Matheus E. França <matheusefranca1727@gmail.com>
 */
export function getLaterDate(daysLater) {
  return moment().locale("pt-br").add(daysLater, "days").format("YYYY-MM-DD");
}
/**
 * @description Recebe por padrão uma data e adiciona a quantidade de dias
 * Necessário a biblioteca MomentJs
 * @param String Date
 * @param Int Days
 * @author Johnny Santos <johnny.santos@sociisrh.com.br>
 */
export function setDayLaterDate(value, daysLater) {
  if (value != undefined || value != "") {
    let date = moment(value);
    return date.add(daysLater, "days").format("YYYY-MM-DD");
  }
  return "";
}

/**
 * @description Retorna data e time atual
 * @example 0000-01-01 00:00:00
 *
 * @author Johnny Santos <johnny.santo@sociisrh.com.br>
 */
export function getCurrentyDateTime() {
  return moment().locale("pt-br").format("YYYY-MM-DD HH:mm:ss");
}

/**
 * @description Pega o dia passado e retorna o dia processado em formato pt-br.
 * @example 22-08-2022
 *
 * @param String day
 * @param String trace
 *
 * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
 */
export function getCurrentyDatePtBr(day, trace = "/") {
  return moment(day).locale("pt-br").format(`DD${trace}MM${trace}YYYY`);
}

/**
 * @description Verificar se uma data é posterior a outra data
 * @author Johnny Santos <johnny.santo@sociisrh.com.br>
 */
export function getCompareDate(dataOne, dateTwo) {
  return moment(dateTwo).isAfter(dataOne);
}

/**
 * Parse a localized number to a float.
 * @param {string} stringNumber - the localized number
 * @param {string} locale - [optional] the locale that the number is represented in. Omit this parameter to use the current locale.
 * 
 * @author naitsirch <https://stackoverflow.com/users/1119601/naitsirch>
 */
export function parseLocaleNumber(stringNumber, locale) {
  const thousandSeparator = Intl.NumberFormat(locale)
    .format(11111)
    .replace(/\p{Number}/gu, "");
  const decimalSeparator = Intl.NumberFormat(locale)
    .format(1.1)
    .replace(/\p{Number}/gu, "");

  return parseFloat(
    stringNumber
      .replace(new RegExp("\\" + thousandSeparator, "g"), "")
      .replace(new RegExp("\\" + decimalSeparator), ".")
  );
}

/**
 * @description Retira a mascará do cpf
 * 
 * @param value - String
 * 
 * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
*/
export function unMaskCpf(value = String) {
  if (!value) return '';

  return value.replace(/\D/g, '')
}


/**
 * @description Retira a mascará do celular
 * 
 * @param value - String
 * 
 * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
*/
export function unMaskPhone(value = String) {
  if (!value) return '';

  return value.replace(/\D/g, '')
}

/**
 * @description Desmascara um número e retorna um float
 * 
 * @param value - String 
 * @param format - String
 * 
 * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
*/
export function unMaskNumber(value = String, format = String) {
  if (!value) return 0;

  switch (format) {
    case 'RE':
      value = value.split(".").join("");
      value = value.split(",").join(".");
      break
    case 'DO':
      value = value.split(",").join("");
      break
    case 'UN':
      value = value.split(".").join("");
      break
    case 'PO':
      value = value.split(",").join(".");
      break
  }

  return Number(value.replace(/[^0-9.]/g, ""));
}

/**
 * @description Através da data formatada recebida, a mesma desmascara a data retornando ao padrão.
 * 
 * @param value - String 
 * @param formatDefault - String
 * 
 * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
*/
export function unMaskDate(value = String, formatDefault = 'DD/MM/YYYY') {
  let date = moment(value, formatDefault, true);

  if (date.isValid()) {
    return date.format("YYYY-MM-DD");
  }

  return null;
}

export function processFloat(value, format = String) {
  if (!value) return '0';
  const noSymbol = true

  let result = ''
  switch (format) {
    case 'RE':
      const optionsRE = { style: 'currency', currency: 'BRL' }

      result = formatNumberInCurrency(value, 'pt-BR', optionsRE, noSymbol);
      break
    case 'DO':
      const optionsDO = { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol' };

      result = formatNumberInCurrency(value, 'en-US', optionsDO, noSymbol);
      break
    case 'UN':
      const optionsUN = {}

      result = formatNumberInCurrency(value, 'pt-BR', optionsUN, noSymbol);
      break
    case 'PO':
      const optionsPO = {
        style: 'percent',
        minimumFractionDigits: 1,
        maximumFractionDigits: 2
      };

      result = formatNumberInCurrency(value, 'en-GB', optionsPO, noSymbol);
      break
  }

  return result;
}