export default {
  getUsuario(state) {
    return state.usuario
  },
  getUsuarios(state) {
    return state.usuarios
  },
  getResponsaveis(state) {
    return state.responsaveis;
  },
  getLoading: (state) => {
    return state.loading;
  },
  getColaboradores(state) {
    return state.colaboradores;
  },
};
