
export default {
    formsOfCollaborator: {},
    form: {},

    technicalEvaluation: null,

    // Formulário de avaliação comportamental
    countQuestions: 1,
    question: {}
}