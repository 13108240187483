export default {
  getTabsFormulario(state) {
    return state.tabsVisualizarAvaliacao;
  },
  getConfiguracoes: (state) => {
    return state.configuracoes;
  },
  getConfiguracao: (state) => {
    return state.configuracao;
  },
  getDistribuicaoValores: (state) => {
    return state.distribuicao;
  },
  getMesesPeriodicidade: (state) => {
    return state.mesesPeriodicidades;
  },
  getHabilitarDistribuicaoValores: (state) => {
    return state.habilitarDistribuicaoValores;
  },
  getTotalApurado: (state) => {
    return state.totalApurado;
  },
  getConfigMetasIndividuais: state => {
    return state.configMetasIndividuais;
  }
};
