export default {
  setMenus(state, value) {
    state.menus = value;
  },
  setMenusPorColunas(state, value) {
    state.menusPorColunas = value;
  },
  setItensMenus(state, value) {
    state.itensMenus = value;
  },
  setLoadingMenu(state, value) {
    state.loadingMenu = value;
  },
  setGruposAcesso(state, value) {
    state.gruposAcesso = value;
  },
  setEmpresas(state, value) {
    state.empresas = value;
  },
  setSetores(state, value) {
    state.setores = value;
  },
  setCargos(state, value) {
    state.cargos = value;
  },
  setEquipes(state, value) {
    state.equipes = value;
  },
  setPapeis(state, value) {
    state.papeis = value;
  },
  setPermissoes(state, value) {
    state.permissoes = value;
  },
  setLoadingSetores(state, value) {
    state.loadingSetores = value;
  },
  setLoadingEquipes(state, value) {
    state.loadingEquipes = value;
  },
  setLoadingCargos(state, value) {
    state.loadingCargos = value;
  },
  setLoadingPapeis(state, value) {
    state.loadingPapeis = value;
  },
  setLoadingPermissoes(state, value) {
    state.loadingPermissoes = value;
  },
  setListaMenus(state, value) {
    state.listaMenus = value;
  },
  setLoadingGrupoAcesso(state, value) {
    state.loadingGrupoAcesso = value;
  },
};
