export default {
  setModelos(state, value) {
    state.modelos = value;
  },
  setModelo(state, value) {
    state.modelo = value;
  },
  resetDatas(state) {
    state.perguntas.FE = [];
    state.perguntas.FI.FAV = [];
    state.perguntas.FI.BEN = [];
    state.perguntas.FI.DES = [];
    state.perguntas.FI.MOT = [];
  },
  setModeloLegiio(state, value) {
    state.modeloLegiio = value;
  },
  setModeloPersonalizado(state, value) {
    state.modeloPersonalizado = value;
  },

  // Configurações do módulo -------------------------------------------------
  /**
   * @description Seta os valores para determinada propriedade de Loadings definida na propriedade Property de data
   *
   * @param object data
   * @example { property: 'loadingFE', value: true }
   *
   * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
   */
  setLoadings(state, data) {
    state.loadings[data.property] = data.value;
  },
  setStep(state, num) {
    state.step = num;
  },
  setDialogModalPergunta(state, bool) {
    state.dialogModalPergunta = bool;
  },
  setClickedOnSave(state, bool) {
    state.clickedOnSave = bool;
  },
  resetModelo(state, data) {
    state.modelo = data;
  },
  setProcessOfSave(state, bool) {
    state.processOfSave = bool;
  },
};
