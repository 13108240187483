
export default [
    {
        path: '/res/painel',
        name: 'painel-candidato',
        meta: {
            layout: "candidato",
            requiresAuth: true,
            resource: 'res-candidato',
            action: 'access',
        },
        component: () => import(`../views/PainelCandidato.vue`),
        beforeEnter(to, from, next) {
            const hostname = window.location.hostname;
            const arrayName = hostname.split('.');

            if (arrayName.length <= 1) return next({ name: 'error-404' });

            return next();
        },
    },
]