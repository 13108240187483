export default {
  setCargo(state, data) {
    state.cargo = data
  },
  setCargos(state, data) {
    state.cargos = data
  },
  setCargosOfByColumns(state, data) {
    state.cargosOfByColumns = data;
  },
};
