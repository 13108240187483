export default {
  namespaced: true,
  state: {
    shallContentShowOverlay: false,
    menuList: []
  },
  getters: {
    getListaMenu: state => state.menuList,
  },
  mutations: {
    TOGGLE_CONTENT_OVERLAY(state, value) {
      state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay
    },
    setListaMenu(state, value) {
      state.menuList = value
    }
  },
  actions: {},
}
