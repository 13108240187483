import restApi from '@axios'

export default {
    setNivel({ commit }, data) {
        commit('setNivel', data)
    },
    initialize({ commit }) {
        return new Promise(function (resolve, reject) {
            restApi.get('/avd/nivel/initialize').then(response => {
                if (response.data) {
                    commit('setNiveis', response.data.data)
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },
    /**
     * @description Cria ou atualiza um dado.
     * 
     * @param data Object
     * 
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
    */
    save({ commit }, data = Object) {
        return new Promise(function (resolve, reject) {
            restApi.post(data.url, data).then(response => {
                if (response.data) {
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    }
}