<template>
  <v-snackbar v-if="snackbarGlobal" :timeout="snackbarGlobal.duracao || 5000"
    :color="snackbarGlobal.cor ? snackbarGlobal.cor : 'success'" v-model="snackbarGlobal.visibilidade">
    <p class="mensagem" v-html="snackbarGlobal.mensagem"></p>

    <template v-slot:action="{ attrs }">
      <v-btn color="white" text v-bind="attrs" @click="snackbarGlobal.visibilidade = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>

export default {
  computed: {
    snackbarGlobal: {
      get() {
        return this.$store.getters["snackbar/getSnackbar"];
      },
    },
  },
};
</script>

<style scoped>
.mensagem {
  font-family: Roboto, sans-serif !important;
}
</style>
