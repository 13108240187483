
export default [
  {
    path: "/adm/configuracoes/submotivos",
    name: "adm-config-submotivos",
    component: () => import("@/modules/administrativo/modules/submotivos/views/SubmotivosView.vue"),
    meta: {
      layout: "content",
      requiresAuth: true,
      resource: "motivos",
      action: "access",
    }    
  },
];
