import restApi from '@axios';

export default {
    setCidades({ commit }, data) {
        commit('setCidades', data)
    },

    findByColumns({ commit }, data) {
        return new Promise(function (resolve, reject) {
            restApi.post('/cidade/find-by-columns', data).then(response => {
                if (response.data) {
                    commit('setCidades', response.data.data)
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getDataForFilter({ commit }) {
        return new Promise(function (resolve, reject) {
            restApi.get('/ext/cidade/get-data-for-filter').then(response => {
                if (response.data) {
                    commit('setCidades', response.data.data)
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch((error) => {
                reject(error)
            })
        })
    },
}