export default {
  // EMPRESA
  empresas: [],
  empresaSelecionada: {
    informacoes: {
      endereco: {
        cep: "",
      },
    },
  },
  empresa: null,
  roles: [],
  empresasAtivas: [],
  // FILIAL
  empresaFiliais: [],
  empresaFiliaisSelect: [],
  // RESPONSAVEIS
  empresaResponsaveis: [],
  // CONTRATO
  empresaContratos: [],
  // PROPOSTA
  empresaPropostas: [],
  // LOADING
  loading: false,
  // SETORES
  empresaSetores: [],
  empresaSetoresSelect: [],
  // CARGOS
  empresaCargos: [],
  empresaCargosSelect: [],
  // COMPETÊNCIAS
  empresaCompetencias: [],
  // TIMES
  empresaTimes: [],
  // USUARIOS
  empresaUsuarios: [],
  empresaUsuariosSelect: [],
  // GRUPOS DE ACESSO
  empresaGruposDeAcesso: [],
  dialogModalCnpj: true,
  // DADOS EMPRESA FORMULARIO EXTERNO
  listaEmpresa: [],
  loadings: {
    loadingModalCnpj: false,
    loadingModalDados: false,
    loadingFinalizacao: false,
  },
  tabsFormularioEmpresa: [
    {
      id: "principal",
      title: "Principal",
      scopeValidation: "principal",
      disabled: false,
      component: () =>
        import(
          "@/modules/administrativo/modules/empresas/components/tabs/Principal.vue"
        ),
    },
    {
      id: "pessoais",
      title: "Sócios",
      scopeValidation: "pessoais",
      disabled: false,
      component: () =>
        import(
          "@/modules/administrativo/modules/empresas/components/tabs/Pessoal.vue"
        ),
    },
    {
      id: "enderecos",
      title: "Endereços",
      scopeValidation: "enderecos",
      disabled: false,
      component: () =>
        import(
          "@/modules/administrativo/modules/empresas/components/tabs/Enderecos.vue"
        ),
    },
    {
      id: "outros",
      title: "Outros",
      scopeValidation: "outros",
      disabled: false,
      component: () =>
        import(
          "@/modules/administrativo/modules/empresas/components/tabs/Outros.vue"
        ),
    },
    // { id:'social',title: "Social",    scopeValidation: 'social', disabled: true, component: () => import('./../../../modules/administrativo/modules/empresas/components/tabs/Social.vue')},
    //{ id: 'contas', title: "Contas", scopeValidation: 'contas', disabled: true, component: () => import('./../../../modules/administrativo/modules/empresas/components/tabs/Contas.vue') },
    //{ id: 'pedidos', title: "Pedidos", scopeValidation: 'pedidos', disabled: true, component: () => import('./../../../modules/administrativo/modules/empresas/components/tabs/OutrosPedidos.vue') }
  ],
  tabsInformacoesEmpresa: [
    {
      name: "informações gerais",
      component: () =>
        import(
          "@/modules/administrativo/modules/empresas/components/Gerais.vue"
        ),
      disabled: false,
    },
    {
      name: "usuários",
      component: () =>
        import(
          "@/modules/administrativo/modules/empresas/components/Usuarios.vue"
        ),
      disabled: false,
    },
    {
      name: "filiais",
      component: () =>
        import(
          "@/modules/administrativo/modules/empresas/components/Filiais.vue"
        ),
      disabled: false,
    },
    {
      name: "setores",
      component: () =>
        import(
          "@/modules/administrativo/modules/empresas/components/Setores.vue"
        ),
      disabled: false,
    },
    {
      name: "cargos",
      component: () =>
        import(
          "@/modules/administrativo/modules/empresas/components/Cargos.vue"
        ),
      disabled: false,
    },
    {
      name: "times",
      component: () =>
        import(
          "@/modules/administrativo/modules/empresas/components/Times.vue"
        ),
      disabled: false,
    },
    {
      name: "contratos",
      component: () =>
        import(
          "@/modules/administrativo/modules/empresas/components/Contratos.vue"
        ),
      disabled: false,
    },
    {
      name: "clientes",
      component: () => import("@/modules/clientes/views/Clientes.vue"),
      disabled: false,
    },
    {
      name: "modulos",
      component: () =>
        import(
          "@/modules/administrativo/modules/empresas/components/Modulos.vue"
        ),
      disabled: false,
    },
  ],

  // LAYOUT EMPRESA MODULOS
  /**
   * Regras para implementação neste array
   * - Os objetos na primeira camada se tiverem children não terão a propriedade component;
   * - A url deve ser sempre o nome simplificado;
   * - O routeName é uma propriedade muito importante utilizada para renderizar o objeto no GenericRenderComponents
   * - Objetos na raiz não tem rootNode, já os filhos, netos... dele terão sempre o rootNode com o nome do objeto raiz
   *     -- Exemplo: { routeName: 'pco' ... children: [{ rootNode: 'pco', routeName: 'escalas' }] }
   *  - rootNode e routeName sempre deverão ser letras minusculas.
   * - Todos os objetos no array devem ter o breabcrumb citado o caminho completo que percorreu
   */
  modulosEmpresa: [
    {
      routeName: "pco",
      url: "pco",
      name: "pesquisa de clima organizacional",
      disabled: false,
      children: [
        {
          rootNode: "pco",
          routeName: "dashboard",
          url: "dashboard",
          name: "dashboard",
          component: () =>
            import("@/modules/pco/dashboard/views/Dashboard.vue"),
          disabled: false,
          breadcrumbs: [
            {
              text: "Modulos",
              disabled: true,
              href: "",
            },
            {
              text: "Pesquisa de clima organizacional",
              disabled: true,
              href: "/adm/pco",
            },
            {
              text: "Dashboard",
              disabled: true,
              href: "",
            },
          ],
        },
        {
          rootNode: "pco",
          routeName: "escalas",
          url: "escalas",
          name: "escalas",
          component: () => import("@/modules/pco/escalas/views/Painel.vue"),
          disabled: false,
          breadcrumbs: [
            {
              text: "Modulos",
              disabled: true,
              href: "",
            },
            {
              text: "Pesquisa de clima organizacional",
              disabled: true,
              href: "",
            },
            {
              text: "Escalas",
              disabled: true,
              href: "",
            },
          ],
        },
        {
          rootNode: "pco",
          routeName: "grupos",
          url: "grupos",
          name: "grupos",
          component: () => import("@/modules/pco/grupos/views/Painel.vue"),
          disabled: false,
          breadcrumbs: [
            {
              text: "Modulos",
              disabled: true,
              href: "",
            },
            {
              text: "Pesquisa de clima organizacional",
              disabled: true,
              href: "",
            },
            {
              text: "Escalas",
              disabled: true,
              href: "",
            },
          ],
        },
        {
          rootNode: "pco",
          routeName: "modelos",
          url: "modelos",
          name: "modelos",
          component: () => import("@/modules/pco/modelos/views/Modelos.vue"),
          disabled: false,
          breadcrumbs: [
            {
              text: "Modulos",
              disabled: true,
              href: "",
            },
            {
              text: "Pesquisa de clima organizacional",
              disabled: true,
              href: "",
            },
            {
              text: "Escalas",
              disabled: true,
              href: "",
            },
          ],
        },
        {
          rootNode: "pco",
          routeName: "pesquisas",
          url: "pesquisas",
          name: "pesquisas",
          component: () => import("@/modules/pco/pesquisa/views/Pesquisas.vue"),
          disabled: false,
          breadcrumbs: [
            {
              text: "Modulos",
              disabled: true,
              href: "",
            },
            {
              text: "Pesquisa de clima organizacional",
              disabled: true,
              href: "",
            },
            {
              text: "Escalas",
              disabled: true,
              href: "",
            },
          ],
        },
      ],
      breadcrumbs: [
        {
          text: "Modulos",
          disabled: true,
          href: "",
        },
        {
          text: "Pesquisa de clima organizacional",
          disabled: true,
          href: "",
        },
      ],
    },
    {
      routeName: "avd",
      url: "avd",
      name: "avaliação de desempenho",
      disabled: false,
      children: [
        {
          rootNode: "avd",
          routeName: "avaliacao",
          url: "avaliacao",
          name: "Gestão de avaliação",
          component: () =>
            import("@/modules/avd/avaliacao/views/Avaliacao.vue"),
          disabled: false,
          breadcrumbs: [
            {
              text: "Modulos",
              disabled: true,
              href: "",
            },
            {
              text: "Avaliação de desempenho",
              disabled: true,
            },
            {
              text: "Gestão de avaliação",
              disabled: true,
              href: "",
            },
          ],
        },
      ],
      breadcrumbs: [
        {
          text: "Modulos",
          disabled: true,
          href: "",
        },
        {
          text: "Avaliação de desempenho",
          disabled: true,
          href: "",
        },
      ],
    },
    {
      routeName: "okr",
      url: "okr",
      name: "objetivos e resultados chave",
      component: () =>
        import(
          "@/modules/administrativo/modules/empresas/components/modulos/OKR.vue"
        ),
      disabled: false,
      breadcrumbs: [
        {
          text: "Modulos",
          disabled: true,
          href: "",
        },
        {
          text: "Objetivos e resultados chave",
          disabled: true,
          href: "",
        },
      ],
    },
    {
      routeName: "nine-box",
      url: "nine-box",
      name: "matriz nine box",
      component: () =>
        import(
          "@/modules/administrativo/modules/empresas/components/modulos/MatrizNineBox.vue"
        ),
      disabled: false,
      breadcrumbs: [
        {
          text: "Modulos",
          disabled: true,
          href: "",
        },
        {
          text: "Matriz nine box",
          disabled: true,
          href: "",
        },
      ],
    },
    {
      routeName: "recrutamento-e-selecao",
      url: "recrutamento-e-selecao",
      name: "recrutamento e seleção",
      component: () =>
        import(
          "@/modules/administrativo/modules/empresas/components/modulos/RecrutamentoESelecao.vue"
        ),
      disabled: false,
      children: [
        {
          rootNode: "recrutamento-e-selecao",
          routeName: "analise-1",
          url: "avaliacao",
          name: "Gestão de analise 1",
          component: () =>
            import("@/modules/avd/avaliacao/views/Avaliacao.vue"),
          disabled: false,
          children: [
            {
              rootNode: "recrutamento-e-selecao",
              routeName: "analise-2",
              url: "avaliacao",
              name: "Gestão de analise 2",
              component: () =>
                import("@/modules/avd/avaliacao/views/Avaliacao.vue"),
              disabled: false,
              breadcrumbs: [
                {
                  text: "Modulos",
                  disabled: true,
                  href: "",
                },
                {
                  text: "Avaliação de desempenho",
                  disabled: true,
                },
                {
                  text: "Gestão de avaliação",
                  disabled: true,
                  href: "",
                },
              ],
            },
          ],
          breadcrumbs: [
            {
              text: "Modulos",
              disabled: true,
              href: "",
            },
            {
              text: "Avaliação de desempenho",
              disabled: true,
            },
            {
              text: "Gestão de avaliação",
              disabled: true,
              href: "",
            },
          ],
        },
      ],
      breadcrumbs: [
        {
          text: "Modulos",
          disabled: true,
          href: "",
        },
        {
          text: "Recrutamento e seleção",
          disabled: true,
          href: "",
        },
      ],
    },
    {
      routeName: "plano-de-cargos-e-salarios",
      url: "plano-de-cargos-e-salarios",
      name: "plano de cargos e salários",
      component: () =>
        import(
          "@/modules/administrativo/modules/empresas/components/modulos/PlanoDeCargosESalarios.vue"
        ),
      disabled: false,
      breadcrumbs: [
        {
          text: "Modulos",
          disabled: true,
          href: "",
        },
        {
          text: "Plano de cargos e salários",
          disabled: true,
          href: "",
        },
      ],
    },
  ],

  datasLayoutModulos: {
    name: "",
    breadcrumbs: [],
  },

  pcoCruds: [
    {
      id: 1,
      name: "dashboard",
      component: () => import("@/modules/pco/dashboard/views/Dashboard.vue"),
      disabled: false,
    },
    {
      id: 2,
      name: "escalas",
      component: () => import("@/modules/pco/escalas/views/Painel.vue"),
      disabled: false,
    },
    {
      id: 3,
      name: "grupos",
      component: () => import("@/modules/pco/grupos/views/Painel.vue"),
      disabled: false,
    },
    {
      id: 4,
      name: "modelos",
      component: () => import("@/modules/pco/modelos/views/Modelos.vue"),
      disabled: false,
    },
    {
      id: 5,
      name: "pesquisas",
      component: () => import("@/modules/pco/pesquisa/views/Pesquisas.vue"),
      disabled: false,
    },
  ],
  avdCruds: [
    {
      id: 1,
      name: "dashboard-avd",
      component: () => import("@/modules/avd/painel/views/Painel.vue"),
      disabled: false,
    },
    {
      id: 2,
      name: "competencias",
      component: () =>
        import(
          "@/modules/administrativo/modules/empresas/components/Competencias.vue"
        ),
      disabled: false,
    },
    {
      id: 3,
      name: "comportamentos",
      component: () =>
        import("@/modules/avd/comportamentos/views/Comportamento.vue"),
      disabled: false,
    },
    {
      id: 4,
      name: "modelos",
      component: () => import("@/modules/avd/modelos/views/Modelo.vue"),
      disabled: false,
    },
    {
      id: 5,
      name: "avaliacao",
      component: () => import("@/modules/avd/avaliacao/views/Avaliacao.vue"),
      disabled: false,
    },
  ],
};
