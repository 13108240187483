import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-blank',[_c(VCard,{staticClass:"mb-6",staticStyle:{"background-color":"white !important"},attrs:{"elevation":"3","rounded":"0"}},[_c(VRow,[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"3","sm":"3","md":"2","lg":"1","xl":"1"}},[_c(VBtn,{attrs:{"color":"#5c6da7","depressed":"","min-width":"30%","width":"50% !important","height":"100% !important","tile":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c(VIcon,{attrs:{"x-large":"","color":"white"}},[_vm._v("mdi-chevron-left ")])],1)],1),_c(VCol,{attrs:{"cols":"9","sm":"9","md":"10","lg":"11","xl":"11"}},[_c('div',{staticClass:"d-flex flex-column align-start"},[_c('h1',[_vm._v(_vm._s(_vm.resolvedNameModule(_vm.item.name)))]),_c(VBreadcrumbs,{staticClass:"my-1 ml-2 pa-0",attrs:{"divider":"/","items":_vm.item.breadcrumbs}})],1)])],1)],1),_c(VMain,[_c(VContainer,{staticStyle:{"width":"95% !important"},attrs:{"fluid":""}},[_vm._t("default")],2)],1),_c('SnackBar')],1)}
var staticRenderFns = []

export { render, staticRenderFns }