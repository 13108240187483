<template>
  <v-app-bar app color="white" elevation="3">
    <!-- Logo da Empresa -->
    <div class="d-flex flex-row align-center">
      <v-img
        v-if="empresa && empresa.avatar && alterAvatarOrName == false"
        tag="img"
        contain
        max-height="5em"
        max-width="5em"
        :src="empresa.avatar"
        :alt="empresa && empresa.nome ? empresa.nome : 'logo'"
        @error="alterAvatarOrName = true"
      ></v-img>
      <h1 v-if="alterAvatarOrName" class="text-h5 font-weight-medium">
        {{ empresa.nome }}
      </h1>
      <p class="mb-0 ml-2 pa-0 text-no-wrap">- Vagas</p>
    </div>

    <v-spacer></v-spacer>

    <!-- Link Oportunidades -->
    <v-btn
      v-if="user"
      color="black"
      plain
      link
      tag="a"
      type="a"
      :to="{ name: 'oportunidade' }"
    >
      Oportunidades
    </v-btn>

    <!-- DropDown Carreiras -->
    <v-menu v-if="user" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="black"
          plain
          link
          tag="a"
          type="a"
          v-bind="attrs"
          v-on="on"
        >
          Carreiras
        </v-btn>
      </template>
      <v-list>
        <v-list-item link v-for="(item, index) in items" :key="index">
          <v-list-item-avatar>
            <v-img
              :src="item.foto"
              contain
              max-height="2em"
              max-width="2em"
            ></v-img
          ></v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ item.nome }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- Menu Usuário -->
    <v-menu v-if="user" bottom min-width="200px" rounded offset-y>
      <template v-slot:activator="{ on }">
        <v-btn
          style="background-color: #f6f2f3"
          color="grey"
          class="mx-4 grey"
          icon
          x-large
          v-on="on"
        >
          <v-avatar color="grey" size="48">
            <v-img
              v-if="user && user.avatar"
              :src="user.avatar"
              :alt="user.nome"
            ></v-img>
            <v-icon v-else dark>mdi-account</v-icon>
          </v-avatar>
        </v-btn>
      </template>
      <v-card>
        <v-list-item-content class="justify-center">
          <div class="mx-auto text-center">
            <h3>{{ user.nome }}</h3>
            <v-divider class="my-3"></v-divider>
            <v-btn depressed rounded text color="info">
              <v-icon class="mr-1">mdi-pencil</v-icon> Editar perfil
            </v-btn>
            <v-divider class="my-3"></v-divider>
            <v-btn @click="logoutUser()" depressed rounded text color="error">
              <v-icon class="mr-1">mdi-exit-to-app</v-icon>
              Sair
            </v-btn>
          </div>
        </v-list-item-content>
      </v-card>
    </v-menu>
    <v-btn
      v-else
      color="accent"
      outlined
      small
      tag="a"
      :to="{ name: 'auth-login' }"
    >
      Logar/Registar
    </v-btn>
  </v-app-bar>
</template>

<script>
import store from "@/store";
import themeConfig from "@themeConfig";

export default {
  name: "AppBarCandidatoDesktop",
  data() {
    return {
      items: [
        {
          nome: "Google",
          foto: "https://logodownload.org/wp-content/uploads/2014/09/google-logo-1.png",
        },
        {
          nome: "Microsoft",
          foto: "https://logosmarcas.net/wp-content/uploads/2020/09/Microsoft-Logo.png",
        },
      ],

      alterAvatarOrName: false,
    };
  },
  created() {
    const subdomain = window.location.host.split(".")[0];

    this.$store.dispatch("empresas/getEmpresaBySlug", subdomain);
  },
  methods: {
    logoutUser() {
      // Remove userData from localStorage
      themeConfig.app.name = "Legiio";
      // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem("accessToken");
      localStorage.clear();
      // Remove userData & Ability from localStorage
      localStorage.removeItem("user");
      localStorage.removeItem("role");
      localStorage.removeItem("userAbility");

      store.commit("auth/setUser", []);
      store.commit("auth/setToken", false);

      // Reset ability
      //vm.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push("/login");
    },
  },
  computed: {
    /**
     * @description Verifica se o usuário que está acessando é Mobile ou Desktop, caso for mobile será retornado TRUE, senão FALSE será retornado.
     *
     * @return boolean
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    isMobile() {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        return true; // está utilizando celular
      } else {
        return false; // não é celular
      }
    },
    user: {
      get() {
        const userData = JSON.parse(localStorage.getItem("userData"));
        return userData ? userData : null;
      },
    },
    empresa: {
      get() {
        return this.$store.getters["empresas/getEmpresa"];
      },
    },
  },
};
</script>

<style></style>
