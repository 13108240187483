
const router = [
    {
        path: '/adm/propostas',
        name: 'propostas-adm',
        component: () => import('@/modules/administrativo/modules/propostas/views/Propostas.vue'), 
        meta: {
            layout: "content",
            requiresAuth: true,
            resource: 'propostas',
            action: 'access' 
        }
    }
]

export default router