
export default {
    getCandidatoFormacao(state) {
        return state.candidatoFormacao
    },
    getCandidatosFormacoes(state) {
        return state.candidatosFormacoes
    },
    getQuantidadeFormacao(state) {
        return state.quantidadeFormacao
    }
}