import restApi from "@axios";

export default {
  initialize({ commit }) {
    return new Promise((resolve, reject) => {
      restApi
        .get("/clientes/initialize")
        .then((response) => {
          if (response.data) {
            commit("setClientes", response.data.data);
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  save({ commit }, data) {
    return new Promise((resolve, reject) => {
      restApi
        .post(data.url, data.data)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  remove({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      restApi
        .delete(`/clientes/${uuid}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  },
  /**
   * @description Busca por todos os dados e retorna somente a coluna desejada. Também é possível passar uma validação.
   *
   * @param data Object
   * @example data `{ columns: ["id", "nome"], validate: [["status", "A"]] }`
   *
   * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
   */
  getAllDatabyColumns({ commit }, data) {
    return new Promise(function (resolve, reject) {
      restApi
        .post("/clientes/get-all-data-by-columns", data)
        .then((response) => {
          if (response.data) {
            commit("setClientes", response.data.data);
            resolve(response.data.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  retornaClientes({ commit }, data) {
    return new Promise(function (resolve, reject) {
      restApi
        .post("/clientes/retorna-clientes", data)
        .then((response) => {
          if (response.data) {
            commit("setClientes", response.data.data);
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  findByUuid({ commit }, uuid) {
    return new Promise(function (resolve, reject) {
      restApi
        .get(`/clientes/find-by-uuid/${uuid}`)
        .then((response) => {
          if (response.data) {
            commit("setCliente", response.data.data);
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  setCliente({ commit }, data) {
    commit("setCliente", data);
  },
  resetCliente({ commit }) {
    const data = {
      nome: null,
      email: null,
      razao_social: null,
      inscricao_estadual: null,
      inscricao_municipal: null,
      celular: "",
      telefone: "",
      cnpj: "",
      abertura: null,
      descricao: null,
      logotipo: null,
      status: "A",
    };

    commit("setCliente", data);
  },
  enviarAquivoImportacaoClientes({ commit }, data) {
    return new Promise(function (resolve, reject) {
      restApi
        .post("/clientes/importar-excel", data)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
};
