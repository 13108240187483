<template>
  <v-btn
    color="primary"
    icon
    class="mr-auto"
    :to="{ name: 'Clientes' }"
    plain
  >
    <v-icon class="me-5"> mdi-domain </v-icon>
  </v-btn>
</template>

<script>
import { getInitialName } from "@core/utils";

// 3rd Party
import { PerfectScrollbar } from "vue2-perfect-scrollbar";

export default {
  components: {
    // 3rd Party
    PerfectScrollbar,
  },
  setup() {
    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    return {
      getInitialName,
      perfectScrollbarOptions,
    };
  },
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/styles.sass";

.app-bar-notification-content-container {
  .read-all-btn-list-item {
    padding-top: 14px;
    padding-bottom: 14px;
    min-height: unset;
  }
}

.ps-user-notifications {
  max-height: calc(var(--vh, 1vh) * 80);
}

.notification-menu-content {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-width: calc(100vw - (1.5rem * 2)) !important;
    left: 50% !important;
    transform: translateX(-50%);
  }
}
</style>
