import restApi from '@axios'

export default {
    setVaga({ commit }, data) {
        commit('setVaga', data)
    },
    getVagaDetail({ commit }, uuid = String) {
        return new Promise(function (resolve, reject) {
            restApi.get(`ext/res-vaga/get-vaga-detail/${uuid}`).then(response => {
                if (response.data) {
                    commit('setVaga', response.data.data)
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                reject(error)
            })
        })
    }
}