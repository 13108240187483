import restApi from '@axios';

export default {
    findByColumns({ commit }, data) {
        return new Promise(function (resolve, reject) {
            restApi.post('/tipo-setor/find-by-columns', data).then(response => {
                if (response.data) {
                    commit('setTiposSetores', response.data.data)
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    }
}
