export default {
  menus: [],
  menusPorColunas: [],
  itensMenus: [],
  gruposAcesso: [],
  empresas: [],
  setores: [],
  cargos: [],
  equipes: [],
  papeis: [],
  permissoes: [],
  loadingMenu: false,
  loadingGrupoAcesso: false,
  loadingPapeis: false,
  loadingEquipes: false,
  loadingCargos: false,
  loadingSetores: false,
  loadingPermissoes: false,
  listaMenus: [],
  tabsMenu: [
    {
      id: 1,
      name: "Estados e cidades",
      ref: "EEC",
      scopeValidation: "EEC",
      disabled: false,
      component: () =>
        import("@/modules/administrativo/modules/configuracoes/modules/components/tabs/EstadosTab"),
    },
    {
      id: 2,
      name: "Áreas de atuações",
      ref: "ADA",
      scopeValidation: "ADA",
      disabled: false,
      component: () =>
        import("@/modules/administrativo/modules/configuracoes/modules/components/tabs/AreaAtuacoesTab"),
    },
    {
      id: 3,
      name: "Assuntos",
      ref: "ASS",
      scopeValidation: "ASS",
      disabled: true,
      component: () =>
        import("@/modules/administrativo/modules/configuracoes/modules/components/tabs/EstadosTab"),
    },
    {
      id: 4,
      name: "Benefícios",
      ref: "BEN",
      scopeValidation: "BEN",
      disabled: false,
      component: () =>
        import("@/modules/administrativo/modules/configuracoes/modules/components/tabs/BeneficiosTab"),
    },
    {
      id: 5,
      name: "Cargos",
      ref: "CAR",
      scopeValidation: "CAR",
      disabled: false,
      component: () =>
        import("@/modules/administrativo/modules/configuracoes/modules/components/tabs/CargosTab"),
    },
    {
      id: 6,
      name: "Competências",
      ref: "COM",
      scopeValidation: "COM",
      disabled: false,
      component: () =>
        import("@/modules/administrativo/modules/configuracoes/modules/components/tabs/CompetenciasTab"),
    },
    {
      id: 7,
      name: "Deficiências",
      ref: "DEF",
      scopeValidation: "DEF",
      disabled: false,
      component: () =>
        import("@/modules/administrativo/modules/configuracoes/modules/components/tabs/DeficienciasTab"),
    },
    {
      id: 8,
      name: "Estados civies",
      ref: "ESC",
      scopeValidation: "ESC",
      disabled: false,
      component: () =>
        import("@/modules/administrativo/modules/configuracoes/modules/components/tabs/EstadosCiviesTab"),
    },
    {
      id: 9,
      name: "Etapas processos",
      ref: "EPR",
      scopeValidation: "EPR",
      disabled: true,
      component: () =>
        import("@/modules/administrativo/modules/configuracoes/modules/components/tabs/EstadosTab"),
    },
    {
      id: 10,
      name: "Formações",
      ref: "FOR",
      scopeValidation: "FOR",
      disabled: false,
      component: () =>
        import("@/modules/administrativo/modules/configuracoes/modules/components/tabs/FormacoesTab"),
    },
    {
      id: 11,
      name: "Idiomas",
      ref: "IDI",
      scopeValidation: "IDI",
      disabled: false,
      component: () =>
        import("@/modules/administrativo/modules/configuracoes/modules/components/tabs/IdiomasTab"),
    },
    {
      id: 12,
      name: "Níveis de proficiência",
      ref: "NDP",
      scopeValidation: "NDP",
      disabled: false,
      component: () =>
        import("@/modules/administrativo/modules/configuracoes/modules/components/tabs/ProficienciasTab"),
    },
    {
      id: 13,
      name: "Perfies vagas",
      ref: "PEV",
      scopeValidation: "PEV",
      disabled: true,
      component: () =>
        import("@/modules/administrativo/modules/configuracoes/modules/components/tabs/EstadosTab"),
    },
    {
      id: 14,
      name: "Pretenções salarias",
      ref: "PRS",
      scopeValidation: "PRS",
      disabled: false,
      component: () =>
        import("@/modules/administrativo/modules/configuracoes/modules/components/tabs/PretensoesSalariaisTab"),
    },
    {
      id: 15,
      name: "Status cursos",
      ref: "STC",
      scopeValidation: "STC",
      disabled: false,
      component: () =>
        import("@/modules/administrativo/modules/configuracoes/modules/components/tabs/StatusCursosTab"),
    },
    {
      id: 16,
      name: "Status vagas",
      ref: "STV",
      scopeValidation: "STV",
      disabled: false,
      component: () =>
        import("@/modules/administrativo/modules/configuracoes/modules/components/tabs/StatusVagasTab"),
    },
    {
      id: 17,
      name: "Tempo experiência",
      ref: "TEE",
      scopeValidation: "TEE",
      disabled: false,
      component: () =>
        import("@/modules/administrativo/modules/configuracoes/modules/components/tabs/TemposExperienciasTab"),
    },
  ],
};
