
import restApi from '@axios'

export default {
    setVagaCompetencia({ commit }, data) {
        commit('setVagaCompetencia', data)
    },
    initialize({ commit }) {
        return new Promise(function (resolve, reject) {
            restApi.get('/res-vaga-competencia/initialize').then(response => {
                if (response.data) {
                    commit('setVagasCompetencias', response.data.data)
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },
    save({ commit }, data) {
        return new Promise(function (resolve, reject) {
            restApi.post(data.url, data).then(response => {
                if (response.data) {
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },
    findByUuid({ commit }, uuid) {
        return new Promise(function (resolve, reject) {
            restApi.get(`/res-vaga-competencia/find-by-uuid/${uuid}`).then(response => {
                if (response.data) {
                    commit('setVagaCompetencia', response.data.data)
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },
    remove({ commit }, uuid) {
        return new Promise(function (resolve, reject) {
            restApi.delete(`/res-vaga-competencia/${uuid}`).then(response => {
                if (response.data) {
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },
}