import { mdiCheckboxBlankCircleOutline } from '@mdi/js'

const themeConfig = {
  app: {
    name: 'Legiio',
    logo: require('@/assets/images/svg/logo.svg'),
    isDark: false,
    isRtl: false,
    contentLayoutNav: 'vertical', // vertical, horizontal
    routeTransition: 'scroll-x-transition', // https://vuetifyjs.com/en/styles/transitions/#api
    // ! `semi-dark` isn't available for horizontal nav menu
    skinVariant: 'default', // default, bordered, semi-dark
    contentWidth: 'boxed',
  },
  menu: {
    isMenuHidden: false,
    isVerticalNavMini: true,
    verticalMenuAccordion: true,
    groupChildIcon: mdiCheckboxBlankCircleOutline,
    horizontalNavMenuGroupOpenOnHover: false,
  },
  appBar: {
    /*
    ! In Content Layout Horizontal Nav type `hidden` value won't work
    ! In Content Layout Horizontal Nav type value of `type` will affect both Appbar and Horizontal Navigation Menu
    */
    type: 'static', // fixed, static, hidden
    isBlurred: false,
  },
  footer: {
    type: 'static', // fixed, static, hidden
  },
  themes: {
    light: {
      primary: '#152d40',
      accent: '#0d6efd',
      secondary: '#8A8D93',
      success: '#56CA00',
      info: '#16B1FF',
      warning: '#FFB400',
      error: '#FF4C51',
    },
    dark: {
      primary: '#152d40',
      accent: '#0d6efd',
      secondary: '#8A8D93',
      success: '#56CA00',
      info: '#16B1FF',
      warning: '#FFB400',
      error: '#FF4C51',
    },
  },
  chartColors: {
    series1: "#ed254e",
    series2: "#b6147d",
    series3: "#ec6b1b",
    series4: "#ffc857",
    series5: "#177e89",
    series6: "#778da9",
    series7: "#8aa29e",
    series8: "#6da34d",
    series9: "#519872",
    series10: "#697a21",
    series11: "#14591d",
    series12: "#5f634f",
    series13: "#582707",
    series14: "#4c061d",
    series15: "#051c2c",
    series16: "#1b4ded",
    series17: "#1bb485",
    series18: "#569f32",
    series19: "#115137",
    series20: "#14591d",
    series21: "#bcbc08",
    series22: "#5f3e91",
    series23: "#eaa434",
    series24: "#oc3e49",
    series25: "#355ea9",
    series26: "#29727b",
    series27: "#4d5860",
    series28: "#856e30",
    series29: "#7a4d2d",
    series30: "#0b457a"
  },
  colors: {
    red: {
      color: '#ED254E',
      variants: {
        var1: '#EB4872',
        var2: '#E86A96',
        var3: '#E58DBA',
        var4: '#EAA4C8',
        var5: '#EEB6D3',
        gradient1: ['#ED254E', '#ee3057', '#ef3b60', '#f04669', '#f15171', '#f25b7a', '#f26683', '#f3718c', '#f47c95', '#f5879e', '#f692a6', '#f79daf', '#f8a8b8', '#f9b3c1', '#fabeca', '#fac9d3', '#fbd3dc', '#fcdee4']
      }
    },
    white_blue: {
      color: '#177E89',
      variants: {
        var1: '#318984',
        var2: '#5AA19D',
        var3: '#7BB4B1',
        var4: '#95C3C1',
        var5: '#AACFCD',
        gradient1: ['#177E89', '#1a8c98', '#1c9aa7', '#1fa7b6', '#21b5c5', '#24c3d4', '#2dcbdc', '#3ccfde', '#4bd2e1', '#5ad6e3', '#69dae6', '#78dee8', '#87e1eb', '#96e5ed', '#a5e9f0', '#b4ecf2', '#c3f0f5']
      }
    },
    black_blue: {
      color: '#051C2C',
      variants: {
        var1: '#072B3B',
        var2: '#395562',
        var3: '#617781',
        var4: '#81929A',
        var5: '#9AA8AE',
        gradient1: ['#051c2c', '#041e42', '#002d72', '#004b87', '#0076ad', '#009ecc', '#00bce1', '#6ad4eb', '#ace3ef']
      }
    },
    yellow: {
      color: '#FFC857',
      variants: {
        var1: '#CDA951',
        var2: '#D7BA74',
        var3: '#DFC890',
        var4: '#E5D3A6',
        var5: '#EADCB8',
        gradient1: ['#177E89', '#106575', '#084C61', '#DB3A34', '#E04C39', '#E45E3D', '#ED8146', '#F6A54F', '#FFC857', '#FFCD66']
      }
    },
    green: {
      color: '#6DA34D',
      variants: {
        var1: '#80A44E',
        var2: '#99B671',
        var3: '#ADC58D',
        var4: '#BDD1A4',
        var5: '#CADAB6',
        gradient1: ['#6da34d', '#689050', '#648153', '#617555', '#5f6b57', '#548687', '#8fbc94', '#c5e99b'],
        gradient2: ['#6DA34D', '#73ac51', '#7ab15a', '#82b563', '#89ba6c', '#90be75', '#98c27f', '#9fc788', '#a7cb91', '#aecf9a', '#b5d4a3', '#bdd8ac', '#c4dcb6', '#cbe1bf', '#d3e5c8', '#dae9d1', '#e2eeda']
      }
    },
    gradients: {
      gradient1: ['#3b8cde', '#9b86e7', '#e47ad6', '#ff72af', '#ffa145', '#ffc900', '#ff9460', '#ffcc50', '#ff9800', '#c06500', '#853400', '#b40030', '#db314c', '#3cd9a2', '#03bf8a', '#00b07c'],
      gradient2: ['#6DA34D', '#41493B', '#A5AE9D', '#00A5E3', '#0071AB', '#ED254E', '#DD2A98', '#985AD5', '#FFC857', '#C1BD46', '#84AE46', '#449B4F', '#008759', '#007062'],
      gradient3: ['#3498db', '#2980b9', '#2ecc71', '#27ae60', '#f1c40f', '#f39c12', '#e74c3c', '#c0392b', '#e67e22', '#d35400', '#1abc9c', '#16a085']
    }
  },
  modulos: {
    avd: {
      modeloDownload: "https://api-legiio.s3.us-east-2.amazonaws.com/avd/modelos/modelo_importacao_colaboradores_avalicao_desempenho_sociisrh.xlsx"
    }
  }
}

export default themeConfig
