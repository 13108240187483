import appConfigStoreModule from "@core/@app-config/appConfigStoreModule";
import Vue from "vue";
import Vuex from "vuex";
import app from "./app";
import auth from "./auth";
import snackbar from "./modules/snackbar";
import snackBarModule from "./modules/snackBarModule";

import propostas from "./modules/propostas";
import empresas from "./modules/empresas";
import configuracoes from "./modules/configuracoes";
import motivos from "./modules/motivos";
import submotivos from "./modules/submotivos";
import clientes from "./modules/clientes";
import escalas from "./modules/escalas";
import escalaItens from "./modules/escalaItens";
import pcoGrandesGrupos from "./modules/pcoGrandesGrupos";
import pcoModelos from "./modules/pcoModelos";
import pcoPerguntas from "./modules/pcoPerguntas";
import pcoOpcoes from "./modules/pcoOpcoes";
import pesquisas from "./modules/pesquisas";
import usuarios from "./modules/usuarios";
import filiais from "./modules/filiais";
import setores from "./modules/setores";
import cargos from "./modules/cargos";
import times from "./modules/times";
import tiposSetores from './modules/tiposSetores'
import papeis from './modules/papeis';
import contratos from './modules/contratos';
import codigosSegurancas from './modules/codigosSegurancas'
import cidades from './modules/cidades'
import estados from './modules/estados'
import avd from "./modules/avd";
import avdColaboradores from './modules/avdColaboradores';
import avdCompetencias from './modules/avdCompetencias';
import avdTecnicas from './modules/avdTecnicas';
import avdNiveis from './modules/avdNiveis';
import resDefinicoes from './modules/resDefinicoes';
import resAreasAtuacoes from './modules/resAreasAtuacoes'
import resVagasBeneficios from './modules/resVagasBeneficios'
import resCargos from "./modules/resCargos";
import resVagasCompetencias from './modules/resVagasCompetencias'
import resDeficiencias from './modules/resDeficiencias'
import resEstadosCivies from "./modules/resEstadosCivies";
import resFormacoes from "./modules/resFormacoes";
import resIdiomas from "./modules/resIdiomas";
import resProficiencias from "./modules/resProficiencias";
import resPretensoesSalariais from './modules/resPretensoesSalariais';
import resTemposExperiencias from './modules/resTemposExperiencias';
import resStatusCursos from './modules/resStatusCursos';
import resStatusVagas from './modules/resStatusVagas';
import resCandidatos from './modules/resCandidatos'
import resExperienciasProfissionais from "./modules/resExperienciasProfissionais";
import resObjetivosProfissionais from "./modules/resObjetivosProfissionais";
import resCandidatosFormacoes from './modules/resCandidatosFormacoes'
import resCandidatosRedesSociais from './modules/resCandidatosRedesSociais'
import resCandidatosIdiomas from './modules/resCandidatosIdiomas'
import resTipoVagas from "./modules/resTipoVagas";
import resCategorias from "./modules/resCategorias";
import resVagas from './modules/resVagas'
import resProcessosCandidatos from './modules/resProcessosCandidatos'
import resCursos from './modules/resCursos'
import avdAvaliacoes from "./modules/avdAvaliacoes";
import avdFormularios from "./modules/avdFormularios";
import avdFormulariosRespostas from './modules/avdFormulariosRespostas'
import avdFormulariosColaboradores from './modules/avdFormulariosColaboradores'


Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    app,
    auth,
    propostas,
    empresas,
    configuracoes,
    motivos,
    submotivos,
    clientes,
    escalas,
    escalaItens,
    pcoGrandesGrupos,
    pcoModelos,
    pcoPerguntas,
    pcoOpcoes,
    pesquisas,
    snackbar,
    snackBarModule,
    usuarios,
    filiais,
    setores,
    cargos,
    times,
    tiposSetores,
    papeis,
    contratos,
    codigosSegurancas,
    cidades,
    estados,
    avd,
    avdColaboradores,
    avdCompetencias,
    avdTecnicas,
    avdNiveis,
    resDefinicoes,
    resAreasAtuacoes,
    resVagasBeneficios,
    resCargos,
    resVagasCompetencias,
    resDeficiencias,
    resEstadosCivies,
    resFormacoes,
    resIdiomas,
    resProficiencias,
    resPretensoesSalariais,
    resTemposExperiencias,
    resStatusCursos,
    resStatusVagas,
    resCandidatos,
    resExperienciasProfissionais,
    resObjetivosProfissionais,
    resCandidatosFormacoes,
    resCandidatosRedesSociais,
    resCandidatosIdiomas,
    resCategorias,
    resTipoVagas,
    resVagas,
    resProcessosCandidatos,
    resCursos,
    avdAvaliacoes,
    avdFormularios,
    avdFormulariosRespostas,
    avdFormulariosColaboradores
  },
});

export default store;
