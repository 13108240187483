
export default [
    {
        path: "/calculadora-rotatividade",
        name: "calculadora-rotatividade",
        component: () => import("@/views/site/views/CalculadoraRotatividade.vue"),
        meta: {
            layout: "blank",
            resource: "Public",
            requiresAuth: false,
        },
    },
    {
        path: "/calculadora-absenteismo",
        name: "calculadora-absenteismo",
        component: () => import("@/views/site/views/CalculadoraAbsenteismo.vue"),
        meta: {
            layout: "blank",
            resource: "Public",
            requiresAuth: false,
        },
    },
]