export default {
  getPesquisas: (state) => {
    return state.dataPesquisas;
  },
  getPesquisa: (state) => {
    return state.pesquisa;
  },
  getPesquisasEstatisticsData: (state) => {
    return state.statisticsData;
  },
  getResponsaveis: (state) => {
    return state.dataResponsaveis;
  },
  getLoading: (state) => {
    return state.loading;
  },
  getPesquisaSelecionada: (state) => {
    return state.dataPesquisaSelecionada;
  },
  getModelosLegiio: (state) => {
    return state.dataModeloslegiio;
  },
  getModelosPersonalizados: (state) => {
    return state.dataModelosPersonalizados;
  },
  getDadosPainel(state) {
    return state.dadosPainel;
  },
  getDadosPesquisaExterna(state) {
    return state.dadosPesquisaExterna;
  },
  getLoadingPesquisaExterna(state) {
    return state.loadingPesquisaExterna;
  },
  getPesquisaExterna(state) {
    return state.pesquisaExterna;
  },
  getPesquisaDialogExterna(state) {
    return state.pesquisaDialogExterna;
  },
  getHabilitarFormulario(state) {
    return state.habilitarFormulario;
  },
  getRespostas(state) {
    return state.respostas;
  },
  getPesquisaExternaIndex(state) {
    return state.questionIndex;
  },
  getPCOLoadingEstruturaFormulario(state) {
    return state.loadingEstruturaFormulario;
  },
  getErrorPesquisa(state) {
    return state.errorPesquisa;
  },
  getPesquisaFinalizadaDialogExterna(state) {
    return state.pesquisaFinalizadaDialogExterna;
  },
  getPesquisaVencidaDialogExterna(state) {
    return state.pesquisaVencidaDialogExterna;
  },
  getPesquisaExternaMessage(state) {
    return state.pesquisaExternaVencidaMessage;
  },
  getFiltros(state) {
    return state.filtros;
  },
  getTabsCategorias(state) {
    return state.tabsCategorias;
  },
  getArrayPergunta(state) {
    return state.arrayPerguntas;
  },
  getTab(state) {
    return state.tab;
  },
  getTabsPainel(state) {
    return state.tabsPainel;
  },
  getTabPainel(state) {
    return state.tabPainel;
  },
  getTotalizadoresPainel(state) {
    return state.totalizadoresPainel;
  },
  getBeneficiosPainel(state) {
    return state.beneficiosPainel;
  },
  getExternasPainel(state) {
    return state.externasPainel;
  },
  getMotivacionalPainel(state) {
    return state.motivacionalPainel;
  },
  getDesmotivacionalPainel(state) {
    return state.desmotivacionalPainel;
  },
  getGruposPainel(state) {
    return state.gruposPainel;
  },
  getFavorabilidadeGeralPainel(state) {
    return state.favorabilidadeGeralPainel;
  },
  getFidelidadePainel(state) {
    return state.fidelidade;
  },
  getLoadingFidelidade(state) {
    return state.loadingsPainel.loadingFidelidade;
  },
  getFavorabilidadePainel(state) {
    return state.favorabilidadePainel;
  },
  getDadosPesquisaExternaInternas(state) {
    return state.dadosPesquisaExternaInternas;
  },
  getListaPerguntasAbertas(state) {
    return state.listaPerguntasAbertas;
  },

  // ModalCadastroPerguntas
  getDialogModalCadastroPerguntas(state) {
    return state.dialogModalCadastroPerguntas;
  },
  getCategoriaModalCadastroPerguntas(state) {
    return state.categoriaModalCadastroPerguntas;
  },

  // ModalDeletePergunta
  getDialogDeletePergunta(state) {
    return state.dialogDeletePergunta;
  },

  getPesquisaCodigoDialog(state) {
    return state.dialogCode;
  },
  getPesquisaCodigoExternaMessage(state) {
    return state.messageCode;
  },
  getPesquisaExternaDescricao(state) {
    return state.descricaoPesquisa;
  },
  getPesquisaExternaComponenteInicial(state) {
    return state.componenteInicial;
  },
  getPesquisaExternaComponenteFormulario(state) {
    return state.componenteFormulario;
  },
  getPesquisaExternaPerguntas(state) {
    return state.perguntasFormularioExterno;
  },
  getPCOUltimaPerguntaRespondida(state) {
    return state.ultimaPerguntaRespondida;
  },
  getDevolutivas(state) {
    return state.devolutivas;
  },
  getPcoMensagemRetorno(state) {
    return state.mensagemRetorno;
  },

  // Configuração pesquisa
  getModalPerguntaSelecionada(state) {
    return state.modalPerguntaSelecionada;
  },
  getLoadingExportacao(state) {
    return state.loadingsPainel.loadingExport;
  },
};
