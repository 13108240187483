export default [
  {
    path: "/avd/avaliacoes",
    name: "avd-avaliacoes-view",
    meta: {
      layout: "content",
      requiresAuth: true,
      resource: "avaliacao-desempenho",
      action: "access",
    },
    component: () => import("../views/Avaliacao.vue") 
  },
  {
    path: "/avd/configuracao/:uuid?",
    name: "avd-configuracao-view",
    meta: {
      layout: "content",
      requiresAuth: true,
      resource: "avaliacao-desempenho",
      action: "access",
    },
    component: () => import("../views/Configuracao.vue"),
  },
  {
    path: "/avd/painel/:uuid?",
    name: "avaliacao-view",
    meta: {
      layout: "content",
      requiresAuth: true,
      resource: "avaliacao-desempenho",
      action: "access",
    },
    component: () => import("../views/AvaliacaoView.vue"),
  },
];
