// axios
import axios from "axios";
import Vue from "vue";

const token = localStorage.getItem("accessToken");

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_BASE_URI_API,
  headers: {
    "Accept": "application/json",
    "Content-Type": "application/json"
  },
});

if (token) {
  axiosIns.defaults.headers.common["Authorization"] = "Bearer " + token;
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axiosIns.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axiosIns.defaults.headers.get["Accepts"] = "application/json";
// axios.defaults.timeout = 10000;
// axiosIns.defaults.timeout = 10000;

axiosIns.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.common["Authorization"] = "Bearer "+localStorage.getItem("accessToken");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


Vue.prototype.$http = axiosIns;

// Add a response interceptor
axiosIns.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (localStorage.getItem("accessToken")) {
      axiosIns.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
    }
    
    // if (response.request.responseType === 'arraybuffer') {
    //   const {fileName, newFileName} = typeof(response.config.data) === 'string' ? JSON.parse(response.config.data) : response.config
    //   console.log(fileName, response)
    //   if(!fileName) return response;
  
    //   let link          = document.createElement('a')
    //   link.href         = window.URL.createObjectURL(new Blob([response.data], {type: 'application/octet-stream'}))
    //   link.download     = newFileName !== undefined && newFileName ? returnNewFileName(fileName,response.headers) : fileName
    //   document.body.appendChild(link);
    //   link.click()
    // }

    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    let message = "";

    if (error.response.status === 500) {
      message = `
        Title: ${error.response.statusText}<br />
        Exception: ${error.response.data.exception}<br />
        Message: ${error.response.data.message}<br />
        File-Line: ${error.response.data.file} - ${error.response.data.line}`;
      console.error(message);
    }
    return Promise.reject(error.response);
  }
);



export default axiosIns;
