export default {
  perguntas: {
    FE: [],
    FI: {
      FAV: [],
      BEN: [],
      DES: [],
      MOT: [],
    },
  },
  pergunta: {
    id_empresa: null,
    id_modelo: null,
    id_grande_grupo: null,
    titulo: "",
    descricao: "",
    categoria: "",
    subcategoria: null,
    tipo: "",
    limite_de_opcoes: null,
    resposta_invertida: false,
    fidelidade: 0,
    obrigatoriedade: true,
    posicao: null,
    code_front: Math.floor(Date.now() / 1000),
    opcoes: [],
    pergunta_invalida: {
      pergunta_invalida: null,
      pergunta_validadora: null,
      item_invalido: null,
      item_validador: null,
    },
  },
};
