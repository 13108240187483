<template>
  <div>
    <v-app-bar app color="white" elevation="3">
      <!-- Logo da Empresa -->
      <div class="d-flex flex-row align-center">
        <v-img
          v-if="empresa && empresa.avatar && alterAvatarOrName == false"
          contain
          max-height="5em"
          max-width="5em"
          :src="empresa.avatar"
          :alt="empresa && empresa.nome ? empresa.nome : 'logo'"
          @error="alterAvatarOrName = true"
        ></v-img>
        <h1 v-if="alterAvatarOrName" class="text-h5 font-weight-medium">
          {{ empresa.nome }}
        </h1>
        <p class="mb-0 ml-2 pa-0 text-no-wrap">- Vagas</p>
      </div>

      <v-spacer></v-spacer>

      <v-app-bar-nav-icon
        v-if="user"
        class="mr-4"
        @click="drawer = true"
      ></v-app-bar-nav-icon>

      <v-btn
        v-else
        color="accent"
        outlined
        small
        tag="a"
        :to="{ name: 'auth-login' }"
      >
        Logar/Registar
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-if="user" v-model="drawer" absolute temporary>
      <v-list>
        <v-list-item>
          <v-list-item-avatar
            style="background-color: #f6f2f3"
            color="grey"
            size="48"
          >
            <v-img
              v-if="user && user.avatar"
              :src="user.avatar"
              :alt="user.nome"
            ></v-img>
            <v-icon v-else>mdi-account</v-icon>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">{{
              user.nome
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-btn depressed rounded text color="info">
            <v-icon class="mr-1">mdi-pencil</v-icon> Editar perfil
          </v-btn>
        </v-list-item>

        <v-list-item>
          <v-btn @click="logoutUser()" depressed rounded text color="error">
            <v-icon class="mr-1">mdi-exit-to-app</v-icon>
            Sair
          </v-btn>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list dense>
        <v-list-item>
          <!-- Link Oportunidades -->
          <v-btn
            color="black"
            plain
            link
            tag="a"
            type="a"
            :to="{ name: 'oportunidade' }"
            class="my-2"
          >
            Oportunidades
          </v-btn>
        </v-list-item>

        <v-list-item>
          <!-- DropDown Carreiras -->
          <v-menu offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="black"
                plain
                link
                tag="a"
                type="a"
                v-bind="attrs"
                v-on="on"
                class="my-2"
              >
                Carreiras
              </v-btn>
            </template>
            <v-list>
              <v-list-item link v-for="(item, index) in items" :key="index">
                <v-list-item-avatar>
                  <v-img
                    :src="item.foto"
                    contain
                    max-height="2em"
                    max-width="2em"
                  ></v-img
                ></v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ item.nome }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "AppBarCandidatoMobile",
  data() {
    return {
      drawer: false,
      items: [
        {
          nome: "Google",
          foto: "https://logodownload.org/wp-content/uploads/2014/09/google-logo-1.png",
        },
        {
          nome: "Microsoft",
          foto: "https://logosmarcas.net/wp-content/uploads/2020/09/Microsoft-Logo.png",
        },
      ],

      alterAvatarOrName: false,
    };
  },
  created() {
    const subdomain = window.location.host.split(".")[0];

    this.$store.dispatch("empresas/getEmpresaBySlug", subdomain);
  },
  computed: {
    user: {
      get() {
        const userData = JSON.parse(localStorage.getItem("userData"));
        return userData ? userData : null;
      },
    },
    empresa: {
      get() {
        return this.$store.getters["empresas/getEmpresa"];
      },
    },
  },
};
</script>

<style></style>
