import restApi from "@axios";
import SecureLS from "secure-ls";
import moment from "moment";

let ls = new SecureLS();

export default {
  setPesquisaExternaComponenteInicial({ commit }, value) {
    commit("setPesquisaExternaComponenteInicial", value);
  },
  setPesquisaExternaComponenteFormulario({ commit }, value) {
    commit("setPesquisaExternaComponenteFormulario", value);
  },
  setPesquisaCodigoDialog({ commit }, value) {
    commit("setPesquisaCodigoDialog", value);
  },
  setPesquisaDialogExterna({ commit }, value) {
    commit("setPesquisaDialogExterna", value);
  },
  setHabilitarFormulario({ commit }, value) {
    commit("setHabilitarFormulario", value);
  },
  setRespostas({ commit }, value) {
    commit("setRespostas", value);
  },
  setPesquisaExternaIndex({ commit }, value) {
    commit("setPesquisaExternaIndex", value);
  },
  setPCOUltimaPerguntaRespondida({ commit }, value) {
    commit("setPCOUltimaPerguntaRespondida", value);
  },
  setPcoMensagemRetorno({ commit }, value) {
    commit("setPcoMensagemRetorno", value);
  },
  buscarPesquisas({ rootGetters, commit }) {
    commit("SET_LOADING");
    return new Promise((resolve, reject) => {
      restApi
        .get("pesquisas/initialize")
        .then((response) => {
          commit("SET_LOADING");
          if (response.data) {
            const dados = response.data.data;
            commit("SET_PESQUISAS", dados.pesquisas);
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => reject(error));
    }).catch((error) => {
      console.log(error);
    });
  },
  setPesquisaSelecionada({ commit }, uuid) {
    commit("SET_LOADING");
    return new Promise((resolve, reject) => {
      restApi
        .get(`pesquisas/buscar-pesquisa-especifica/${uuid}`)
        .then((response) => {
          if (response.data) {
            commit("SET_LOADING");
            commit("SET_PESQUISA_SELECIONADA", response.data.data.pesquisa);
            commit("SET_RESPONSAVEIS", response.data.data.responsaveis);
            commit("SET_MODELOS_LEGIIO", response.data.data.modelos_legiio);
            commit(
              "SET_MODELOS_PERSONALIZADOS",
              response.data.data.modelos_personalizados
            );
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => reject(error));
    }).catch((error) => reject(error));
  },
  /**
   * @description Busca por todos os dados de uma pesquisa.
   *
   * @param uuid string
   *
   * @dependency
   * - Está sendo utilizado no crud Pesquisas.
   *
   * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
   */
  fetchAllDataPesquisa({ commit }, uuid) {
    return new Promise(function (resolve, reject) {
      restApi
        .get(`pesquisas/fetch-all-data-pesquisa/${uuid}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  /**
   * @description Busca por todos os dados da pesquisa incluindo as perguntas relacionadas a ela.
   *
   * @param uuid
   *
   * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
   */
  fetchAllData({ commit, dispatch, rootGetters }, uuid) {
    return new Promise(function (resolve, reject) {
      restApi
        .get(`pesquisas/fetch-all-data/${uuid}`)
        .then((response) => {
          if (response.data) {
            const perguntas = response.data.data.perguntas
              ? response.data.data.perguntas
              : null;
            dispatch("pcoPerguntas/setPerguntas", perguntas, { root: true });

            delete response.data.data.perguntas;

            commit("SET_PESQUISA", response.data.data);
            commit("atualizaTabs", rootGetters["pcoPerguntas/getAllPerguntas"]);
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  setPesquisa({ commit }, data) {
    commit("SET_PESQUISA", data);
  },
  setLoading({ commit }) {
    commit("SET_LOADING");
  },
  save({ commit, rootGetters }, data) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      let perguntas = JSON.stringify(
        rootGetters["pcoPerguntas/getAllPerguntas"]
      );
      data.id ? formData.append("id", data.id) : null;
      data.uuid ? formData.append("uuid", data.uuid) : null;
      formData.append("tabela_email", data.arquivo ? data.arquivo : null);
      formData.append("titulo", data.titulo);
      formData.append("anonimo", data.anonimo);
      formData.append("cliente", data.cliente);
      formData.append("data_final", data.data_final);
      formData.append("data_inicio", data.data_inicio);
      formData.append("descricao", data.descricao);
      formData.append("devolutivas", data.devolutivas);
      formData.append("hora_final", data.hora_final);
      formData.append("hora_inicio", data.hora_inicio);
      formData.append("id_cliente", data.id_cliente);
      formData.append("id_modelo", data.id_modelo);
      formData.append("id_responsavel", data.id_responsavel);
      formData.append("modelo", data.modelo);
      formData.append("perguntas", perguntas);
      formData.append(
        "expectativa_de_respondentes",
        data.expectativa_de_respondentes
      );
      formData.append("status", data.status);
      formData.append("titulo", data.titulo);
      formData.append("is_edit", data.is_edit);
      formData.append("_method", data._method);

      restApi
        .post(data.url, formData)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  atualizarPesquisaStatus({ commit }, dados) {
    return new Promise((resolve, reject) => {
      restApi
        .post(dados.url, dados)
        .then((response) => {
          if (response.data.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.log(error);
          reject("NAO_AUTORIZADO");
        });
    }).catch((error) => {
      reject(error);
    });
  },
  deletarPesquisa({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      restApi
        .delete(`pesquisas/${uuid}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => reject(error));
    }).catch((error) => reject(error));
  },
  deletarModelo({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      restApi
        .delete(`modelos/${uuid}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => reject(error));
    }).catch((error) => reject(error));
  },
  buscarDadosPainel({ commit }, uuid) {
    commit("setLoadingFidelidade", true);
    restApi
      .post("pesquisas/painel", uuid)
      .then((response) => {
        commit("SET_DADOS_PAINEL", response.data.data);
        commit("setTotalizadoresPainel", response.data.data.totalizadores);
        commit("setBeneficiosPainel", response.data.data.beneficios);
        commit("setMotivacionalPainel", response.data.data.motivacional);
        commit("setDesmotivacionalPainel", response.data.data.desmotivacional);
        commit("setExternasPainel", response.data.data.externas);
        commit("setGruposPainel", response.data.data.grupos);
        commit(
          "setFavorabilidadeGeralPainel",
          response.data.data.favorabilidadeGeral
        );

        commit("setFidelidadePainel", response.data.data.fidelidade);
        commit("setFavorabilidadePainel", response.data.data.favorabilidade);
        commit("setListaPerguntasAbertas", response.data.data.abertas);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        commit("setLoadingFidelidade", false);
      });
  },
  initializePesquisaExterna({ commit, dispatch }) {
    const organizationalResearch = ls.get("organizationalResearchKey");
    const dataForm = ls.get("dataForm");
    dispatch("validateDatePesquisa");
    if (
      dataForm &&
      dataForm.dataForm != undefined &&
      organizationalResearch &&
      organizationalResearch.organizationalResearch !== undefined
    ) {
      commit("setPesquisaExternaComponenteInicial", false);

      commit("setPesquisaCodigoExternaMessage", dataForm.dataForm);
      commit(
        "setPesquisaExternaPerguntas",
        organizationalResearch.organizationalResearch
      );
      dispatch("retornaRespostasPesquisaExterna");
    } else {
      commit("setPCOLoadingEstruturaFormulario", false);
    }
  },
  buscarPesquisaExterna({ commit, dispatch }, data) {
    commit("setLoadingPesquisaExterna", true);
    const organizationalResearch = ls.get("organizationalResearchKey");
    const dataForm = ls.get("dataForm");
    dispatch("retornaDadosPesquisaFormExterno", data);
    if (!dataForm && !organizationalResearch) {
      commit("setPesquisaFinalizadaDialogExterna", true);
    }
  },
  retornaRespostasPesquisaExterna({ commit, dispatch }) {
    const dataForm = ls.get("dataForm");
    const organizationalResearch = ls.get("organizationalResearchKey");

    return new Promise(function (resolve, reject) {
      restApi
        .post("ext/pco-formularios-respostas/resposta-formulario", dataForm)
        .then((response) => {
          if (response.data) {
            commit(
              "setPesquisaExternaPerguntas",
              organizationalResearch.organizationalResearch
            );
            ls.remove("data-validate");
            // Atualiza a data da pesquisa
            ls.set("data-validate", { dataValidate: response.data.data.data });
            if (response.data.data.respostas) {
              dispatch("setRespostas", response.data.data.respostas);
            }
            dispatch("validateDatePesquisa");
            commit("setPesquisaExternaComponenteFormulario", true);
            resolve(response);
          } else {
            reject(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch(
            "snackBarModule/openSnackBar",
            {
              color: "error",
              timeout: 5000,
              text: error.data.message || error,
            },
            { root: true }
          );
          reject(error);
        })
        .finally(() => {
          commit("setPCOLoadingEstruturaFormulario", false);
        });
    });
  },
  retornaDadosPesquisaFormExterno({ rootGetters, commit, dispatch }, data) {
    console.log("RETORNA DADOS DA PESQUISA: ");
    const organizationalResearch = ls.get("organizationalResearchKey");
    const dataForm = ls.get("dataForm");
    restApi
      .post("ext/pesquisas/buscar-pesquisa-externa", data)
      .then((response) => {
        const data = response.data.data;
        if (data != null) {
          if (data.formulario && data.formulario != "") {
            //Modal do código Formulário
            if (dataForm.dataForm === undefined) {
              commit("setPesquisaCodigoDialog", true);
              commit("setPesquisaCodigoExternaMessage", data.formulario);
              ls.set("dataForm", { dataForm: data.formulario });
              ls.set("data-validate", { dataValidate: data.data });
            } else {
              commit("setPesquisaExternaComponenteInicial", false);
              commit("setPesquisaExternaComponenteFormulario", true);
              commit("setPesquisaCodigoExternaMessage", dataForm.dataForm);
            }
          }
          if (data.pesquisa && data.pesquisa != "") {
            commit("setPesquisaExternaDescricao", data.pesquisa);
          }
          if (data.perguntas) {
            if (organizationalResearch.organizationalResearch === undefined) {
              ls.set("organizationalResearchKey", {
                organizationalResearch: data.perguntas,
              });
              commit("setPesquisaExternaPerguntas", data.perguntas);
            } else {
              commit("setPesquisaExternaPerguntas", data.perguntas);
            }
          }

          if (data.pesquisa) {
            commit("setDadosPesquisaExterna", data);
            commit("setPesquisaExterna", data.pesquisa);
            // commit("setPesquisaDialogExterna", true);
          } else {
            commit("setPesquisaVencidaDialogExterna", true);
            commit(
              "setPesquisaExternaVencidaMessage",
              response.data.data.message
            );
            commit("setPesquisaExternaComponenteInicial", false);
            //commit("setPesquisaDialogExterna", true);
          }
        } else {
          commit("setErrorPesquisa", true);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        commit("setLoadingPesquisaExterna", false);
      });
  },
  retornaDadosPesquisaExternaPorToken({ commit, dispatch }, token) {
    console.log("RETORNA RESPOSTA DE PESQUISA: ", token.code);
    const dataForm = ls.get("dataForm");

    return new Promise(function (resolve, reject) {
      restApi
        .post("ext/pco-formularios/dados-formulario-token", token)
        .then((response) => {
          if (response.data) {
            const retorno = response.data.data;
            if (retorno.status && retorno.status.status == "FINALIZADO") {
              dispatch(
                "snackBarModule/openSnackBar",
                {
                  color: "success",
                  timeout: 5000,
                  text: retorno.status.message,
                },
                { root: true }
              );
            } else {
              ls.remove("data-validate");
              ls.remove("dataForm");
              ls.remove("organizationalResearchKey");
              // Atualiza a data da pesquisa
              ls.set("data-validate", { dataValidate: retorno.data });
              ls.set("dataForm", { dataForm: token.code });
              ls.set("organizationalResearchKey", {
                organizationalResearch: retorno.perguntas,
              });
              commit("setPesquisaCodigoExternaMessage", token.code);
              commit("setPesquisaExternaPerguntas", retorno.perguntas);
              dispatch("setRespostas", retorno.respostas);
              dispatch("validateDatePesquisa");
              let ultimaPergunta =
                retorno.respostas[retorno.respostas.length - 1];
              if (ultimaPergunta) {
                commit("setPCOUltimaPerguntaRespondida", ultimaPergunta);
                commit("setPesquisaExternaComponenteFormulario", true);
              }
              commit("setPesquisaExternaComponenteInicial", false);
            }

            resolve(response);
          } else {
            reject(response.data);
          }
        })
        .catch((error) => {
          dispatch(
            "snackBarModule/openSnackBar",
            {
              color: "error",
              timeout: 5000,
              text: error.data.message,
            },
            { root: true }
          );
          reject(error);
        })
        .finally(() => {
          commit("setPCOLoadingEstruturaFormulario", false);
        });
    });
  },
  salvarResposta({ commit, dispatch }, resposta) {
    return new Promise(function (resolve, reject) {
      const mensagem = "";
      commit("setPcoMensagemRetorno", "");
      restApi
        .post("ext/pco-formularios-respostas/salvar-resposta", { resposta })
        .then((response) => {
          const retorno = response.data.data;
          if (retorno.status === true) {
            commit("setPcoMensagemRetorno", retorno.mensagem);
          } else {
            dispatch(
              "snackBarModule/openSnackBar",
              {
                color: "error",
                timeout: 3000,
                text: "Erro ao salvar resposta",
              },
              { root: true }
            );
            commit("setPcoMensagemRetorno", "Erro ao salvar resposta");
          }
          resolve(response);
        })
        .catch((error) => {
          dispatch(
            "snackBarModule/openSnackBar",
            {
              color: "error",
              timeout: 5000,
              text: "Erro ao salvar resposta",
            },
            { root: true }
          );
          reject(false);
        })
        .finally(() => { });
    });
  },
  atualizarStatusFormulario({ commit }, uuidFormulario) {
    restApi
      .post("ext/pesquisas/formulario-atualizar", { uuid: uuidFormulario })
      .then((response) => {
        if (response.data.data) {
          const data = response.data.data;
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => { });
  },
  finalizarFormularioPesquisa({ rootGetters, commit }) {
    const dataForm = ls.get("dataForm");

    let respostas = rootGetters["pesquisas/getRespostas"];
    const data = {
      respostas: respostas,
      token: dataForm.dataForm,
    };

    restApi
      .post("ext/pco-formularios/finalizar-formulario-externo", data)
      .then((response) => {
        if (response.data.data == true) {
          ls.remove("data-validate");
          ls.remove("dataForm");
          ls.remove("organizationalResearchKey");

          commit("setPesquisaCodigoExternaMessage", "");
          commit("setPesquisaExternaPerguntas", []);
          commit("setPesquisaVencidaDialogExterna", true);
          commit(
            "setPesquisaExternaVencidaMessage",
            "Pesquisa finalizada com sucesso!"
          );

          commit("setDadosPesquisaExterna", []);
          commit("setRespostas", []);
          // commit("setPesquisaDialogExterna", false);
          // commit("setPesquisaFinalizadaDialogExterna", true);
          // commit("setHabilitarFormulario", false);
        }
      })
      .catch((error) => {
        dispatch(
          "snackBarModule/openSnackBar",
          {
            color: "error",
            timeout: 5000,
            text: error.data.message,
          },
          { root: true }
        );
        reject(error);
      })
      .finally(() => { });
  },
  buscarPerguntasPorModelo({ commit, dispatch, rootGetters }, data) {
    return new Promise(function (resolve, reject) {
      restApi
        .post(`/pco-perguntas/retorna-perguntas-modelo`, data)
        .then((response) => {
          if (response.data) {
            let perguntas = response.data.data;
            dispatch("pcoPerguntas/setPerguntas", perguntas, { root: true });
            commit("atualizaTabs", rootGetters["pcoPerguntas/getAllPerguntas"]);
            resolve(response.data.data);
          } else {
            reject(response.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  buscarDadosGraficoExternaAbertas({ commit }, data) {
    return new Promise(function (resolve, reject) {
      restApi
        .post(`/pesquisas/painel/grafico-abertas-internas`, data)
        .then((response) => {
          if (response.data) {
            commit("setDadosPesquisaExternaInternas", response.data.data);
            resolve(response);
          } else {
            reject(response.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  validateDatePesquisa({ commit }) {
    const fullDate = moment().locale("pt-br").format("YYYY-MM-DD HH:mm:ss");
    const data = ls.get("data-validate").dataValidate;

    if (moment(fullDate).isAfter(data) === true) {
      // REMOVER DADOS DA PESQUISA E HABILITAR MODAL DE PESQUISA FINNALIZADA
      ls.remove("data-validate");
      ls.remove("dataForm");
      ls.remove("organizationalResearchKey");

      commit("setPesquisaVencidaDialogExterna", true);
      commit(
        "setPesquisaExternaVencidaMessage",
        "Está pesquisa foi finalizada"
      );
      return false;
    } else {
      return true;
    }
  },
  // ModalCadastroPerguntas
  setDialogModalCadastroPerguntas({ commit }, bool) {
    commit("setDialogModalCadastroPerguntas", bool);
  },
  setCategoriaModalCadastroPerguntas({ commit }, data) {
    commit("setCategoriaModalCadastroPerguntas", data);
  },
  setPCOLoadingEstruturaFormulario({ commit }, data) {
    commit("setPCOLoadingEstruturaFormulario", data);
  },

  // ModalDeletePergunta
  setDialogDeletePergunta({ commit }, bool) {
    commit("setDialogDeletePergunta", bool);
  },
  setAtualizarTabs({ commit, rootGetters }, data) {
    if (data.isEdit && data.isEdit == true) {
      const perguntas =
        data.categoria == "FE"
          ? rootGetters["pcoPerguntas/getAllPerguntas"][data.categoria]
          : rootGetters["pcoPerguntas/getAllPerguntas"][data.categoria][
          data.subcategoria
          ];

      const index = perguntas.findIndex(
        (el) => el.code_front == data.code_front
      );

      perguntas[index] = data;
    } else {
      if (data.categoria == "FE") {
        rootGetters["pcoPerguntas/getAllPerguntas"][data.categoria].push(data);
      } else {
        rootGetters["pcoPerguntas/getAllPerguntas"][data.categoria][
          data.subcategoria
        ].push(data);
      }
    }

    commit("atualizaTabs", rootGetters["pcoPerguntas/getAllPerguntas"]);
  },

  // Dashboard
  fetchDatasTotalizers({ commit }, data) {
    return new Promise(function (resolve, reject) {
      restApi
        .get(`pesquisas/fetch-datas-totalizers/${data.uuid}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  exportarPesquisa({ commit }, data) {
    commit("setLoadingExportacao", true);
    restApi
      .get(`pesquisas/painel/exportar-excel/${data.uuid}`, {
        responseType: "arraybuffer"
      })
      .then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'exportacao-dados-pesquisa.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        commit("setLoadingExportacao", false);
      });
  },

  // Configuração pesquisa
  setModalPerguntaSelecionada({ commit }, data) {
    commit("setModalPerguntaSelecionada", data);
  },
};
