
export default {
    candidatoFormacao: {
        id_formacao: null,
        instituicao: '',
        curso: '',
        inicio: null,
        fim: null,
        status: null,
    },
    candidatosFormacoes: [],
    quantidadeFormacao: 0,
}