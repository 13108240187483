const Pesquisa = () => import("../views/Pesquisas.vue");
const Formulario = () => import("../views/Formulario.vue");
const ConfiguracaoPesquisa = () => import("../views/ConfiguracaoPesquisa.vue");

export default [
  {
    path: "/pco/pesquisas",
    name: "pco-pesquisas",
    component: Pesquisa,
    meta: {
      layout: "content",
      requiresAuth: true,
      resource: "pesquisa-clima",
      action: "access",
    },
  },
  {
    path: "/pco/pesquisas/configuracao/:action/:uuid?",
    name: "pco-configuracao-pesquisa",
    component: ConfiguracaoPesquisa,
    props: true,
    meta: {
      layout: "content",
      requiresAuth: true,
      resource: "pesquisa-clima",
      action: "access",
    },
  },
  {
    path: "/pco/externo/pesquisas/formulario/:uuid?",
    name: "pco-formulario-pesquisa",
    component: Formulario,
    meta: {
      layout: "blank",
      resource: "Public",
    },
  },
];

/**
 * NOTE
 * pco-configuracao-pesquisa: Verificar e validar permissão atribuida a está rota
 */
