import restApi from '@axios';

export default {
    startFormEvaluation({ commit }, data) {
        return new Promise(function (resolve, reject) {
            restApi.post('/ext/avd-formulario-colaborador/start-form-evaluation', data).then(response => {
                if (response.data) {
                    resolve(response.data.data)
                }

                reject('NAO_AUTORIZADO')
            }).catch(error => reject(error))
        })
    },
    finishFormEvaluation({ commit }, data) {
        return new Promise(function (resolve, reject) {
            restApi.post('/ext/avd-formulario-colaborador/finish-form-evaluation', data).then(response => {
                if (response.data) {
                    resolve(response.data.data)
                }

                reject('NAO_AUTORIZADO')
            }).catch(error => reject(error))
        })
    },
    findFormOfCollaboratorByUuid({ commit }, data) {
        return new Promise(function (resolve, reject) {
            restApi.get(`/ext/avd-formulario-colaborador/find-form-of-collaborator-by-uuid/${data.formUuid}/${data.collaboratorUuid}`).then((response) => {
                if (response.data) {
                    resolve(response.data.data)
                }

                reject('NAO_AUTORIZADO')
            }).catch(error => {
                reject(error)
            })
        })
    }
}