import { VAlert } from 'vuetify/lib/components/VAlert';
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,{attrs:{"id":"inspire"}},[_c(VAppBar,{attrs:{"app":"","color":"primary"}},[_c(VToolbarTitle,{staticStyle:{"color":"white","text-decoration":"none !important"}},[_vm._v(_vm._s(_vm.title))]),_c(VSpacer)],1),_c(VMain,[_c(VContainer,[(!_vm.dialog)?_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Formulário de cadastro empresa")]),_c(VCardText,[_c(VTabs,{staticClass:"d-md-block",attrs:{"show-arrows":"","grow":true},model:{value:(_vm.abaSelecionada),callback:function ($$v) {_vm.abaSelecionada=$$v},expression:"abaSelecionada"}},[_vm._l((_vm.tabsFormularioEmpresa),function(tab,index){return _c(VTab,{key:("tab-formulario-empresa-" + index),staticClass:"d-sm-block",staticStyle:{"color":"#5c6da7","font-weight":"400"},attrs:{"disabled":tab.disabled,"href":("#tab-" + index)}},[_vm._v(" "+_vm._s(tab.title)+" ")])}),_vm._l((_vm.tabsFormularioEmpresa),function(tab,index){return _c(VTabItem,{key:("tabs-items-formulario-empresa-" + index),staticStyle:{"padding":"20px"},attrs:{"value":("tab-" + index),"eager":true,"transition":"fade-transition","reverse-transition":"fade-transition"}},[_c(VCard,{staticStyle:{"background-color":"white !important"},attrs:{"flat":"","tile":""}},[_c(tab.component,{tag:"component"})],1)],1)})],2)],1)],1)],1)],1):_vm._e(),(_vm.cadastroConcluido)?_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VAlert,{attrs:{"dense":"","type":"info"}},[_c('strong',[_vm._v("Cadastro")]),_vm._v(" realializado aguarde o contato do setor financeiro. ")])],1)],1):_vm._e()],1)],1),_c('ModalCnpj')],1)}
var staticRenderFns = []

export { render, staticRenderFns }