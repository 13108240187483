export default [
  {
    path: "/adm/configuracoes/menu",
    name: "adm-config-menu",
    meta: {
      layout: "content",
      requiresAuth: true,
      resource: "menus",
      action: "access",
    },
    component: () =>
      import(
        "@/modules/administrativo/modules/configuracoes/modules/views/Menu.vue"
      ),
  },
  {
    path: "/adm/configuracoes/grupo-acesso",
    name: "adm-config-grupo-acesso",
    meta: {
      layout: "content",
      requiresAuth: true,
      resource: "grupoacesso",
      action: "access",
    },
    component: () => import("../views/GrupoAcesso.vue"),
  },
  {
    path: "/adm/configuracoes/papeis", //padrão para / se deixado em branco
    name: "adm-config-papeis",
    meta: {
      layout: "content",
      requiresAuth: true,
      resource: "configuracoes",
      action: "access",
    },
    component: () =>
      import(
        "@/modules/administrativo/modules/configuracoes/modules/views/Papeis.vue"
      ),
  },
  {
    path: "/adm/configuracoes/permissoes", //padrão para / se deixado em branco
    name: "adm-config-permissoes",
    meta: {
      layout: "content",
      requiresAuth: true,
      resource: "configuracoes",
      action: "access",
    },
    component: () =>
      import(
        "@/modules/administrativo/modules/configuracoes/modules/views/Permissoes.vue"
      ),
  },
  {
    path: "/adm/configuracoes/controle-acesso", //padrão para / se deixado em branco
    name: "adm-config-controle-acesso",
    meta: {
      layout: "content",
      requiresAuth: true,
      resource: "controleAcesso",
      action: "access",
    },
    component: () =>
      import(
        "@/modules/administrativo/modules/configuracoes/modules/views/ControleAcesso.vue"
      ),
  },
  {
    path: "/adm/configuracoes/definicoes-res", //padrão para / se deixado em branco
    name: "adm-config-definicoes-res",
    meta: {
      layout: "content",
      requiresAuth: true,
      resource: "definicoesRes",
      action: "access",
    },
    component: () =>
      import(
        "@/modules/administrativo/modules/configuracoes/modules/views/DefinicoesRES.vue"
      ),
  },
];
