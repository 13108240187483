
export default {
    setCandidato(state, data) {
        state.candidato = data
    },

    setModal(state, data) {
        state.modal = data
    },
    /**
     * @description Muda o card selecionado no state de genericCards.cardSelected
    */
    setCardSelected(state, data) {
        state.genericCards.cardSelected = data
    },
    
    setOportunidades(state, data) {
        state.oportunidades = data
    },
    setVagas(state, data) {
        state.vagas = data
    },
    setProcessos(state, data) {
        state.processos = data
    },

    setPessoais(state, data) {
        state.pessoais = data
    },
    setProfissionais(state, data) {
        state.profissionais = data
    }
}