export default {
  getMotivos: (state) => {
    return state.dataMotivo;
  },
  getListaSubmotivos: (state) => {
    return state.listaSubmotivos;
  },
  getLoading: (state) => {
    return state.loading;
  },
};
