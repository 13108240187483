
import restApi from '@axios'

export default {
    setEstadoCivil({ commit }, data) {
        commit('setEstadoCivil', data)
    },
    initialize({ commit }) {
        return new Promise(function (resolve, reject) {
            restApi.get('/res-estado-civil/initialize').then(response => {
                if (response.data) {
                    commit('setEstadosCivies', response.data.data)
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },
    save({ commit }, data) {
        return new Promise(function (resolve, reject) {
            restApi.post(data.url, data).then(response => {
                if (response.data) {
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },
    findByUuid({ commit }, uuid) {
        return new Promise(function (resolve, reject) {
            restApi.get(`/res-estado-civil/find-by-uuid/${uuid}`).then(response => {
                if (response.data) {
                    commit('setEstadoCivil', response.data.data)
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },
    remove({ commit }, uuid) {
        return new Promise(function (resolve, reject) {
            restApi.delete(`/res-estado-civil/${uuid}`).then(response => {
                if (response.data) {
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },
}