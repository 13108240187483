export default {
  ativarSnackbarGlobal(context, data) {
    // Caso não haja os parâmetros necessarios para o snackbar ele irá estourar erro.
    if (data.visibilidade && data.mensagem && data.cor) {
      context.commit("ATIVAR_SNACKBAR_GLOBAL", data);
    } else {
      console.error(
        "O snackbarGlobal precisa de parâmetros: \n visibilidade: Boolean, \n mensagem: String, \n cor: String"
      );
    }
  }
};
