export default {
  setUsuario(state, data) {
    state.usuario = data;
  },
  setUsuarios(state, data) {
    state.usuarios = data;
  },
  setResponsaveis(state, data) {
    state.responsaveis = data;
  },
  fetchCollaboratorsOfResearch(state, data) {
    state.colaboradores = data;
  },
  SET_LOADING(state) {
    state.loading = !state.loading;
  },
};
