
/**
 * @description Valida se existe um subdomínio ou não. Caso exista retornará true, senão false será retornado
 * 
 * @author Matheus Eduardo França <matheusefranca1727@gmail.com> 
*/
export function validateSubdomain() {
    const hostname = window.location.hostname;
    const arrayName = hostname.split('.');

    return arrayName.length <= 1 ? false : true;
}