const Dashboard = () => import("../views/Dashboard.vue");

export default [
  {
    path: "/pco/dashboard/:uuid",
    name: "pco-dashboard",
    meta: {
      layout: "content",
      requiresAuth: true,
      resource: "pesquisa-clima",
      action: "access",
    },
    component: Dashboard,
  },
];

/**
 * NOTE
 * pco-dashboard: Verificar e validar permissão atribuida a está rota
 */