import PainelRouter from '../painel/router/index.js'
import OportunidadeRouter from '../oportunidade/router/index.js'
import ProcessoRouter from '../processos/router/index.js'
import ConfiguracaoRouter from '../configuracao/router/index.js'

export default [
    ...PainelRouter,
    ...OportunidadeRouter,
    ...ProcessoRouter,
    ...ConfiguracaoRouter
]