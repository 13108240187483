
export default [
    {
        path: '/oportunidade',
        name: 'oportunidade',
        meta: {
            layout: "candidato",
            requiresAuth: false,
            resource: 'Public',
            action: 'read',
        },
        component: () => import(`../views/Oportunidade.vue`),
        beforeEnter(to, from, next) {
            const hostname = window.location.hostname;
            const arrayName = hostname.split('.');

            if (arrayName.length <= 1) return next({ name: 'error-404' });

            return next();
        },
    },
    {
        path: '/oportunidade/:uuid',
        name: 'oportunidade-vaga',
        meta: {
            layout: "candidato",
            requiresAuth: false,
            resource: 'Public',
            action: 'read',
        },
        component: () => import(`../views/VagaDetalhes.vue`),
        beforeEnter(to, from, next) {
            const hostname = window.location.hostname;
            const arrayName = hostname.split('.');

            if (arrayName.length <= 1) return next({ name: 'error-404' });

            return next();
        },
    },
]