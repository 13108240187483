
export default {
    setExperienciaProfissional(state, data) {
        state.experienciaProfissional = data
    },
    setExperienciasProfissionais(state, data) {
        state.experienciasProfissionais = data
    },
    setQuantidadeExperiencia(state, data) {
        state.quantidadeExperiencia = data
    }
}