
export default {
    competencia: {
        id_cliente: null,
        id_nivel: null,
        descricao: '',
        peso: 0,
        ids_cargos: [],
        ids_setores: [],
        comportamentos: [
            {
                descricao: ''
            }
        ]
    },
    competencias: [],
}