<template>
  <v-app>
    <AppBarCandidatoMobile v-if="isMobile" />
    <AppBarCandidatoDesktop v-else />

    <v-main>
      <v-container fluid style="width: 95% !important">
        <slot></slot>
      </v-container>
    </v-main>

    <SnackBar />
  </v-app>
</template>

<script>
import AppBarCandidatoDesktop from "@/components/AppBarCandidatoDesktop.vue";
import AppBarCandidatoMobile from "@/components/AppBarCandidatoMobile.vue";
import SnackBar from "@/components/SnackBar.vue";

export default {
  name: "LayoutCandidato",
  components: {
    AppBarCandidatoDesktop,
    AppBarCandidatoMobile,
    SnackBar,
  },
  computed: {
    /**
     * @description Verifica se o usuário que está acessando é Mobile ou Desktop, caso for mobile será retornado TRUE, senão FALSE será retornado.
     *
     * @return boolean
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    isMobile() {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        return true; // está utilizando celular
      } else {
        return false; // não é celular
      }
    },
  },
};
</script>

<style></style>
