import AdmRouter from "@/modules/administrativo/router/index.js";
import PcoRouter from "@/modules/pco/router/index.js";
import ExternoRouter from "@/modules/externo/modules/empresas/router";
import AvdRouter from "@/modules/avd/router/index.js";
import PainelAvd from "@/modules/avd/painel/router/index.js";
import ModeloAvd from "@/modules/avd/modelos/router/index.js";
import ClienteRouter from "@/modules/clientes/router/index.js";
import ResRouter from '@/modules/res/router/index.js'
/*import EmpresasRouter from "./../modules/empresas/router";
import MotivosRouter from "./../modules/motivos/router";
import SubmotivosRouter from "./../modules/submotivos/router";
import ConfiguracoesRouter from "./../modules/configuracoes/router";
import GruposRouter from "./../modules/pco/grupos/router"; */
/*
import EscalasRouter from "./../modules/pco/escalas/router";
*/

import SiteRouter from '@/views/site/router/index.js';

export default [
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/Error404.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
    },
  },
  {
    path: "/not-authorized",
    name: "misc-not-authorized",
    component: () => import("@/views/NotAuthorized.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
    },
  },
  {
    path: "/authorize/github/callback",
    name: "auth-login-github",
    component: () => import("@/views/LoginGithub.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
      requiresAuth: false,
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/login",
    name: "auth-login",
    component: () => import("@/views/Login.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
      requiresAuth: false,
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/register",
    name: "auth-register",
    component: () => import("@/views/Register.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
      requiresAuth: false,
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/forgot-password",
    name: "auth-forgot-password",
    component: () => import("@/views/ForgotPassword.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
      requiresAuth: false,
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/reset-password/:token",
    name: "auth-reset-password",
    component: () => import("@/views/ResetPassword.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
      requiresAuth: false,
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "*",
    redirect: "error-404",
  },
  {
    path: "*",
    redirect: {
      name: "catchAll",
      path: "/",
    },
    meta: {
      requiresAuth: true,
    },
  },
  ...AdmRouter,
  ...PcoRouter,
  ...ExternoRouter,
  ...AvdRouter,
  ...PainelAvd,
  ...ModeloAvd,
  ...ClienteRouter,
  ...ResRouter,
  /*...EmpresasRouter,
  ...MotivosRouter,
  ...SubmotivosRouter,
  ...ConfiguracoesRouter,
  ...GruposRouter, */
  /* ...ClientesRouter,
  ...EscalasRouter,*/
  ...SiteRouter
];
