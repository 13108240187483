import restApi from "@axios";

export default {
  setColaborador({ commit }, data) {
    commit("setColaborador", data);
  },
  setDataSpreadsheets({ commit }, data) {
    commit("setDataSpreadsheets", data);
  },
  /**
   * @description Retorna todos os dados, porém, somente com as colunas desejadas.
   *
   * @param data - Object
   *
   * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
   */
  findByColumns({ commit, dispatch }, data = Object) {
    return new Promise(function (resolve, reject) {
      restApi
        .post("/avd/colaborador/find-by-columns", data)
        .then((response) => {
          if (response.data.data) {
            commit("setColaboradores", response.data.data);
            /**!SECTION
             *  - CRIAR E ATUALIZAR VUEX GESTORES POR SETOR
                - CRIAR E ATUALIZAR VUEX DE COLABORADORES POR SETOR
             */
            dispatch("processarColaboradores", response.data.data);
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  /**
   * @description Busca por um dado a partir do uuid.
   *
   * @param uuid - String
   *
   * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
   */
  findByUuid({ commit }, uuid = String) {
    return new Promise(function (resolve, reject) {
      restApi
        .get(`/avd/colaborador/find-by-uuid/${uuid}`)
        .then((response) => {
          if (response.data.data) {
            commit("setColaborador", response.data.data);
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  /**
   * @description Salva ou atualiza um colaborador
   *
   * @param data - Object
   *
   * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
   */
  save({ commit }, data = Object) {
    return new Promise(function (resolve, reject) {
      restApi
        .post(data.url, data)
        .then((response) => {
          if (response.data.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  /**
   * @description Remove um dado a partir do uuid do mesmo.
   *
   * @param uuid - String
   *
   * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
   */
  remove({ commit }, uuid = String) {
    return new Promise(function (resolve, reject) {
      restApi
        .delete(`/avd/colaborador/${uuid}`)
        .then((response) => {
          if (response.data.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  /**
   * @description Importa colaboradores da planilha enviada e retorna os mesmo cadastrados no sistema.
   *
   * @param data - FormData
   *
   * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
   */
  importCollaborators({ commit }, data) {
    return new Promise(function (resolve, reject) {
      restApi
        .post("/avd/colaborador/import-collaborators", data)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  /**
   * @description . Salva tipos de avaliações por colaboradores
   * @param data - FormData
   * @author Johnny Santos <johnny.santos@sociisrh.com.br>
   */
  salvarAvaliacoesDeColaboradores({ commit }, data) {
    return new Promise(function (resolve, reject) {
      restApi
        .post("/avd/avaliacao-colaboradores", data)
        .then((response) => {
          if (response.data.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  /**!SECTION */
  processarColaboradores({ commit, dispatch }, data) {
    if (data && data.length == 0) return true;
    let objetoGestores = [];
    let objetoColaboradores = [];
    let objetoSetores = [];
    // Separa os colaboradores dos gestores
    data.map((e) => {
      e.gestor === 1 ? objetoGestores.push(e) : objetoColaboradores.push(e);
    });
    // Cria array com os setores
    data.map((e) => {
      e.setor !== "";
      objetoSetores.push(e.setor);
    });
    objetoSetores = [...new Set(objetoSetores)];
    // Montar array de grupos com seus respectivos colaboradores
    const colaboradoresSetor = [];
    objetoSetores.forEach((k) => {
      colaboradoresSetor.push({ header: k });
      objetoColaboradores.forEach((l) => {
        if (l.setor === k) {
          colaboradoresSetor.push({
            id: l.id,
            uuid: l.uuid,
            nome: l.nome,
            cargo: l.cargo,
            id_gestor: l.id_gestor,
            group: k,
          });
        }
      });
    });
    const gestoresSetor = [];
    objetoSetores.forEach((k) => {
      gestoresSetor.push({ header: k });
      objetoGestores.forEach((l) => {
        if (l.setor === k) {
          gestoresSetor.push({
            id: l.id,
            nome: l.nome,
            cargo: l.cargo,
            uuid: l.uuid,
            group: k,
          });
        }
      });
    });
    commit("setListaGestrores", gestoresSetor);
    commit("setListaColaboradores", colaboradoresSetor);
  },
};
