export default {
  time: {
    nome: '',
    id_filial: null,
    id_setor: null,
    id_coordenador: null,
    descricao: '',
    status: 'A'
  },
  times: [],
};
