export default {
  setPropostas(state, value) {
    state.propostas = value;
  },
  deletarProposta(state, uuid) {
    state.propostas = state.propostas.filter(
      (element) => element.uuid !== uuid
    );
  },
  setProdutos(state, value) {
    state.produtos = value;
  },
  setMotivos(state, value) {
    state.motivos = value;
  },
  SET_LOADING(state) {
    state.loading = !state.loading;
  },
};
