import restApi from "@axios";

export default {
  //EMPRESA
  buscarEmpresas({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING");
      restApi
        .get("/empresas/initialize")
        .then((response) => {
          commit("SET_LOADING");
          if (response.data) {
            commit("setEmpresas", response.data.data.empresas);
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  buscarEmpresaSelecionada({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING");
      restApi
        .post("/empresas/buscar-dados-empresa", { uuid: uuid })
        .then((response) => {
          commit("SET_LOADING");
          if (response.data) {
            commit("setEmpresaSelecionada", response.data.data);
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  salvarEmpresa({ commit }, data) {
    return new Promise((resolve, reject) => {
      restApi
        .post(data.url, data)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  deletarEmpresa({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      restApi
        .delete(`/empresas/${uuid}`)
        .then((response) => {
          if (response.data) {
            commit("setDeletarEmpresa", response.data);
            resolve(response.data);
          } else {
            commit("setDeletarEmpresa", null);
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  /**
   * @description Busca dados basicos da empresa utilizando o slug.
  */
  getEmpresaBySlug({ commit }, slug) {
    return new Promise((resolve, reject) => {
      restApi
        .get(`ext/empresas/get-empresa-by-slug/${slug}`)
        .then((response) => {
          if (response.data) {
            commit('setEmpresa', response.data.data)
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    })
  },
  // USUÁRIOS
  buscarUsuarios({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING");
      restApi
        .post("/empresas/buscar-dados-empresa-usuarios", {
          uuid: data.uuid,
          trazFiliais: data.trazFiliais,
          trazSetores: data.trazSetores,
        })
        .then((response) => {
          commit("SET_LOADING");
          if (response.data) {
            commit("setEmpresaUsuarios", response.data.data.usuarios);
            commit("setEmpresaUsuariosSelect", response.data.data.usuarios);
            commit("setRoles", response.data.data.roles);
            commit("setEmpresaCargos", response.data.data.cargos);

            if (data.trazFiliais)
              commit("setEmpresaFiliaisSelect", response.data.data.filiais);
            if (data.trazSetores)
              commit("setEmpresaSetoresSelect", response.data.data.setores);

            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  buscarUsuarioSelecionado({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      restApi
        .post("/empresas/buscar-dados-empresa-usuario-selecionado", {
          uuid: uuid,
        })
        .then((response) => {
          console.log(response.data);
          if (response.data) {
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  salvarEmpresaUsuario({ commit }, data) {
    const formData = new FormData();
    data.foto ? formData.append("arquivo", data.arquivo) : null;
    formData.append("nome", data.nome);
    formData.append("id", data.id);
    formData.append("id_empresa", data.id_empresa);
    formData.append("id_grupo_acesso", data.id_grupo_acesso);
    formData.append("aniversario", data.aniversario);
    formData.append("email", data.email);
    formData.append("celular", data.celular);
    formData.append("gestor", data.gestor);
    formData.append("coordenador", data.coordenador);
    formData.append("status", data.status);
    formData.append("papel", data.papel);
    formData.append("relacionamento", data.relacionamento);
    formData.append("grupo_de_acesso", data.grupo_de_acesso);
    formData.append("endereco", data.endereco);
    formData.append("_method", data._method);

    return new Promise((resolve, reject) => {
      restApi
        .post(data.url, formData)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  deletarEmpresaUsuario({ commit }, data) {
    return new Promise((resolve, reject) => {
      restApi
        .delete(`/usuarios/${data}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  //FILIAL
  buscarFiliais({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING");
      restApi
        .post("/empresas/buscar-dados-empresa-filiais", { uuid: uuid })
        .then((response) => {
          commit("SET_LOADING");
          if (response.data) {
            commit("setEmpresaFiliais", response.data.data.filiais);
            commit("setEmpresaFiliaisSelect", response.data.data.filiais);
            commit("setEmpresaResponsaveis", response.data.data.responsaveis);
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  buscarFilialSelecionada({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      restApi
        .post("/empresas/buscar-dados-empresa-filial-selecionada", {
          uuid: uuid,
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  salvarEmpresaFilial({ commit }, data) {
    return new Promise((resolve, reject) => {
      restApi
        .post(data.url, data)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  deletarEmpresaFilial({ commit }, data) {
    return new Promise((resolve, reject) => {
      restApi
        .delete(`/filiais/${data}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  // SETORES
  buscarSetores({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING");
      restApi
        .post("/empresas/buscar-dados-empresa-setores", {
          uuid: data.uuid,
          trazFiliais: data.trazFiliais,
        })
        .then((response) => {
          commit("SET_LOADING");
          if (response.data) {
            commit("setEmpresaSetores", response.data.data.setores);
            commit("setEmpresaSetoresSelect", response.data.data.setores);
            if (data.trazFiliais)
              commit("setEmpresaFiliaisSelect", response.data.data.filiais);
            commit("setEmpresaResponsaveis", response.data.data.responsaveis);
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  buscarSetorSelecionado({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      restApi
        .post("/empresas/buscar-dados-empresa-setor-selecionado", {
          uuid: uuid,
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  salvarEmpresaSetor({ commit }, data) {
    return new Promise((resolve, reject) => {
      restApi
        .post(data.url, data)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  deletarEmpresaSetores({ commit }, data) {
    return new Promise((resolve, reject) => {
      restApi
        .delete(`/setor/${data}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  // CARGOS
  buscarCargos({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING");
      restApi
        .post("/empresas/buscar-dados-empresa-cargos", {
          uuid: data.uuid,
          trazFiliais: data.trazFiliais,
          trazSetores: data.trazSetores,
        })
        .then((response) => {
          commit("SET_LOADING");
          if (response.data) {
            commit("setEmpresaCargos", response.data.data.cargos);
            commit("setEmpresaCargosSelect", response.data.data.cargos);
            if (data.trazFiliais)
              commit("setEmpresaFiliaisSelect", response.data.data.filiais);
            if (data.trazSetores)
              commit("setEmpresaSetoresSelect", response.data.data.setores);
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  buscarCargoSelecionado({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      restApi
        .post("/empresas/buscar-dados-empresa-cargo-selecionado", {
          uuid: uuid,
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  salvarEmpresaCargo({ commit }, data) {
    return new Promise((resolve, reject) => {
      restApi
        .post(data.url, data)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  deletarEmpresaCargo({ commit }, data) {
    return new Promise((resolve, reject) => {
      restApi
        .delete(`/cargos/${data}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  // COMPETÊNCIAS
  buscarCompetenciasInitialize({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING");
      restApi
        .get("/competencias/initialize")
        .then((response) => {
          commit("SET_LOADING");
          if (response.data) {
            commit("setEmpresaCompetencias", response.data.data.competencias);
            commit("setEmpresaFiliaisSelect", response.data.data.filiais);
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  buscarCompetencias({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING");
      restApi
        .post("/empresas/buscar-dados-empresa-competencias", uuid)
        .then((response) => {
          commit("SET_LOADING");
          if (response.data) {
            commit("setEmpresaCompetencias", response.data.data.competencias);
            commit("setEmpresaFiliaisSelect", response.data.data.filiais);
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  buscarCompetenciaSelecionada({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      restApi
        .post("/empresas/buscar-dados-empresa-competencia-selecionada", {
          uuid: uuid,
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  salvarEmpresaCompetencia({ commit }, data) {
    return new Promise((resolve, reject) => {
      restApi
        .post(data.url, data)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  deletarEmpresaCompetencia({ commit }, data) {
    return new Promise((resolve, reject) => {
      restApi
        .delete(`/competencias/${data}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  // TIMES
  buscarTimes({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING");
      restApi
        .post("/empresas/buscar-dados-empresa-times", {
          uuid: data.uuid,
          trazSetores: data.trazSetores,
          trazUsuarios: data.trazUsuarios,
        })
        .then((response) => {
          commit("SET_LOADING");
          if (response.data) {
            commit("setEmpresaTimes", response.data.data.times);
            if (data.trazSetores)
              commit("setEmpresaSetoresSelect", response.data.data.setores);
            if (data.trazUsuarios)
              commit("setEmpresaUsuariosSelect", response.data.data.usuarios);
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  buscarTimeSelecionado({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      restApi
        .post("/empresas/buscar-dados-empresa-time-selecionado", { uuid: uuid })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  salvarEmpresaTime({ commit }, data) {
    return new Promise((resolve, reject) => {
      restApi
        .post(data.url, data)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => reject(error));
    });
  },
  deletarEmpresaTimes({ commit }, data) {
    return new Promise((resolve, reject) => {
      restApi
        .delete(`/times/${data}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  // CONTRATOS
  buscarContratos({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING");
      restApi
        .post("/empresas/buscar-dados-empresa-contratos", { uuid: uuid })
        .then((response) => {
          commit("SET_LOADING");
          if (response.data) {
            commit("setEmpresaContratos", response.data.data.contratos);
            commit("setEmpresaFiliais", response.data.data.filiais);
            commit("setEmpresaFiliaisSelect", response.data.data.filiais);
            commit("setEmpresaPropostas", response.data.data.propostas);
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  buscarContratoSelecionado({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      restApi
        .post("/empresas/buscar-dados-empresa-contrato-selecionado", {
          uuid: uuid,
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  salvarEmpresaContrato({ commit }, data) {
    return new Promise((resolve, reject) => {
      restApi
        .post(data.url, data)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => reject(error));
    });
  },
  deletarContrato({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      restApi
        .delete(`/contratos/${uuid}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  //CNPJ
  buscarEmpresaPorCnpj({ commit }, cnpj) {
    return new Promise((resolve, reject) => {
      commit("setLoadingModalCnpj", true);
      restApi
        .post("/ext/empresas/buscar-dados-empresa-cnpj", { cnpj: cnpj })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
            commit("setLoadingModalCnpj", false);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("setLoadingModalCnpj", false);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  buscarEmpresaPorUid({ commit }, uid) {
    return new Promise((resolve, reject) => {
      commit("setLoadingModalCnpj", true);
      restApi
        .post("/ext/empresas/buscar-dados-empresa-uid", { uid: uid })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
            commit("setLoadingModalCnpj", false);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("setLoadingModalCnpj", false);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  setDialogModalCnpj({ commit }, value) {
    commit("setDialogModalCnpj", value);
  },
  //Formulário Externo
  salvarEmpresaFormularioExterno({ commit }, formulario) {
    return new Promise((resolve, reject) => {
      restApi
        .post("/ext/empresas/salvar-dados-empresa-cnpj", {
          formulario: formulario,
        })
        .then((response) => {
          if (response.data) {
            commit("setLoadingModalCnpj", false);
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => { });
    }).catch((error) => {
      reject(error);
    });
  },
  buscarEmpresasAtivas({ commit }) {
    return new Promise((resolve, reject) => {
      restApi
        .get("/empresas/buscar-empresas-ativas")
        .then((response) => {
          if (response.data) {
            commit("setEmpresasAtivas", response.data.data);
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  setListaEmpresas({ commit }, value) {
    commit("setListaEmpresas", value);
  },

  // LAYOUT EMPRESA MODULOS
  setDatasLayoutModulos({ commit }, data) {
    commit("setDatasLayoutModulos", data);
  },
};
