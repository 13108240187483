const Modelos = () => import("../views/Modelos.vue");
const Formulario = () => import("../views/Formulario.vue");

export default [
  {
    path: "/pco/modelos",
    name: "pco-modelos",
    component: Modelos,
    meta: {
      layout: "content",
      requiresAuth: true,
      resource: "pesquisa-clima",
      action: "access",
    },
  },
  {
    path: "/pco/modelos/formulario/:action/:uuid?",
    name: "pco-modelos-formulario",
    props: true,
    component: Formulario,
    meta: {
      layout: "content",
      requiresAuth: true,
      resource: "pesquisa-clima",
      action: "access",
    },
  },
];
