// import SecureLs from "secure-ls";
import restApi from "@axios";
import moment from "moment";

export default {
  // Avaliacao
  setTabFormSelected({ commit }, data) {
    commit('setTabFormSelected', data)
  },
  finishEvaluation({ commit }, uuid) {
    return new Promise(function (resolve, reject) {
      restApi.post(`/avd/avaliacoes/finish-evaluation/${uuid}`).then(response => {
        if (response.data) {
          resolve(response.data.data)
        }

        reject(response.data.data)
      }).catch(error => reject(error))
    })
  },

  /**
   * @description Está e a primeira função a ser executada assim que a página de avaliacoes é acessada, pois a mesma irá buscar o básico das avaliacoes
   */
  initializeAvaliacao({ commit }) {
    return new Promise(function (resolve, reject) {
      commit("setLoading");
      restApi
        .get("/avd/avaliacoes/initialize")
        .then((response) => {
          if (response.data.data) {
            commit("setAvaliacoes", response.data.data);
            resolve(response.data.data);
          } else {
            reject(response.data);
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("setLoading");
        });
    });
  },
  /**
   * @description Busca todos os dados da avaliação, incluindo os colaboradores.
   */
  fullDataAvaliacao({ commit }, uuid) {
    return new Promise(function (resolve, reject) {
      restApi
        .get(`avd/avaliacoes/fetch-full-data-avaliacao/${uuid}`)
        .then((response) => {
          if (response.data) {
            commit("setFullDataAvaliacao", response.data.data);
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  /**
   * @description Reseta os dados de avaliação
   */
  resetAvaliacao({ commit }) {
    const data = {
      id: null,
      uuid: "",
      titulo: "",
      descricao: "",
      escala: 1, // O usuario irá definir a escala de 1 a 10 para a avaliação.
      id_responsavel: null,
      id_avd_modelo_registro: null,
      aplica_todos: true,
      status: "",
      data_inicio: "",
      hora_inicio: "",
      data_final: "",
      hora_final: "",
    };

    commit("resetAvaliacao", data);
  },
  /**
   * @description Salva avaliação
   */
  saveAvaliacao({ commit }, data) {
    return new Promise(function (resolve, reject) {
      restApi
        .post(data.url, data)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  /**
   * @description Busca por todos os dados da avaliação apartir uuid.
   */
  getFindByUuidEvaluation({ commit, dispatch }, uuid) {
    return new Promise(function (resolve, reject) {
      restApi
        .get(`/avd/avaliacoes/find-by-uuid/${uuid}`)
        .then((response) => {
          if (response.data) {
            response.data.data.data_inicio = moment(
              response.data.data.data_hora_inicio,
              true
            ).format("YYYY-MM-DD");
            response.data.data.hora_inicio = moment(
              response.data.data.data_hora_inicio,
              true
            ).format("HH:mm");
            response.data.data.data_final = moment(
              response.data.data.data_hora_final,
              true
            ).format("YYYY-MM-DD");
            response.data.data.hora_final = moment(
              response.data.data.data_hora_final,
              true
            ).format("HH:mm");

            let tipos = {
              COM: response.data.data.comportamental == 'A' ? true : false,
              TEC: response.data.data.tecnica == 'A' ? true : false
            };

            response.data.data.dataInicio = response.data.data.data_hora_inicio;
            response.data.data.tipo_avaliacao = tipos;

            commit("setAvaliacao", response.data.data);
            commit("setIsShowStepper", false);

            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  /**
   * @description Remove avaliação
   */
  deleteEvaluation({ commit }, uuid) {
    return new Promise(function (resolve, reject) {
      restApi
        .delete(`/avd/avaliacoes/${uuid}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  /**
   * @description Busca por colaboradores para o formulario de cadastro/editar;
   */
  buscarDadosColaboradoresFormularioDeAvaliacao({ commit }) {
    commit("setLoadingFormulario");
    setTimeout(() => {
      commit("setLoadingFormulario");
    }, 3000);

    const data = {
      colaboradores: [
        {
          id: 1,
          nome: "Matheus França",
          foto: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
        },
        {
          id: 2,
          nome: "Sandro Souza",
          foto: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
        },
        {
          id: 3,
          nome: "Johnny Santos",
          foto: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        },
        {
          id: 4,
          nome: "João Maria",
          foto: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        },
      ],
    };

    commit("setColaboradores", data.colaboradores);
  },
  /**
   * @description Busca dados para visualização da avaliação. A busca por dados é progressiva!
   */
  buscarDadosDaAvaliacao({ commit, state }, data) {
    const dataItem = {
      uuid: data.uuid,
      buscar: "",
    };

    if (!state.uuidDaAvaliacao && dataItem.uuid) {
      // Faz uma requisição buscando por configuração
      dataItem.buscar = "configuracao";

      const configuracao = {
        descricao:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam, dolores molestiae blanditiis aut quas officia eaque non assumenda qui. Ipsam minima, animi, earum, libero possimus a pariatur tenetur maiores rem eos molestias nesciunt tempora quae error voluptate na eaque? Dolorem in ratione magnam optio inventore doloremque cumque esse distinctio, tenetur totam libero provident ab explicabo voluptas eaque consequatur. Harum, maxime? Molestiae aut pariatur iste distinctio amet a facere ea optio iure, laboriosam repudiandae veritatis unde nostrum? Dolorum neque fuga recusandae! Magnam architecto, ipsa sapiente atque impedit non temporibus, aperiam sed reiciendis magni ab ipsam quasi, numquam deserunt optio enim eligendi?",
        tipos: ["AP", "AA", "AL", "AG", "AC"],
        status: "C",
        escala: "Concordo... Discordo",
        aplica_todos_colaboradores: false,
        data_inicio: "2022-02-05",
        hora_inicio: "13:09:14",
        data_final: "2022-02-05",
        hora_final: "13:09:14",
      };

      commit("setUuidDaAvaliacao", dataItem.uuid);
    }

    if (state.uuidDaAvaliacao !== dataItem.uuid) return;
    switch (data.tab) {
      // Colaboradores
      case "tab-2":
        if (state.colaboradoresDaAvaliacao.length > 0) break;
        dataItem.buscar = "colaboradores";

        const colaboradores = [
          {
            id: 1,
            foto: "https://cdn.pixabay.com/photo/2020/12/21/19/05/window-5850628_960_720.png",
            nome: "Matheus Eduardo França",
            cargo: "Estagiário de Desenvolvimento",
          },
          {
            id: 2,
            foto: "https://cdn.pixabay.com/photo/2020/12/21/19/05/window-5850628_960_720.png",
            nome: "Zeca",
            cargo: "Estagiário de Desenvolvimento",
          },
          {
            id: 3,
            foto: "https://cdn.pixabay.com/photo/2020/12/21/19/05/window-5850628_960_720.png",
            nome: "Osvaldo",
            cargo: "Estagiário de Desenvolvimento",
          },
          {
            id: 4,
            foto: "https://cdn.pixabay.com/photo/2020/12/21/19/05/window-5850628_960_720.png",
            nome: "Maria",
            cargo: "Estagiário de Desenvolvimento",
          },
        ];

        break;
      // Filiais
      case "tab-3":
        if (state.filiaisDaAvaliacao.length > 0) break;
        dataItem.buscar = "filiais";

        const filiais = [
          {
            id: 1,
            nome: "Filial 01",
          },
          {
            id: 2,
            nome: "Filial 02",
          },
          {
            id: 3,
            nome: "Filial 03",
          },
          {
            id: 4,
            nome: "Filial 04",
          },
          {
            id: 5,
            nome: "Filial 05",
          },
        ];

        commit("setFiliaisDaAvaliacao", filiais);
        break;
      // Setores
      case "tab-4":
        if (state.setoresDaAvaliacao.length > 0) break;
        dataItem.buscar = "setores";

        const setores = [
          {
            id: 1,
            nome: "Setor 001",
          },
          {
            id: 2,
            nome: "Setor 002",
          },
          {
            id: 3,
            nome: "Setor 003",
          },
          {
            id: 4,
            nome: "Setor 004",
          },
        ];

        commit("setSetoresDaAvaliacao", setores);
        break;
      // Times
      case "tab-5":
        if (state.timesDaAvaliacao.length > 0) break;
        dataItem.buscar = "times";

        const times = [
          {
            id: 1,
            nome: "Time 01",
          },
          {
            id: 2,
            nome: "Time 02",
          },
          {
            id: 3,
            nome: "Time 03",
          },
          {
            id: 4,
            nome: "Time 04",
          },
        ];

        commit("setTimesDaAvaliacao", times);
        break;
      // Cargos
      case "tab-6":
        if (state.cargosDaAvaliacao.length > 0) break;
        dataItem.buscar = "cargos";

        const cargos = [
          {
            id: 1,
            nome: "Cargo 001",
          },
          {
            id: 2,
            nome: "Cargo 002",
          },
          {
            id: 3,
            nome: "Cargo 003",
          },
          {
            id: 4,
            nome: "Cargo 004",
          },
        ];

        commit("setCargosDaAvaliacao", cargos);
        break;
    }
  },
  /**
   * @description Limpa os dados de visualizar avaliação
   */
  clearDadosDeVisualizarAvaliacao({ commit }) {
    commit("setUuidDaAvaliacao", "");
    commit("setFiliaisDaAvaliacao", []);
    commit("setSetoresDaAvaliacao", []);
    commit("setTimesDaAvaliacao", []);
    commit("setCargosDaAvaliacao", []);
  },
  /**
   * @description Limpa o state avaliacao
   */
  clearDataAvaliacao({ commit }) {
    const novaAvaliacao = {
      id: 0,
      uuid: "",
      titulo: "",
      descricao: "",
      tipos: [],
      escala: 10, // O usuario irá definir a escala de 1 a 10 para a avaliação.
      responsavel: null,
      id_avd_modelo_registro: null,
      aplica_todos: true,
      filiais: [],
      setores: [],
      times: [],
      cargos: [],
      colaboradores: [],
      tipos: {
        AA: [],
        AP: [],
        AL: [],
        AS: [],
        AC: [],
      },
      status: "",
      data_inicio: "",
      hora_inicio: "",
      data_final: "",
      hora_final: "",
      created_at: " ",
      updated_at: " ",
    };

    commit("setAvaliacao", novaAvaliacao);
  },

  // Painel

  initialize({ commit }) {
    const filiais = [
      {
        id: 1,
        nome: "Filial 1",
        responsaveis: [
          {
            id: 1,
            nome: "Gestor 1",
          },
        ],
        times: [
          {
            id: 1,
            nome: "Time 1",
          },
        ],
        cargos: [
          {
            id: 1,
            nome: "Cargos 1",
          },
        ],
      },
      {
        id: 2,
        nome: "Filial 2",
        setores: [
          {
            id: 2,
            nome: "Setores 2",
          },
        ],
        responsaveis: [
          {
            id: 2,
            nome: "Gestor 2",
          },
        ],
        times: [
          {
            id: 2,
            nome: "Time 2",
          },
        ],
      },
      {
        id: 3,
        nome: "Filial 3",
        setores: [
          {
            id: 3,
            nome: "Setores 3",
          },
        ],
        responsaveis: [
          {
            id: 3,
            nome: "Gestor 3",
          },
        ],
        times: [
          {
            id: 3,
            nome: "Time 3",
          },
        ],
        cargos: [
          {
            id: 3,
            nome: "Cargos 3",
          },
        ],
      },
      {
        id: 4,
        nome: "Filial 4",
        setores: [
          {
            id: 4,
            nome: "Setores 4",
          },
        ],
        responsaveis: [
          {
            id: 4,
            nome: "Gestor 4",
          },
        ],
        times: [
          {
            id: 4,
            nome: "Time 4",
          },
        ],
        cargos: [
          {
            id: 4,
            nome: "Cargos 4",
          },
        ],
      },
      {
        id: 5,
        nome: "Filial 5",
        setores: [
          {
            id: 5,
            nome: "Setores 5",
          },
        ],
        responsaveis: [
          {
            id: 5,
            nome: "Gestor 5",
          },
        ],
        times: [
          {
            id: 5,
            nome: "Time 5",
          },
        ],
        cargos: [
          {
            id: 5,
            nome: "Cargos 5",
          },
        ],
      },
    ];

    commit("setFilias", filiais);
  },
  buscarPainelAvaliacao({ commit }) {
    const data = {
      avaliacao: [
        {
          id: 23,
          uuid: "sodjd8-sjkdsb-394djd-fifd8",
          nome: "Avaliação 01",
          descricao:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam, dolores molestiae blanditiis aut quas officia eaque non assumenda qui. Ipsam minima, animi, earum, libero possimus a pariatur tenetur maiores rem eos molestias nesciunt tempora quae error voluptate na eaque? Dolorem in ratione magnam optio inventore doloremque cumque esse distinctio, tenetur totam libero provident ab explicabo voluptas eaque consequatur. Harum, maxime? Molestiae aut pariatur iste distinctio amet a facere ea optio iure, laboriosam repudiandae veritatis unde nostrum? Dolorum neque fuga recusandae! Magnam architecto, ipsa sapiente atque impedit non temporibus, aperiam sed reiciendis magni ab ipsam quasi, numquam deserunt optio enim eligendi?",
          tipos: ["AP", "AA", "AL", "AG", "AC"],
          responsaveis: {
            id: 1,
            nome: "Johnny Santos",
          },
          colaborador: {
            id: 2,
            foto: "https://cdn.pixabay.com/photo/2020/12/21/19/05/window-5850628_960_720.png",
            nome: "Matheus Eduardo França",
            cargo: {
              id: 3,
              nome: "Estagiário",
            },
            setor: {
              nome: "TI",
            },
          },
          status: "C",
          data_inicio: "2022-02-05",
          hora_inicio: "13:09:14",
          data_final: "2022-02-05",
          hora_final: "13:09:14",
          data_ultima_atualizacao: "2022-02-05 13:09:14",
        },
        {
          id: 13,
          uuid: "sodjd8-sjkdsb-394djd-fif12",
          nome: "Avaliação 02",
          descricao:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam, dolores molestiae blanditiis aut quas officia eaque non assumenda qui. Ipsam minima, animi, earum, libero possimus a pariatur tenetur maiores rem eos molestias nesciunt tempora quae error voluptate na eaque? Dolorem in ratione magnam optio inventore doloremque cumque esse distinctio.",
          tipos: ["AP", "AA", "AL", "AG", "AC"],
          responsaveis: {
            id: 1,
            nome: "Juliana Silva",
          },
          colaborador: {
            id: 2,
            foto: "https://cdn.pixabay.com/photo/2020/12/21/19/05/window-5850628_960_720.png",
            nome: "Matheus Eduardo França",
            cargo: {
              id: 3,
              nome: "Estagiário",
            },
            setor: {
              nome: "TI",
            },
          },
          status: "A",
          data_inicio: "2022-02-05",
          hora_inicio: "13:09:14",
          data_final: "2022-02-05",
          hora_final: "13:09:14",
          data_ultima_atualizacao: "2022-02-05 13:09:14",
        },
        {
          id: 26,
          uuid: "sodjd8-sjkdsb-394djd-fif65",
          nome: "Avaliação 03",
          descricao: "¯_(ツ)_/¯",
          tipos: ["AP", "AA", "AL", "AG", "AC"],
          responsaveis: {
            id: 1,
            nome: "Sandro Souza",
          },
          colaborador: {
            id: 2,
            foto: "https://cdn.pixabay.com/photo/2020/12/21/19/05/window-5850628_960_720.png",
            nome: "Matheus Eduardo França",
            cargo: {
              id: 3,
              nome: "Estagiário",
            },
            setor: {
              nome: "TI",
            },
          },
          status: "E",
          data_inicio: "2022-02-05",
          hora_inicio: "13:09:14",
          data_final: "2022-02-05",
          hora_final: "13:09:14",
          data_ultima_atualizacao: "2022-02-05 13:09:14",
        },
        {
          id: 36,
          uuid: "sodjd8-sjkdsb-394djd-fif09",
          nome: "Avaliação 04",
          descricao:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam, dolores molestiae blanditiis aut quas officia eaque non assumenda qui. Ipsam minima, animi, earum, libero possimus a pariatur tenetur maiores rem eos molestias nesciunt tempora quae error voluptate na eaque? Dolorem in ratione magnam optio inventore doloremque cumque esse distinctio, tenetur totam libero provident ab explicabo voluptas eaque consequatur. Harum, maxime? Molestiae aut pariatur iste distinctio amet a facere ea optio iure, laboriosam repudiandae veritatis unde nostrum? Dolorum neque fuga recusandae! Magnam architecto, ipsa sapiente atque impedit non temporibus, aperiam sed reiciendis magni ab ipsam quasi, numquam deserunt optio enim eligendi?",
          tipos: ["AP", "AA", "AL", "AG", "AC"],
          responsaveis: {
            id: 1,
            nome: "Alberto Girino",
          },
          colaborador: {
            id: 2,
            foto: "https://cdn.pixabay.com/photo/2020/12/21/19/05/window-5850628_960_720.png",
            nome: "Matheus Eduardo França",
            cargo: {
              id: 3,
              nome: "Estagiário",
            },
            setor: {
              nome: "TI",
            },
          },
          status: "E",
          data_inicio: "2022-02-05",
          hora_inicio: "13:09:14",
          data_final: "2022-02-05",
          hora_final: "13:09:14",
          data_ultima_atualizacao: "2022-02-05 13:09:14",
        },
        {
          id: 79,
          uuid: "sodjd8-sjkdsb-394djd-fif43",
          nome: "Avaliação 05",
          descricao: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
          tipos: ["AP", "AA", "AL", "AG", "AC"],
          responsaveis: {
            id: 1,
            nome: "Adolfo Jhennerson",
          },
          colaborador: {
            id: 2,
            foto: "https://cdn.pixabay.com/photo/2020/12/21/19/05/window-5850628_960_720.png",
            nome: "Matheus Eduardo França",
            cargo: {
              id: 3,
              nome: "Estagiário",
            },
            setor: {
              nome: "TI",
            },
          },
          status: "P",
          data_inicio: "2022-02-05",
          hora_inicio: "13:09:14",
          data_final: "2022-02-05",
          hora_final: "13:09:14",
          data_ultima_atualizacao: "2022-02-05 13:09:14",
        },
        {
          id: 85,
          uuid: "sodjd8-sjkdsb-394djd-fif23",
          nome: "Avaliação 06",
          descricao:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam, dolores molestiae blanditiis aut quas officia eaque non assumenda qui. Ipsam minima, animi, earum, libero possimus a pariatur tenetur maiores rem eos molestias nesciunt tempora quae error voluptate na eaque? Dolorem in ratione magnam optio inventore doloremque cumque esse distinctio, tenetur totam libero provident ab explicabo voluptas.",
          tipos: ["AP", "AA", "AL", "AG", "AC"],
          responsaveis: {
            id: 1,
            nome: "Johnny Santos",
          },
          colaborador: {
            id: 2,
            foto: "https://cdn.pixabay.com/photo/2020/12/21/19/05/window-5850628_960_720.png",
            nome: "Matheus Eduardo França",
            cargo: {
              id: 3,
              nome: "Estagiário",
            },
            setor: {
              nome: "TI",
            },
          },
          status: "C",
          data_inicio: "2022-02-05",
          hora_inicio: "13:09:14",
          data_final: "2022-02-05",
          hora_final: "13:09:14",
          data_ultima_atualizacao: "2022-02-05 13:09:14",
        },
      ],
      totalizadores: [12, 16, 5, 98],
    };

    commit("setPainelAvaliacoes", data.avaliacao);
    commit("setDadosTotalizadores", data.totalizadores);
  },
  buscarPainelMinhasAvaliacoes({ commit }) {
    const minhasAvaliacoes = [
      {
        id: 23,
        uuid: "sodjd8-sjkdsb-394djd-fifd8",
        nome: "Avaliação 01",
        descricao:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam, dolores molestiae blanditiis aut quas officia eaque non assumenda qui. Ipsam minima, animi, earum, libero possimus a pariatur tenetur maiores rem eos molestias nesciunt tempora quae error voluptate na eaque? Dolorem in ratione magnam optio inventore doloremque cumque esse distinctio, tenetur totam libero provident ab explicabo voluptas eaque consequatur. Harum, maxime? Molestiae aut pariatur iste distinctio amet a facere ea optio iure, laboriosam repudiandae veritatis unde nostrum? Dolorum neque fuga recusandae! Magnam architecto, ipsa sapiente atque impedit non temporibus, aperiam sed reiciendis magni ab ipsam quasi, numquam deserunt optio enim eligendi?",
        tipos: ["AP", "AA", "AL", "AG", "AC"],
        responsaveis: {
          id: 1,
          nome: "Johnny Santos",
        },
        status: "C",
        data_inicio: "2022-02-05",
        hora_inicio: "13:09:14",
        data_final: "2022-02-05",
        hora_final: "13:09:14",
        data_ultima_atualizacao: "2022-02-05 13:09:14",
      },
      {
        id: 13,
        uuid: "sodjd8-sjkdsb-394djd-fif12",
        nome: "Avaliação 02",
        descricao:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam, dolores molestiae blanditiis aut quas officia eaque non assumenda qui. Ipsam minima, animi, earum, libero possimus a pariatur tenetur maiores rem eos molestias nesciunt tempora quae error voluptate na eaque? Dolorem in ratione magnam optio inventore doloremque cumque esse distinctio.",
        tipos: ["AP", "AA", "AL", "AG", "AC"],
        responsaveis: {
          id: 1,
          nome: "Juliana Silva",
        },
        status: "A",
        data_inicio: "2022-02-05",
        hora_inicio: "13:09:14",
        data_final: "2022-02-05",
        hora_final: "13:09:14",
        data_ultima_atualizacao: "2022-02-05 13:09:14",
      },
      {
        id: 26,
        uuid: "sodjd8-sjkdsb-394djd-fif65",
        nome: "Avaliação 03",
        descricao: "¯_(ツ)_/¯",
        tipos: ["AP", "AA", "AL", "AG", "AC"],
        responsaveis: {
          id: 1,
          nome: "Sandro Souza",
        },
        status: "E",
        data_inicio: "2022-02-05",
        hora_inicio: "13:09:14",
        data_final: "2022-02-05",
        hora_final: "13:09:14",
        data_ultima_atualizacao: "2022-02-05 13:09:14",
      },
      {
        id: 36,
        uuid: "sodjd8-sjkdsb-394djd-fif09",
        nome: "Avaliação 04",
        descricao:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam, dolores molestiae blanditiis aut quas officia eaque non assumenda qui. Ipsam minima, animi, earum, libero possimus a pariatur tenetur maiores rem eos molestias nesciunt tempora quae error voluptate na eaque? Dolorem in ratione magnam optio inventore doloremque cumque esse distinctio, tenetur totam libero provident ab explicabo voluptas eaque consequatur. Harum, maxime? Molestiae aut pariatur iste distinctio amet a facere ea optio iure, laboriosam repudiandae veritatis unde nostrum? Dolorum neque fuga recusandae! Magnam architecto, ipsa sapiente atque impedit non temporibus, aperiam sed reiciendis magni ab ipsam quasi, numquam deserunt optio enim eligendi?",
        tipos: ["AP", "AA", "AL", "AG", "AC"],
        responsaveis: {
          id: 1,
          nome: "Alberto Girino",
        },
        status: "E",
        data_inicio: "2022-02-05",
        hora_inicio: "13:09:14",
        data_final: "2022-02-05",
        hora_final: "13:09:14",
        data_ultima_atualizacao: "2022-02-05 13:09:14",
      },
      {
        id: 79,
        uuid: "sodjd8-sjkdsb-394djd-fif43",
        nome: "Avaliação 05",
        descricao: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
        tipos: ["AP", "AA", "AL", "AG", "AC"],
        responsaveis: {
          id: 1,
          nome: "Adolfo Jhennerson",
        },
        status: "P",
        data_inicio: "2022-02-05",
        hora_inicio: "13:09:14",
        data_final: "2022-02-05",
        hora_final: "13:09:14",
        data_ultima_atualizacao: "2022-02-05 13:09:14",
      },
      {
        id: 85,
        uuid: "sodjd8-sjkdsb-394djd-fif23",
        nome: "Avaliação 06",
        descricao:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam, dolores molestiae blanditiis aut quas officia eaque non assumenda qui. Ipsam minima, animi, earum, libero possimus a pariatur tenetur maiores rem eos molestias nesciunt tempora quae error voluptate na eaque? Dolorem in ratione magnam optio inventore doloremque cumque esse distinctio, tenetur totam libero provident ab explicabo voluptas.",
        tipos: ["AP", "AA", "AL", "AG", "AC"],
        responsaveis: {
          id: 1,
          nome: "Johnny Santos",
        },
        status: "C",
        data_inicio: "2022-02-05",
        hora_inicio: "13:09:14",
        data_final: "2022-02-05",
        hora_final: "13:09:14",
        data_ultima_atualizacao: "2022-02-05 13:09:14",
      },
    ];

    commit("setPainelMinhasAvaliacoes", minhasAvaliacoes);
  },
  buscarVisualizarAvaliacao({ commit }, uuid) {
    console.log("Visualizar avaliação: ", uuid);

    const items = {
      visaoGeral: {
        geral: {
          competencias: [
            {
              id: 1,
              nome: "lorem ipsum sd uddhsdh usdhu shdu isisisisiidhsd",
              valorObtido: 4.0,
              valorMaximo: 10.0,
            },
            {
              id: 2,
              nome: "Competência 00000000002",
              valorObtido: 2.6,
              valorMaximo: 10.0,
            },
            {
              id: 3,
              nome: "Competência 003",
              valorObtido: 1.7,
              valorMaximo: 10.0,
            },
            {
              id: 4,
              nome: "Competência 004",
              valorObtido: 4.9,
              valorMaximo: 10.0,
            },
            {
              id: 5,
              nome: "Competência 004",
              valorObtido: 4.9,
              valorMaximo: 10.0,
            },
            {
              id: 6,
              nome: "Competência 004",
              valorObtido: 4.9,
              valorMaximo: 10.0,
            },
            {
              id: 7,
              nome: "Competência 004",
              valorObtido: 4.9,
              valorMaximo: 10.0,
            },
            {
              id: 8,
              nome: "Competência 004",
              valorObtido: 4.9,
              valorMaximo: 10.0,
            },
          ],
          grafico_categorias: [
            "COM",
            "CRI",
            "INI",
            "VIS",
            "COL",
            "FLE",
            "LID",
            "PLA",
            "FOC",
            "DEC",
          ],
          grafico_series: [
            {
              name: "Autoavaliação",
              data: [80, 50, 30, 40, 100, 20, 80, 50, 30, 40],
            },
            {
              name: "Avaliação de Pares",
              data: [20, 30, 40, 80, 20, 80, 20, 30, 40, 80],
            },
            {
              name: "Avaliação de Gestor",
              data: [44, 76, 78, 13, 43, 10, 44, 76, 78, 13],
            },
            {
              name: "Avaliação de Cliente",
              data: [80, 20, 80, 40, 30, 20, 80, 20, 80, 40],
            },
          ],
        },
        ranking: "1",
        ninebox: "Mantenedor",
        series: [
          {
            name: "series1",
            data: [31, 40, 28, 51, 42, 109, 100, 22, 45, 14, 70, 88],
          },
        ],
      },
      autoavaliacao: {
        geral: {
          competencias: [
            {
              id: 1,
              nome: "lorem ipsum sd uddhsdh usdhu shdu isisisisiidhsd",
              valorObtido: 4.0,
              valorMaximo: 10.0,
            },
            {
              id: 2,
              nome: "Competência 00000000002",
              valorObtido: 2.6,
              valorMaximo: 10.0,
            },
            {
              id: 3,
              nome: "Competência 003",
              valorObtido: 1.7,
              valorMaximo: 10.0,
            },
            {
              id: 4,
              nome: "Competência 004",
              valorObtido: 4.9,
              valorMaximo: 10.0,
            },
            {
              id: 5,
              nome: "Competência 004",
              valorObtido: 4.9,
              valorMaximo: 10.0,
            },
            {
              id: 6,
              nome: "Competência 004",
              valorObtido: 4.9,
              valorMaximo: 10.0,
            },
            {
              id: 7,
              nome: "Competência 004",
              valorObtido: 4.9,
              valorMaximo: 10.0,
            },
            {
              id: 8,
              nome: "Competência 004",
              valorObtido: 4.9,
              valorMaximo: 10.0,
            },
          ],
          grafico_categorias: [
            "COM",
            "CRI",
            "INI",
            "VIS",
            "COL",
            "FLE",
            "LID",
            "PLA",
            "FOC",
            "DEC",
          ],
          grafico_series: [
            {
              name: "Autoavaliação",
              data: [80, 50, 30, 40, 100, 20, 80, 50, 30, 40],
            },
            {
              name: "Avaliação de Pares",
              data: [20, 30, 40, 80, 20, 80, 20, 30, 40, 80],
            },
            {
              name: "Avaliação de Gestor",
              data: [44, 76, 78, 13, 43, 10, 44, 76, 78, 13],
            },
            {
              name: "Avaliação de Cliente",
              data: [80, 20, 80, 40, 30, 20, 80, 20, 80, 40],
            },
          ],
        },
        competencia: [
          {
            id: 1,
            nome: "Iniciativa",
            perguntas: [
              {
                id: 1,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3,
                valorMaximo: 5,
              },
              {
                id: 2,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 2.4,
                valorMaximo: 5,
              },
              {
                id: 3,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3.8,
                valorMaximo: 5,
              },
              {
                id: 4,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 1.7,
                valorMaximo: 5,
              },
              {
                id: 5,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3,
                valorMaximo: 5,
              },
              {
                id: 6,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 4.9,
                valorMaximo: 5,
              },
              {
                id: 7,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 4.9,
                valorMaximo: 5,
              },
              {
                id: 8,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 4.9,
                valorMaximo: 5,
              },
              {
                id: 9,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 4.9,
                valorMaximo: 5,
              },
              {
                id: 10,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 4.9,
                valorMaximo: 5,
              },
              {
                id: 11,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 4.9,
                valorMaximo: 5,
              },
              {
                id: 12,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 4.9,
                valorMaximo: 5,
              },
              {
                id: 13,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 4.9,
                valorMaximo: 5,
              },
              {
                id: 14,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 4.9,
                valorMaximo: 5,
              },
              {
                id: 15,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 4.9,
                valorMaximo: 5,
              },
              {
                id: 16,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 4.9,
                valorMaximo: 5,
              },
              {
                id: 17,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 4.9,
                valorMaximo: 5,
              },
              {
                id: 18,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 4.9,
                valorMaximo: 5,
              },
            ],
          },
          {
            id: 2,
            nome: "Trabalho em equipe",
            perguntas: [
              {
                id: 1,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3,
                valorMaximo: 5,
              },
              {
                id: 2,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 2.4,
                valorMaximo: 5,
              },
              {
                id: 3,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3.8,
                valorMaximo: 5,
              },
              {
                id: 4,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 1.7,
                valorMaximo: 5,
              },
              {
                id: 5,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3,
                valorMaximo: 5,
              },
              {
                id: 6,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 4.9,
                valorMaximo: 5,
              },
            ],
          },
          {
            id: 3,
            nome: "Criatividade e inovação",
            perguntas: [
              {
                id: 1,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3,
                valorMaximo: 5,
              },
              {
                id: 2,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 2.4,
                valorMaximo: 5,
              },
              {
                id: 3,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3.8,
                valorMaximo: 5,
              },
              {
                id: 4,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 1.7,
                valorMaximo: 5,
              },
              {
                id: 5,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3,
                valorMaximo: 5,
              },
              {
                id: 6,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 4.9,
                valorMaximo: 5,
              },
            ],
          },
        ],
      },
      avaliacaoPares: {
        geral: {
          competencias: [
            {
              id: 1,
              nome: "lorem ipsum sd uddhsdh usdhu shdu isisisisiidhsd",
              valorObtido: 4.0,
              valorMaximo: 10.0,
            },
            {
              id: 2,
              nome: "Competência 00000000002",
              valorObtido: 2.6,
              valorMaximo: 10.0,
            },
            {
              id: 3,
              nome: "Competência 003",
              valorObtido: 1.7,
              valorMaximo: 10.0,
            },
            {
              id: 4,
              nome: "Competência 004",
              valorObtido: 4.9,
              valorMaximo: 10.0,
            },
            {
              id: 5,
              nome: "Competência 004",
              valorObtido: 4.9,
              valorMaximo: 10.0,
            },
            {
              id: 6,
              nome: "Competência 004",
              valorObtido: 4.9,
              valorMaximo: 10.0,
            },
            {
              id: 7,
              nome: "Competência 004",
              valorObtido: 4.9,
              valorMaximo: 10.0,
            },
            {
              id: 8,
              nome: "Competência 004",
              valorObtido: 4.9,
              valorMaximo: 10.0,
            },
          ],
          grafico_categorias: [
            "COM",
            "CRI",
            "INI",
            "VIS",
            "COL",
            "FLE",
            "LID",
            "PLA",
            "FOC",
            "DEC",
          ],
          grafico_series: [
            {
              name: "Autoavaliação",
              data: [80, 50, 30, 40, 100, 20, 80, 50, 30, 40],
            },
            {
              name: "Avaliação de Pares",
              data: [20, 30, 40, 80, 20, 80, 20, 30, 40, 80],
            },
            {
              name: "Avaliação de Gestor",
              data: [44, 76, 78, 13, 43, 10, 44, 76, 78, 13],
            },
            {
              name: "Avaliação de Cliente",
              data: [80, 20, 80, 40, 30, 20, 80, 20, 80, 40],
            },
          ],
        },
        competencia: [
          {
            id: 1,
            nome: "Iniciativa",
            perguntas: [
              {
                id: 1,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3,
                valorMaximo: 5,
              },
              {
                id: 2,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 2.4,
                valorMaximo: 5,
              },
              {
                id: 3,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3.8,
                valorMaximo: 5,
              },
              {
                id: 4,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 1.7,
                valorMaximo: 5,
              },
              {
                id: 5,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3,
                valorMaximo: 5,
              },
              {
                id: 6,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 4.9,
                valorMaximo: 5,
              },
            ],
          },
          {
            id: 2,
            nome: "Trabalho em equipe",
            perguntas: [
              {
                id: 1,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3,
                valorMaximo: 5,
              },
              {
                id: 2,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 2.4,
                valorMaximo: 5,
              },
              {
                id: 3,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3.8,
                valorMaximo: 5,
              },
              {
                id: 4,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 1.7,
                valorMaximo: 5,
              },
              {
                id: 5,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3,
                valorMaximo: 5,
              },
              {
                id: 6,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 4.9,
                valorMaximo: 5,
              },
            ],
          },
          {
            id: 3,
            nome: "Criatividade e inovação",
            perguntas: [
              {
                id: 1,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3,
                valorMaximo: 5,
              },
              {
                id: 2,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 2.4,
                valorMaximo: 5,
              },
              {
                id: 3,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3.8,
                valorMaximo: 5,
              },
              {
                id: 4,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 1.7,
                valorMaximo: 5,
              },
              {
                id: 5,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3,
                valorMaximo: 5,
              },
              {
                id: 6,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 4.9,
                valorMaximo: 5,
              },
            ],
          },
        ],
      },
      avaliacaoLider: {
        geral: {
          competencias: [
            {
              id: 1,
              nome: "lorem ipsum sd uddhsdh usdhu shdu isisisisiidhsd",
              valorObtido: 4.0,
              valorMaximo: 10.0,
            },
            {
              id: 2,
              nome: "Competência 00000000002",
              valorObtido: 2.6,
              valorMaximo: 10.0,
            },
            {
              id: 3,
              nome: "Competência 003",
              valorObtido: 1.7,
              valorMaximo: 10.0,
            },
            {
              id: 4,
              nome: "Competência 004",
              valorObtido: 4.9,
              valorMaximo: 10.0,
            },
            {
              id: 5,
              nome: "Competência 004",
              valorObtido: 4.9,
              valorMaximo: 10.0,
            },
            {
              id: 6,
              nome: "Competência 004",
              valorObtido: 4.9,
              valorMaximo: 10.0,
            },
            {
              id: 7,
              nome: "Competência 004",
              valorObtido: 4.9,
              valorMaximo: 10.0,
            },
            {
              id: 8,
              nome: "Competência 004",
              valorObtido: 4.9,
              valorMaximo: 10.0,
            },
          ],
          grafico_categorias: [
            "COM",
            "CRI",
            "INI",
            "VIS",
            "COL",
            "FLE",
            "LID",
            "PLA",
            "FOC",
            "DEC",
          ],
          grafico_series: [
            {
              name: "Autoavaliação",
              data: [80, 50, 30, 40, 100, 20, 80, 50, 30, 40],
            },
            {
              name: "Avaliação de Pares",
              data: [20, 30, 40, 80, 20, 80, 20, 30, 40, 80],
            },
            {
              name: "Avaliação de Gestor",
              data: [44, 76, 78, 13, 43, 10, 44, 76, 78, 13],
            },
            {
              name: "Avaliação de Cliente",
              data: [80, 20, 80, 40, 30, 20, 80, 20, 80, 40],
            },
          ],
        },
        competencia: [
          {
            id: 1,
            nome: "Iniciativa",
            perguntas: [
              {
                id: 1,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3,
                valorMaximo: 5,
              },
              {
                id: 2,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 2.4,
                valorMaximo: 5,
              },
              {
                id: 3,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3.8,
                valorMaximo: 5,
              },
              {
                id: 4,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 1.7,
                valorMaximo: 5,
              },
              {
                id: 5,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3,
                valorMaximo: 5,
              },
              {
                id: 6,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 4.9,
                valorMaximo: 5,
              },
            ],
          },
          {
            id: 2,
            nome: "Trabalho em equipe",
            perguntas: [
              {
                id: 1,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3,
                valorMaximo: 5,
              },
              {
                id: 2,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 2.4,
                valorMaximo: 5,
              },
              {
                id: 3,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3.8,
                valorMaximo: 5,
              },
              {
                id: 4,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 1.7,
                valorMaximo: 5,
              },
              {
                id: 5,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3,
                valorMaximo: 5,
              },
              {
                id: 6,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 4.9,
                valorMaximo: 5,
              },
            ],
          },
          {
            id: 3,
            nome: "Criatividade e inovação",
            perguntas: [
              {
                id: 1,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3,
                valorMaximo: 5,
              },
              {
                id: 2,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 2.4,
                valorMaximo: 5,
              },
              {
                id: 3,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3.8,
                valorMaximo: 5,
              },
              {
                id: 4,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 1.7,
                valorMaximo: 5,
              },
              {
                id: 5,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3,
                valorMaximo: 5,
              },
              {
                id: 6,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 4.9,
                valorMaximo: 5,
              },
            ],
          },
        ],
      },
      avaliacaoGestor: {
        geral: {
          competencias: [
            {
              id: 1,
              nome: "lorem ipsum sd uddhsdh usdhu shdu isisisisiidhsd",
              valorObtido: 4.0,
              valorMaximo: 10.0,
            },
            {
              id: 2,
              nome: "Competência 00000000002",
              valorObtido: 2.6,
              valorMaximo: 10.0,
            },
            {
              id: 3,
              nome: "Competência 003",
              valorObtido: 1.7,
              valorMaximo: 10.0,
            },
            {
              id: 4,
              nome: "Competência 004",
              valorObtido: 4.9,
              valorMaximo: 10.0,
            },
            {
              id: 5,
              nome: "Competência 004",
              valorObtido: 4.9,
              valorMaximo: 10.0,
            },
            {
              id: 6,
              nome: "Competência 004",
              valorObtido: 4.9,
              valorMaximo: 10.0,
            },
            {
              id: 7,
              nome: "Competência 004",
              valorObtido: 4.9,
              valorMaximo: 10.0,
            },
            {
              id: 8,
              nome: "Competência 004",
              valorObtido: 4.9,
              valorMaximo: 10.0,
            },
          ],
          grafico_categorias: [
            "COM",
            "CRI",
            "INI",
            "VIS",
            "COL",
            "FLE",
            "LID",
            "PLA",
            "FOC",
            "DEC",
          ],
          grafico_series: [
            {
              name: "Autoavaliação",
              data: [80, 50, 30, 40, 100, 20, 80, 50, 30, 40],
            },
            {
              name: "Avaliação de Pares",
              data: [20, 30, 40, 80, 20, 80, 20, 30, 40, 80],
            },
            {
              name: "Avaliação de Gestor",
              data: [44, 76, 78, 13, 43, 10, 44, 76, 78, 13],
            },
            {
              name: "Avaliação de Cliente",
              data: [80, 20, 80, 40, 30, 20, 80, 20, 80, 40],
            },
          ],
        },
        competencia: [
          {
            id: 1,
            nome: "Iniciativa",
            perguntas: [
              {
                id: 1,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3,
                valorMaximo: 5,
              },
              {
                id: 2,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 2.4,
                valorMaximo: 5,
              },
              {
                id: 3,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3.8,
                valorMaximo: 5,
              },
              {
                id: 4,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 1.7,
                valorMaximo: 5,
              },
              {
                id: 5,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3,
                valorMaximo: 5,
              },
              {
                id: 6,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 4.9,
                valorMaximo: 5,
              },
            ],
          },
          {
            id: 2,
            nome: "Trabalho em equipe",
            perguntas: [
              {
                id: 1,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3,
                valorMaximo: 5,
              },
              {
                id: 2,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 2.4,
                valorMaximo: 5,
              },
              {
                id: 3,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3.8,
                valorMaximo: 5,
              },
              {
                id: 4,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 1.7,
                valorMaximo: 5,
              },
              {
                id: 5,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3,
                valorMaximo: 5,
              },
              {
                id: 6,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 4.9,
                valorMaximo: 5,
              },
            ],
          },
          {
            id: 3,
            nome: "Criatividade e inovação",
            perguntas: [
              {
                id: 1,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3,
                valorMaximo: 5,
              },
              {
                id: 2,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 2.4,
                valorMaximo: 5,
              },
              {
                id: 3,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3.8,
                valorMaximo: 5,
              },
              {
                id: 4,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 1.7,
                valorMaximo: 5,
              },
              {
                id: 5,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 3,
                valorMaximo: 5,
              },
              {
                id: 6,
                nome: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                valorObtido: 4.9,
                valorMaximo: 5,
              },
            ],
          },
        ],
      },
    };

    commit("buscarVisualizarAvaliacao", items);
  },
  buscarDadosPainelDeBordo({ commit, dispatch }) {
    console.log("Buscando por dados do painel de bordo! ");

    return new Promise((resolve, reject) => {
      const items = {
        media: {
          valorObtido: 40,
          valorMaximo: 100,
        },
        ranking: [
          {
            id: 1,
            nome: "Matheus França",
            foto: "https://cdn.pixabay.com/photo/2018/11/13/22/01/instagram-3814082_960_720.png",
            cargo: "Estagiário full-stack",
            series: [50, 62, 61, 81, 67],
            tipos: ["AA", "AP", "AL", "AG", "AC"],
          },
          {
            id: 2,
            nome: "Zé Ronaldo",
            foto: "https://cdn.pixabay.com/photo/2018/11/13/22/01/instagram-3814082_960_720.png",
            cargo: "Estagiário full-stack",
            series: [50, 62, 61],
            tipos: ["AA", "AP", "AL"],
          },
          {
            id: 3,
            nome: "Maria João",
            foto: "https://cdn.pixabay.com/photo/2018/11/13/22/01/instagram-3814082_960_720.png",
            cargo: "Estagiário full-stack",
            series: [50, 62, 61],
            tipos: ["AA", "AG", "AC"],
          },
        ],
        avaliacaoGeral: [
          {
            name: "Autoavaliação",
            data: [31, 40, 28, 51, 42, 109, 100, 87, 78, 93, 55, 100],
          },
          {
            name: "Avaliação de Pares",
            data: [11, 32, 45, 32, 34, 52, 41, 32, 55, 78, 54, 67],
          },
          {
            name: "Avaliação de Gestor",
            data: [11, 32, 45, 32, 34, 52, 41, 32, 55, 78, 54, 67],
          },
          {
            name: "Avaliação de Líder",
            data: [11, 32, 45, 32, 34, 52, 41, 32, 55, 78, 54, 67],
          },
          {
            name: "Avaliação de Cliente",
            data: [11, 32, 45, 32, 34, 52, 41, 32, 55, 78, 54, 67],
          },
        ],
        competenciasGeral: [
          {
            data: [80, 50, 30, 40, 100, 20, 45, 67, 87, 97, 99, 100],
          },
        ],
      };
      dispatch("buscarCompetencias", 1);

      commit("setDataPainelDeBordo", items);

      resolve(items);
    });
  },
  buscarCompetencias({ commit }, page) {
    const item = {
      1: [
        {
          nome: "Competência 01",
          series: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2],
        },
        {
          nome: "Competência 02",
          series: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2],
        },
        {
          nome: "Competência 03",
          series: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2],
        },
      ],
      2: [
        {
          nome: "Competência 01",
          series: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2],
        },
        {
          nome: "Competência 02",
          series: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2],
        },
        {
          nome: "Competência 03",
          series: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2],
        },
      ],
      3: [
        {
          nome: "Competência 01",
          series: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2],
        },
        {
          nome: "Competência 02",
          series: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2],
        },
        {
          nome: "Competência 03",
          series: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2],
        },
      ],
    };

    commit("setDataCompetencias", item[page]);
  },

  // Comportamentos

  initializeComportamento({ commit }) {
    return new Promise(function (resolve, reject) {
      commit("setLoading");
      restApi
        .get("/comportamentos/initialize")
        .then((response) => {
          if (response.data) {
            commit("setComportamentos", response.data.data);
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        })
        .finally(() => commit("setLoading"));
    });
  },
  saveComportamento({ commit }, data) {
    return new Promise(function (resolve, reject) {
      restApi
        .post(data.url, data)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  deleteComportamento({ commit }, uuid) {
    return new Promise(function (resolve, reject) {
      restApi
        .delete(`/comportamentos/${uuid}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  // Competencias

  findByColumnsCompetencias({ commit }, data) {
    return new Promise(function (resolve, reject) {
      restApi
        .post("/competencias/find-by-columns", data)
        .then((response) => {
          if (response.data) {
            commit("setCompetencias", response.data.data);
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  // Modelos
  buscarModelosAvd({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("setLoading");
      restApi
        .get("/avd-modelos/initialize")
        .then((response) => {
          if (response.data) {
            commit("setModelos", response.data.data.modelos);
            commit("setResponsaveis", response.data.data.responsaveis);
            commit("setLoading");
            resolve(response.data);
          } else {
            commit("setLoading");
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          commit("setLoading");
          reject(error);
        });
    }).catch((error) => {
      commit("setLoading");
      reject(error);
    });
  },
  salvarModelo({ commit }, dados) {
    return new Promise((resolve, reject) => {
      restApi
        .post(`${dados.url}`, dados)
        .then((response) => {
          if (response.data != "NAO_AUTORIZADO") {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  deleteModelo({ commit }, uuid) {
    console.log(uuid);
    return new Promise((resolve, reject) => {
      restApi
        .delete(`/avd-modelos/${uuid}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  buscarAvdComportamental({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      commit("setLoading");
      restApi
        .post("/avd-modelos/buscar-avd-comportamental", { uuid })
        .then((response) => {
          if (response.data) {
            commit(
              "setAvdComportamental",
              response.data.data.AvdComportamental
            );
            commit(
              "setCompetenciasComportamentais",
              response.data.data.competencias
            );
            commit(
              "setComportamentosComportamentais",
              response.data.data.comportamentos
            );
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("setLoading");
        });
    }).catch((error) => {
      reject(error);
    });
  },
  resetAvdComportamentalData({ commit }) {
    commit("resetAvdComportamentalData");
  },
  buscarAvdTecnica({ commit }, uuid) {
    commit("setLoading");
    return new Promise((resolve, reject) => {
      restApi
        .post("/avd-modelos/buscar-avd-tecnica", { uuid })
        .then((response) => {
          if (response.data) {
            commit("setAvdTecnica", response.data.data.AvdTecnica);
            commit("setCompetenciasTecnicas", response.data.data.competencias);
            commit(
              "setComportamentosTecnicas",
              response.data.data.comportamentos
            );
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("setLoading");
        });
    }).catch((error) => {
      reject(error);
    });
  },
  resetAvdTecnicaData({ commit }) {
    commit("resetAvdTecnicaData");
  },
  salvarConfiguracoesAvdModelo({ commit }, data) {
    commit("setLoading");
    return new Promise((resolve, reject) => {
      restApi
        .post("/avd-modelos/salvar-configuracoes-avd-modelo", data)
        .then((response) => { })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("setLoading");
        });
    }).catch((error) => {
      reject(error);
    });
  },
  atualizarTabAvaliacaoForm({ commit, state }, value) {
    state.tabsForm.map((item) => {
      let tab = value.find((i) => item.scopeValidation === i);

      if (item.scopeValidation === "COM" || item.scopeValidation === "CONCOM" || item.scopeValidation === "TEC") {
        if (tab !== undefined && item.scopeValidation === tab) {
          item.disabled = false;
        } else {
          item.disabled = true;
        }
      }
    });
  },

  // Gerais
  setLoading({ commit }) {
    commit("setLoading");
  },
  setAvaliacao({ commit }, data) {
    commit('setAvaliacao', data)
  },

};
