import restApi from "@axios";

export default {
  salvarOpcoes({ commit }, dados) {
    return new Promise((resolve, reject) => {
      restApi
        .post(`${dados["url"]}`, dados)
        .then((response) => {
          if (response.data) {
            commit("SALVAR_OPCOES", response.data.data);
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((err) => {
          console.error(err);
          reject("NAO_AUTORIZADO");
        })
        .finally(() => {});
    }).catch((error) => {
      reject(error);
    });
  },
  save({ commit }, dados) {
    return new Promise((resolve, reject) => {
      restApi
        .post(`${dados.opcaoItem.url}`, dados.opcaoItem)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((err) => {
          console.error(err);
          reject("NAO_AUTORIZADO");
        });
    });
  },
  remove({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      restApi
        .delete(`/opcoes/${uuid}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    });
  },
};
