import restApi from "@axios";

export default {
  buscarGrupos({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING");
      restApi
        .get("/pco-grande-grupo/initialize")
        .then((response) => {
          commit("SET_LOADING");
          if (response.data) {
            commit("setGrupos", response.data.data.grupos);
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => reject(error));
  },
  buscarGrupoSelecionado({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      restApi
        .post("/pco-grande-grupo/buscar-grupo-selecionado", {
          uuid: uuid,
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  salvarGrupo({ commit }, dados) {
    return new Promise((resolve, reject) => {
      restApi
        .post(dados.url, dados)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((err) => {
          reject(err);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  deletarGrupo({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      restApi
        .delete(`/pco-grande-grupo/${uuid}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
            return response.data;
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((err) => {
          reject(err);
          console.log(err);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  getDataByColumn({ commit }, data) {
    return new Promise(function (resolve, reject) {
      restApi
        .post("/pco-grande-grupo/get-data-by-column", data)
        .then((response) => {
          if (response.data) {
            commit("setGrupos", response.data.data);
            resolve(response.data.data);
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
};
