<template>
  <layout-blank>
    <v-card
      class="mb-6"
      elevation="3"
      rounded="0"
      style="background-color: white !important"
    >
      <v-row>
        <v-col class="pa-0" cols="3" sm="3" md="2" lg="1" xl="1">
          <v-btn
            color="#5c6da7"
            depressed
            min-width="30%"
            width="50% !important"
            height="100% !important"
            tile
            @click="$router.go(-1)"
            ><v-icon x-large color="white">mdi-chevron-left </v-icon>
          </v-btn>
        </v-col>
        <v-col class="" cols="9" sm="9" md="10" lg="11" xl="11">
          <div class="d-flex flex-column align-start">
            <h1>{{ resolvedNameModule(item.name) }}</h1>
            <v-breadcrumbs
              class="my-1 ml-2 pa-0"
              divider="/"
              :items="item.breadcrumbs"
            ></v-breadcrumbs>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <v-main>
      <v-container fluid style="width: 95% !important">
        <slot></slot>
      </v-container>
    </v-main>
    <SnackBar />
  </layout-blank>
</template>

<script>
import LayoutBlank from "@/@core/layouts/variants/blank/LayoutBlank.vue";
import SnackBar from "@/components/SnackBar.vue";

export default {
  components: {
    LayoutBlank,
    SnackBar,
  },
  methods: {
    resolvedNameModule(value) {
      if (!value) return "";
      const nameArray = value;
      return nameArray.charAt(0).toUpperCase() + nameArray.slice(1);
    },
  },
  computed: {
    item: {
      get() {
        return this.$store.getters["empresas/getDatasLayoutModulos"];
      },
    },
  },
};
</script>

<style></style>
