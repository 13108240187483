export default {
  getEscalas: (state) => {
    return state.escalas;
  },
  getLoading: (state) => {
    return state.loading;
  },
  getEscala: (state) => {
    return state.escala;
  },
};
