<template>
  <v-app id="inspire">
    <v-app-bar app color="primary">
      <v-toolbar-title style="color: white; text-decoration: none !important">{{
        title
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <v-container>
        <v-row v-if="!dialog">
          <v-col cols="12">
            <v-card>
              <v-card-title>Formulário de cadastro empresa</v-card-title>
              <v-card-text>
                <v-tabs
                  show-arrows
                  v-model="abaSelecionada"
                  :grow="true"
                  class="d-md-block"
                >
                  <v-tab
                    class="d-sm-block"
                    v-for="(tab, index) in tabsFormularioEmpresa"
                    :key="`tab-formulario-empresa-${index}`"
                    :disabled="tab.disabled"
                    :href="`#tab-${index}`"
                    style="color: #5c6da7; font-weight: 400"
                  >
                    {{ tab.title }}
                  </v-tab>
                  <v-tab-item
                    v-for="(tab, index) in tabsFormularioEmpresa"
                    :key="`tabs-items-formulario-empresa-${index}`"
                    :value="`tab-${index}`"
                    style="padding: 20px"
                    :eager="true"
                    transition="fade-transition"
                    reverse-transition="fade-transition"
                  >
                    <v-card
                      flat
                      tile
                      style="background-color: white !important"
                    >
                      <component :is="tab.component"></component>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="cadastroConcluido">
          <v-col cols="12">
            <v-alert dense type="info">
              <strong>Cadastro</strong> realializado aguarde o contato do setor
              financeiro.
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <ModalCnpj />
  </v-app>
</template>

<script>
export default {
  name: "Formulario",
  data() {
    return {
      title: this.$vuetify.breakpoint.mobile
        ? "Legiio"
        : "Legiio - Gestão Estratégica de Pessoas",
      pesquisa: {
        uuid: "",
      },
      abaSelecionada: null,
      cadastroConcluido: false,
    };
  },
  components: {
    ModalCnpj: () => import("./../../components/ModalCnpj.vue"),
  },
  methods: {
  },
  computed: {
    tabsFormularioEmpresa() {
      return this.$store.getters["empresas/getTabsFormularioEmpresa"];
    },
    dialog: {
      get() {
        return this.$store.getters["empresas/getDialogModalCnpj"];
      },
      set(value) {
        this.$store.dispatch("empresas/setDialogModalCnpj", value);
      },
    },
    empresa: {
      get() {
        return this.$store.getters["empresas/getEmpresaUsuarios"];
      },
      set(value) {
        this.$store.dispatch("empresas/setEmpresaUsuarios", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .tabs {
    width: 100%;
    height: auto;
    position: relative;

    .historyButtons {
      width: 57px;
      height: auto;
      position: absolute;
      top: 65px;
      right: -57px;
      z-index: 1;

      .v-btn {
        width: 100%;
        min-width: initial;
        height: 42px;
        border-radius: 0 2px 2px 0 !important;
      }

      .v-btn:not(:last-of-type) {
        margin-bottom: 7px;
      }
    }
  }

  .v-tab {
    border-radius: 5px 5px 0 0 !important;
    background-color: #f2f2f2 !important;
    margin-right: 4px !important;
    margin-left: 4px !important;
    font-size: 17px !important;
    font-weight: 400;
    text-transform: none !important;
    height: 40px !important;
    border: 1px solid #dcdcdc;
    line-height: 40px;
  }
  .v-tab:first-of-type {
    margin-left: 0 !important;
  }
  .v-tab:last-of-type {
    margin-right: 0 !important;
  }

  .v-tab--active {
    height: 50px !important;
    background: #fff !important;
    border-bottom: none;
    font-weight: 500;
    line-height: 50px;
    transition: height ease-in-out 0.1s !important;
  }
  .theme--light.v-tabs .v-tab--active:hover::before,
  .theme--light.v-tabs .v-tab--active::before {
    opacity: 0 !important;
  }
  .v-card {
    background-color: transparent !important;
  }

  .theme--light.v-tabs > .v-tabs-bar {
    background-color: transparent !important;
  }
  .v-tabs-items {
    box-shadow: 0 -1px 0 #dcdcdc;
    border: 1px solid #dcdcdc;
    border-top: none;
    border-bottom: none;
  }
  .v-tabs-slider-wrapper {
    caret-color: transparent !important;
    color: transparent !important;
  }
  .v-slide-group__wrapper {
    z-index: 1;
  }
  .v-slide-group__content {
    text-transform: uppercase;
    align-items: flex-end !important;
  }
  .v-slide-group__next,
  .v-slide-group__prev {
    min-width: 30px;
  }
  .v-tabs-bar {
    height: 65px !important;
  }
  .subheader-beneficio-simulador {
    font-weight: 200;
  }

}
</style>