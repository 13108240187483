
export default {
    getCandidato(state) {
        return state.candidato
    },

    getModal(state) {
        return state.modal
    },
    getGenericCards(state) {
        return state.genericCards
    },
    getVagas(state) {
        return state.vagas
    },
    getProcessos(state) {
        return state.processos
    },
    getOportunidades(state) {
        return state.oportunidades
    },

    getPessoais(state) {
        return state.pessoais
    },
    getProfissionais(state) {
        return state.profissionais
    }
}