const Avaliacoes = () => import("../views/Avaliacoes.vue");
const Avaliacao = () => import("../views/Avaliacao.vue");
const AvaliacaoTecnica = () => import("../views/AvaliacaoTecnica.vue");

import { validateSubdomain } from '../../router/helper.js'

export default [
  {
    /**
     * @param uuid - uuid do colaborador
     * @param battery_uuid - uuid da bateria.
    */
    path: "/avd/avaliacoes/:uuid/:battery_uuid",
    name: "avd-avaliacoes-ext",
    component: Avaliacoes,
    meta: {
      layout: "blank",
      requiresAuth: false,
      resource: "Public",
    },
    beforeEnter: (to, from, next) => {
      if (validateSubdomain() == false || !to.params.uuid || !to.params.battery_uuid) return next({ name: 'error-404' })

      next()
    }
  },
  {
    path: "/avd/avaliacao/:type_form/:uuid_form/:uuid_collaborator/:uuid_collaborator_avaliable?",
    name: "avd-avaliacao-ext",
    component: Avaliacao,
    meta: {
      layout: "blank",
      requiresAuth: false,
      resource: "Public",
    },
    beforeEnter: (to, from, next) => {

      if (validateSubdomain() == false || !to.params.type_form && !to.params.uuid_form && !to.params.uuid_collaborator) return next({ name: 'error-404' })

      next()
    }
  },
  {
    path: "/avd/avaliacao-tecnica/:uuid/:battery_uuid/:meta_uuid",
    name: "avd-avaliacao-tecnica-ext",
    component: AvaliacaoTecnica,
    meta: {
      layout: "blank",
      requiresAuth: false,
      resource: "Public",
    },
    beforeEnter: (to, from, next) => {

      if (validateSubdomain() == false || !to.params.uuid || !to.params.battery_uuid || !to.params.meta_uuid) return next({ name: 'error-404' })

      next()
    }
  }
];
