import restApi from '@axios'

function processValues(values = Array, digits = Number, key = String) {
  const newArray = []

  values.forEach(function (el) {
    const valueConverted = Number(el[key].toFixed(digits).split('.').join(''))
    el[key] = valueConverted

    newArray.push(el)
  })

  return newArray
}

function processColaboradores(values = Array, digits = Number, key = String, periodMonths = Number) {

  const newArray = [];

  values.forEach(function (el) {
    const newEl = el;

    newEl.meta = processValues(newEl.meta, digits, key)

    // Inclui o valor do desdobramento em casa meta para ser renderizado na tabela.
    let count = 1;
    while (count <= periodMonths) {

      newEl.meta[count - 1].value = `desdobramento-${count}`

      count++
    }

    newArray.push(newEl)
  })

  return newArray;
}

function processGestores(arrayGestores = Array, arrayColaboradores = Array) {
  if (!arrayColaboradores || arrayColaboradores.length < 1) return [];

  const arrayIdGestores = [];

  arrayColaboradores.forEach(el => {
    if (el.id_gestor && arrayIdGestores.includes(el.id_gestor) == false) arrayIdGestores.push(el.id_gestor);
  })
  
  return arrayGestores.filter(el => {
    if (arrayIdGestores.includes(el.id)) return el
  })

}

export default {
  setConfiguracoes({ commit }, data) {
    commit('setConfiguracoes', data)
  },
  findByColumns({ commit }, data) {
    return new Promise(function (resolve, reject) {
      restApi.post('/avd/configuracao/find-by-columns', data).then(response => {
        if (response.data) {
          commit('setConfiguracoes', response.data.data)
          resolve(response.data.data)
        }

        reject('NAO_AUTORIZADO')
      }).catch(error => reject(error))
    })
  },
  findByUuid({ commit, rootState, rootGetters }, uuid = String) {
    return new Promise(function (resolve, reject) {
      restApi.get(`/avd/configuracao/find-by-uuid/${uuid}`).then(response => {
        if (response.data) {
          const configuracao = Object.assign({}, response.data.data.configuracao)

          configuracao.range = [response.data.data.configuracao.range_min, response.data.data.configuracao.range_max]
          configuracao.unidade = configuracao.tipo_unidade_medida
          configuracao.perspectiva_bsc = configuracao.bsc

          delete configuracao.range_min
          delete configuracao.range_max
          delete configuracao.tipo_unidade_medida
          delete configuracao.bsc

          commit('setConfiguracao', configuracao);
          commit('setColaboradoresConfigMetasIndividuais', processColaboradores(response.data.data.colaboradores, 2, 'objetivo', rootState.avdTecnicas.mesesPeriodicidades));
          commit('setGestoresConfigMetasIndividuais', processGestores(rootGetters["avdColaboradores/getColaboradores"], response.data.data.colaboradores));
          commit('setDistribuicaoValores', processValues(response.data.data.distribuicao_valores.distribuicoes, 2, 'value'));
          resolve(response.data.data)
        }

        reject('NAO_AUTORIZADO')
      }).catch(error => reject(error))
    })
  },
  save({ commit }, data) {
    return new Promise(function (resolve, reject) {
      restApi.post(data.url, data.data).then(response => {
        if (response.data) {
          resolve(response.data.data)
        }

        reject('NAO_AUTORIZADO')
      }).catch(error => reject(error))
    })
  },
  remove({ commit }, uuid) {
    return new Promise(function (resolve, reject) {
      restApi.delete(`/avd/configuracao/${uuid}`).then(response => {
        if (response.data) {
          resolve(response.data.data)
        }

        reject('NAO_AUTORIZADO')
      }).catch(error => reject(error))
    })
  },
  resetDatas({ commit }) {
    const configuracao = {
      unidade: null,
      melhor_se: null,
      verbo: null,
      complemento: null,
      objetivo: null,
      tipo_distribuicao: null,
      tipo_tecnica: null,
      range: [0, 1],
      detalhamento: '',
      fonte_apuracao: '',
      perspectiva_bsc: 'N'
    };
    const configMetasIndividuais = {
      gestoresSelected: [],
      colaboradoresSelected: [],
      aplicaTodos: false,
    }

    commit('setConfiguracao', configuracao)
    commit('setHabilitarDistribuicaoValores', false)
    commit('setTotalApurado', 0)
    commit('setColaboradoresConfigMetasIndividuais', [])
    commit('setConfigMetasIndividuais', configMetasIndividuais)
  },

  setTotalApurado({ commit }, data) {
    commit('setTotalApurado', data)
  },
  setDistribuicaoValores({ commit }, data) {
    commit("setDistribuicaoValores", data);
  },
  setHabilitarDistribuicaoValores({ commit }, data) {
    commit("setHabilitarDistribuicaoValores", data);
  },
  setConfigMetasIndividuais({ commit }, data) {
    commit('setConfigMetasIndividuais', data)
  },
  calculaDadosDistribuicaoDeValores({ commit, state, dispatch, rootGetters }) {
    let avaliacao = rootGetters["avd/getAvaliacao"];
    let arrayDistribuicaoValores = [];
    if (avaliacao.total_meses && avaliacao.total_meses > 0) {
      // Calcula valores de distribuição
      for (let index = 0; index < avaliacao.total_meses; index++) {
        arrayDistribuicaoValores.push({
          value: "",
        });
      }
      // Calcula total de meses da periodicidade
      state.mesesPeriodicidades =
        avaliacao && avaliacao.total_meses >= 1 ? avaliacao.total_meses : 0;
    }
    // if (state.mesesPeriodicidades === 1) {
    //   arrayDistribuicaoValores[0] = parseFloat(
    //     state.configuracao.objetivo.replace(".", "").replace(",", ".")
    //   );
    // } 
    commit("setDistribuicaoValores", arrayDistribuicaoValores);
  },
  calculaDistribuicaoDeValores({ commit, state, rootGetters }, posicao) {
    let avaliacao = rootGetters["avd/getAvaliacao"];

    // Total do objetivo cadastrado e convertido
    let valorGeral = parseFloat(
      state.configuracao.objetivo.replace(".", "").replace(",", ".")
    );
    //Quantidade preenchidos
    let qtdTotalPreenchido = state.distribuicao.filter(
      (el) => el !== ""
    ).length;

    let resultado = 0;

    // Caso não tiver nenhuma posição preenchida, a posição selecionada recebe o valor total
    if (qtdTotalPreenchido === 0) {
      state.distribuicao[posicao] = valorGeral.toString();
      // commit("setDistribuicaoValores", arrayDistribuicaoAux);
    }
    if (qtdTotalPreenchido > 0) {
      resultado = valorGeral / qtdTotalPreenchido;
      state.distribuicao.map((item, i) => {
        item = resultado;
      });
    }
    console.log("Valor geral: ", valorGeral);
    console.log("Posições preennchidas: ", qtdTotalPreenchido);
    console.log("Posição solicitada: ", posicao);
    console.log("Array distribuição: ", state.distribuicao);
  },
  habilitarCamposDistribuicaoValores({ state, dispatch }, item) {
    state.tabsVisualizarAvaliacao.forEach((el) => {
      if (el.item !== "distribuicao-valores") {
        el.disabled = item !== "" && item !== undefined ? !item : false;
      }
    });
    dispatch("setHabilitarDistribuicaoValores", item);
  },
};
