import axios from "axios";
import axiosIns from "@axios";

async function login({ commit, dispatch }, data) {
  return new Promise((resolve, reject) => {
    axios
    .post(process.env.VUE_APP_BASE_URI_API + "/auth/login", data)
    .then(response => resolve(response))
    .catch(error => reject(error))
  });
}

async function loginGithub({ commit, dispatch }) {
  return new Promise((resolve, reject) => {
    axios({
      url: process.env.VUE_APP_BASE_URI_API + "/authorize/github/redirect",
      method: "GET",
    })
      .then((response) => {
        resolve(response)
      })
      .catch((erro) => {
        console.error("Oops, Unable to login!");
        console.log("error :>> ", erro.response);
        reject(erro.response);
      });
  });
} 

async function loginUserCallback({ commit, dispatch }, payload) {
  return new Promise((resolve, reject) => {
    axios({
      url: process.env.VUE_APP_BASE_URI_API + "/auth/authorize/github/callback",
      params: payload,
      method: "GET",
    })
      .then((response) => {
        if (response.status === 200 && response.data.data.access_token) {
          const token = response.data.data.access_token;
          localStorage.setItem("accessToken", token);
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          resolve(response);
        }
        if (response.status === 200 && (response.data.data.codigo == 10 || response.data.data.codigo == 1))
        {
          resolve(response);
        }
      })
      .catch((erro) => {
        reject(erro);
      });
  });
}

async function recuperarSenha({ commit, dispatch }, data) {
  return new Promise((resolve, reject) => {
    axios
    .post(process.env.VUE_APP_BASE_URI_API + "/auth/forgotPassword", data)
    .then(response => resolve(response))
    .catch(error => reject(error))
  });
}

async function validarToken({ commit, dispatch }, dados) {
  return new Promise((resolve, reject) => {
    const token = dados.split("=");
    const data = {
      token: token[1],
    };
    axios({
      url: process.env.VUE_APP_BASE_URI_API + "/auth/validToken",
      data: data,
      method: "POST",
    })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.data.codigo == false) {
            commit("setErrorMessage", "Link expirado, tente novamente.");
            this.$route.push("auth-login");
          }
          resolve(true);
        }
        resolve(false);
      })
      .catch((erro) => {
        reject(erro);
      });
  });
}

async function novaSenha({ commit, dispatch }, data) {
  return new Promise((resolve, reject) => {
    axios
    .post(process.env.VUE_APP_BASE_URI_API + "/auth/registerNewPassword", data)
    .then(response => resolve(response))
    .catch(error => reject(error))
  });

}

async function register({ commit, dispatch }, data) {
  return new Promise((resolve, reject) => {
    axios
    .post(process.env.VUE_APP_BASE_URI_API + "/auth/register", data)
    .then(response => resolve(response))
    .catch(error => reject(error))
  });
}

async function dadosUsuario({ commit, dispatch }) {
  return new Promise((resolve, reject) => {
    axiosIns
    .get(process.env.VUE_APP_BASE_URI_API + "/usuarios/dados-usuario")
    .then(response => resolve(response))
    .catch(error => reject(error))
  });
}

async function buscarMenus({ commit, dispatch }) {
	return new Promise((resolve, reject) => {
    axiosIns.get(`${process.env.VUE_APP_BASE_URI_API}/grupo-acesso/initialize`)
			.then(response => { 
        localStorage.removeItem("menuList");  
        localStorage.setItem("menuList", JSON.stringify(response.data.data.menu))    
				//ls.set('menu', response.data.data.menu)
				resolve(true)
			})
      .catch(error => reject(error))
	})
}

export default {
  login,
  loginGithub,
  loginUserCallback,
  recuperarSenha,
  novaSenha,
  validarToken,
  dadosUsuario,
  buscarMenus,
  register,
};
