export default {
  setConfiguracoes(state, data) {
    state.configuracoes = data;
  },
  setConfiguracao(state, data) {
    state.configuracao = data;
  },
  setDistribuicaoValores(state, data) {
    state.distribuicao = data;
  },
  setMesesPeriodicidade(state, data) {
    state.mesesPeriodicidades = data;
  },
  setHabilitarDistribuicaoValores(state, data) {
    state.habilitarDistribuicaoValores = data;
  },
  setTotalApurado(state, data) {
    state.totalApurado = data
  },
  setConfigMetasIndividuais(state, data) {
    state.configMetasIndividuais = data
  },
  setColaboradoresConfigMetasIndividuais(state, data) {
    state.configMetasIndividuais.colaboradoresSelected = data
  },
  setGestoresConfigMetasIndividuais(state, data) {
    state.configMetasIndividuais.gestoresSelected = data
  }
};
