import restApi from "@axios";

export default {
  setMotivos({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING");
      restApi
        .get("/motivos/initialize")
        .then((response) => {
          commit("SET_LOADING");
          if (response.data) {
            commit("SET_MOTIVOS", response.data.data.motivos);
            commit("SET_LISTA_SUBMOTIVOS", response.data.data.submotivos);
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  salvarMotivo({ commit }, data) {
    return new Promise((resolve, reject) => {
      restApi
        .post("/motivos/salvar-configuracao-motivo", data)
        .then((response) => {
          if (response.data) {
            commit("SALVAR_MOTIVO", response.data.data);
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  atualizarMotivo({ commit }, data) {
    return new Promise((resolve, reject) => {
      restApi
        .put(`/motivos/editar-configuracao-motivo`, data)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  deletarMotivo({ commit }, data) {
    return new Promise((resolve, reject) => {
      restApi
        .delete(`/motivos/${data}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  getSubmotivos({ commit }, data) {
    return new Promise((resolve, reject) => {
      restApi
        .post("/motivos/visualizar-submotivos", data)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
};
