export default {
  authStatus: "",
  errorMessages: "",
  token: localStorage.getItem("accessToken") || false,
  user: JSON.parse(localStorage.getItem("user")) || [],
  role: localStorage.getItem("role") || "",
  tableList: [],
  notifications: [],
  currentTime: new Date().toLocaleString(),
  avatarURL:
    (localStorage.getItem("user") && localStorage.getItem("user").avatar) || "",
  name:
    (localStorage.getItem("user") && localStorage.getItem("user").name) || "",
  usuario: "",
  loading: false,
};
