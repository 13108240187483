export default {
  SET_SUBMOTIVOS(state, data) {
    state.dataSubmotivos = data;
  },
  SALVAR_SUBMOTIVO(state, data) {
    state.dataSubmotivos.push(data);
  },
  DELETAR_SUBMOTIVO(state, data) {
    state.dataSubmotivos = state.dataSubmotivos.filter(
      (element) => element.uuid !== data
    );
  },
  SET_LOADING(state) {
    state.loading = !state.loading;
  },
};
