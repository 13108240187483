import restApi from '@axios';

export default {
  setSubmotivos({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING");
      restApi
        .get("/submotivos")
        .then((response) => {
          commit("SET_LOADING");
          if (response.data) {
            commit("SET_SUBMOTIVOS", response.data.data.data);
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  salvarSubmotivos({ commit }, data) {
    return new Promise((resolve, reject) => {
      restApi
        .post("/submotivos", data)
        .then((response) => {
          if (response.data) {
            commit("SALVAR_SUBMOTIVO", response.data.data);
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  atualizarSubmotivo({ commit }, data) {
    const uuid = data.uuid;
    const submotivoAtualizado = {
      descricao: data.descricao,
      status: data.status,
    };

    return new Promise((resolve, reject) => {
      restApi
        .put(`/submotivos/${uuid}`, submotivoAtualizado)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  deletarSubmotivo({ commit }, data) {
    return new Promise((resolve, reject) => {
      restApi
        .delete(`/submotivos/${data}`)
        .then((response) => {
          if (response.data) {
            commit("DELETAR_SUBMOTIVO", data);
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
};
