export default {
  setFilial(state, data) {
    state.filial = data;
  },
  setFiliais(state, data) {
    state.filiais = data;
  },
  setFiliaisWithSetoresCargosTimes(state, data) {
    state.filiaisWithSetoresCargosTimes = data;
  },
  setLoadind(state)
  {
    state.loading =  !state.loading;
  }
};
