const Painel = () => import("../views/Painel.vue");
const Formulario = () => import("../views/Formulario.vue");

export default [
  {
    path: "/avd/painel",
    name: "avd-painel-view",
    meta: {
      layout: "content",
      requiresAuth: true,
      resource: "avaliacao-desempenho",
      action: "access",
    },
    component: Painel,
  },  
  {
    path: "/avd/formulario",
    name: "avd-formulario-view",
    meta: {
      layout: "content",
      requiresAuth: true,
      resource: "avaliacao-desempenho",
      action: "access",
    },
    component: Formulario,
  },
];
