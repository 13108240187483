import restApi from "@axios";

export default {
  setEscalasItens({ commit }, data) {
    commit('setEscalasItens', data)
  },
  save({ commit }, dados) {
    return new Promise((resolve, reject) => {
      restApi
        .post(`${dados.url}`, dados)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((err) => {
          console.log(err);
          reject("NAO_AUTORIZADO");
        })
        .finally(() => { });
    }).catch((error) => {
      reject(error);
    });
  },
  remove({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      restApi
        .delete(`/escalas-itens/${uuid}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => { });
    }).catch((error) => {
      reject(error);
    });
  },
  getDataByColumns({ commit }, data) {
    return new Promise(function (resolve, reject) {
      restApi
        .post("/escalas-itens/get-data-by-columns", data)
        .then((response) => {
          if (response.data) {
            commit("setEscalasItens", response.data.data);
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
};
