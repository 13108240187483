
export default {
    setFormsOfCollaborator(state, data) {
        state.formsOfCollaborator = data
    },
    setForm(state, data) {
        state.form = data
    },

    setTechnicalEvaluations(state, data) {
        state.technicalEvaluation = data
    },

    // Formulário de avaliação comportamental
    setCountQuestions(state, data) {
        state.countQuestions = data
    },
    setQuestion(state, data) {
        state.question = data
    }
}