import restApi from '@axios'

export default {
    setExperienciaProfissional({ commit }, data) {
        commit('setExperienciaProfissional', data)
    },
    setExperienciasProfissionais({ commit }, data) {
        commit('setExperienciasProfissionais', data)
    },
    setQuantidadeExperiencia({ commit }, data) {
        commit('setQuantidadeExperiencia', data)
    },

    resetExperienciaProfissional({ commit }) {
        const data = {
            funcao: '',
            empresa: '',
            descricao: '',
            inicio: null,
            fim: null,
            salario: null,
            trabalho_atual: "0",
            id_area: null,
            id_cargo: null,
        }

        commit('setExperienciaProfissional', data)
    },

    initialize({ commit }) {
        return new Promise(function (resolve, reject) {
            restApi.get('res-candidato-experiencia/initialize').then(response => {
                if (response.data) {
                    commit('setExperienciasProfissionais', response.data.data)
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },
    save({ commit }, data) {
        return new Promise(function (resolve, reject) {
            restApi.post(data.url, data.data).then(response => {
                if (response.data) {
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },
    findByColumns({ commit }, data) {
        return new Promise(function (resolve, reject) {
            restApi.post('res-candidato-experiencia/find-by-columns', data).then(response => {
                if (response.data) {
                    commit('setExperienciasProfissionais', response.data.data)
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },
    remove({ commit }, uuid) {
        return new Promise(function (resolve, reject) {
            restApi.delete(`res-candidato-experiencia/${uuid}`).then(response => {
                if (response.data) {
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },
    findByUuid({ commit }, uuid) {
        return new Promise(function (resolve, reject) {
            restApi.get(`res-candidato-experiencia/find-by-uuid/${uuid}`).then(response => {
                if (response.data) {
                    commit('setExperienciaProfissional', response.data.data)
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    }
}