import restApi from "@axios";

export default {
  save({ commit }, dados) {
    return new Promise((resolve, reject) => {
      restApi
        .post(dados.url, dados)
        .then((response) => {
          if (response.data.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.log(error);
          reject("NAO_AUTORIZADO");
        });
    });
  },
  updatePositionQuestions({ commit }, data) {
    return new Promise((resolve, reject) => {
      restApi
        .put("/pco-perguntas/update-position-question", data)
        .then((response) => {
          if (response.data != "NAO_AUTORIZADO") {
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        });
    }).catch((error) => reject(error));
  },
  remove({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      restApi
        .delete(`/pco-perguntas/${uuid}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  findByUuid({ commit }, uuid) {
    return new Promise(function (resolve, reject) {
      restApi
        .get(`/pco-perguntas/find-by-uuid/${uuid}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  fetchAllDataPergunta({ commit }, uuid) {
    return new Promise(function (resolve, reject) {
      restApi
        .get(`/pco-perguntas/fetch-all-data-pergunta/${uuid}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  fetchDataByColumns({ commit }, data) {
    return new Promise(function (resolve, reject) {
      restApi
        .post("/pco-perguntas/get-data-by-columns", data)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  /**
   * @description Busca por todas as perguntas de uma categoria relacionadas ao modelo.
   *
   * @param Object data Objeto que contem a **categoria** e **subcategoria** de perguntas e o **uuid** do modelo
   * @example { uuid: 'fbdfhfb733-dhu288-dfbdh305-hsd2g33', categoria: 'FI', subcategoria: 'BEN' }
   *
   * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
   */
  fetchQuestionsOfModelo({ commit }, data) {
    return new Promise(function (resolve, reject) {
      restApi
        .post("/pco-perguntas/fetch-questions-of-modelo", data)
        .then((response) => {
          if (response.data) {
            commit(
              `set${data.subcategoria ? data.subcategoria : data.categoria}`,
              response.data.data
            );

            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  /**
   * Duplica uma pergunta e todos os dados relacionados a ela.
   *
   * @param Object data
   * @example Data - { uuid: 'djfh474-dbdh48-knd33-4bdfh' }
   *
   * @return Object - Irá retornar o objeto inteiro da pergunta
   */
  duplicate({ commit }, data = Object) {
    return new Promise(function (resolve, reject) {
      restApi
        .post("/pco-perguntas/duplicate", data)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  removeRelationshipBetweenQuestions({ commit }, idPerguntaValidadora) {
    return new Promise(function (resolve, reject) {
      restApi
        .delete(`/pco-perguntas-invalidacoes/${idPerguntaValidadora}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  setPergunta({ commit }, data) {
    commit("setPergunta", data);
  },
  setPerguntas({ commit }, data) {
    commit("setPerguntas", data);
  },
  resetPergunta({ commit }) {
    const data = {
      id_empresa: null,
      id_modelo: null,
      id_grande_grupo: null,
      titulo: "",
      descricao: "",
      categoria: "",
      subcategoria: null,
      tipo: "",
      limite_de_opcoes: null,
      resposta_invertida: false,
      fidelidade: 0,
      obrigatoriedade: true,
      posicao: null,
      code_front: Math.floor(Date.now() / 1000),
      opcoes: [],
      pergunta_invalida: {
        pergunta_invalida: null,
        pergunta_validadora: null,
        item_invalido: null,
        item_validador: null,
      },
    };

    commit("resetPergunta", data);
  },
  resetPerguntas({ commit }) {
    const data = {
      FE: [],
      FI: {
        FAV: [],
        BEN: [],
        DES: [],
        MOT: [],
      },
    };

    commit("resetPerguntas", data);
  },
};
