export default {
  configuracoes: [],
  tabsVisualizarAvaliacao: [
    {
      id: 1,
      item: "distribuicao-valores",
      disabled: false,
      nome: "Distribução de valores",
      icon: "mdi-currency-usd",
      componente: () =>
        import(
          "@/modules/avd/avaliacao/components/tabs/forms/components/tecnica/tabs/DistribuicaoValoresComponent.vue"
        ),
    },
    {
      id: 2,
      item: "meta-individual",
      disabled: true,
      nome: "Configuração de objetivos",
      icon: "mdi-calendar-check",
      componente: () =>
        import(
          "@/modules/avd/avaliacao/components/tabs/forms/components/tecnica/tabs/MetasIndividuaisComponent.vue"
        ),
    },
    {
      id: 3,
      item: "config-extras",
      disabled: true,
      nome: "Configuração extras",
      icon: "mdi-application-cog-outline",
      componente: () =>
        import(
          "@/modules/avd/avaliacao/components/tabs/forms/components/tecnica/tabs/ConfiguracaoExtraComponent.vue"
        ),
    },
  ],
  configuracao: {
    unidade: null,
    melhor_se: null,
    verbo: null,
    complemento: null,
    objetivo: null,
    tipo_distribuicao: null,
    tipo_tecnica: null,
    range: [0, 1],
    detalhamento: '',
    fonte_apuracao: '',
    perspectiva_bsc: 'N'
  },
  distribuicao: [],
  mesesPeriodicidades: 0,
  habilitarDistribuicaoValores: false,
  totalApurado: 0,
  configMetasIndividuais: {
    gestoresSelected: [],
    colaboradoresSelected: [],
    aplicaTodos: false,
  }
};
