export default {
  getPropostas: (state) => {
    return state.propostas;
  },
  getProdutos: (state) => {
    return state.produtos;
  },
  getMotivos: (state) => {
    return state.motivos;
  },
  getLoading: (state) => {
    return state.loading;
  },
};
