export default [
  {
    title: "Painel",
    icon: "mdi-view-dashboard",
    to: "dashboard-adm-dev",
    resource: "dashboard-adm-dev",
    action: "access",
  },
  {
    title: "Painel de bordo",
    icon: "mdi-view-dashboard",
    to: "painel-adm",
    resource: "dashboard-adm",
    action: "access",
  },
  {
    title: "Comercial",
    icon: "mdi-handshake-outline",
    resource: "comercial",
    action: "access",
    children: [
      {
        title: "Gestão de Propostas",
        icon: "mdi-currency-usd",
        to: "propostas-adm",
        resource: "propostas",
        action: "access",
      },
      {
        title: "Gestão de motivo e submotivo",
        icon: "mdi-chat-alert-outline",
        to: "motivos-adm",
        resource: "motivos",
        action: "access",
      },
    ],
  },
  {
    title: "Pesquisa de clima",
    icon: "mdi-emoticon-happy-outline",
    resource: "pesquisa-clima",
    action: "access",
    children: [
      {
        title: "Pesquisa",
        icon: "mdi-note-plus-outline",
        to: "pco-pesquisas",
        resource: "pesquisa-clima",
        action: "access",
      },
      {
        title: "Modelo",
        icon: "mdi-note-text-outline",
        to: "pco-modelos",
        resource: "pesquisa-clima",
        action: "access",
      },
    ],
  },
  {
    title: "Avaliação de desempenho",
    icon: "mdi-file-chart-outline",
    resource: "avaliacao-desempenho",
    action: "access",
    children: [
      {
        title: "Painel de bordo",
        icon: "mdi-finance",
        to: "avd-painel-view",
        resource: "avaliacao-desempenho",
        action: "access",
      },
      {
        title: "Gestão de AVD",
        icon: "mdi-chart-box-plus-outline",
        to: "avd-avaliacoes-view",
        resource: "avaliacao-desempenho",
        action: "access",
      },
    ],
  },
  {
    title: "Recrutamento e seleção",
    icon: "mdi-account-group-outline",
    resource: "recrutamento",
    action: "access",
    children: [
      {
        title: "Configuração",
        icon: "mdi-cog-outline",
        resource: "res-configuracao",
        action: "access",
        children: [
          {
            title: "Área de Atuação",
            to: "area-atuacao-res",
            resource: "res-configuracao",
            action: "access",
          },
          {
            title: "Cargo",
            to: "cargo-res",
            resource: "res-configuracao",
            action: "access",
          },
          {
            title: "Categoria",
            to: "categoria-res",
            resource: "res-configuracao",
            action: "access",
          },
          {
            title: "Competência",
            to: "competencia-res",
            resource: "res-configuracao",
            action: "access",
          },
          {
            title: "Curso",
            to: "curso-res",
            resource: "res-configuracao",
            action: "access",
          },
          {
            title: "Deficiência",
            to: "deficiencia-res",
            resource: "res-configuracao",
            action: "access",
          },
          {
            title: "Estado Civil",
            to: "estado-civil-res",
            resource: "res-configuracao",
            action: "access",
          },
          {
            title: "Formação",
            to: "formacao-res",
            resource: "res-configuracao",
            action: "access",
          },
          {
            title: "Idioma",
            to: "idioma-res",
            resource: "res-configuracao",
            action: "access",
          },
          {
            title: "Tipo de Vaga",
            to: "tipo-vaga-res",
            resource: "res-configuracao",
            action: "access",
          },
        ],
      },
    ]
  },
  {
    title: "Configurações",
    icon: "mdi-cog-outline",
    resource: "configuracoes",
    action: "access",
    children: [
      {
        title: "Gestão de empresa",
        icon: "mdi-domain",
        to: "empresas-adm",
        resource: "configuracoes",
        action: "access",
      },
      {
        title: "Gestão de papéis",
        icon: "mdi-shield-account-variant-outline",
        to: "adm-config-papeis",
        resource: "configuracoes",
        action: "access",
      },
      {
        title: "Gestão de permissões",
        icon: "mdi-shield-account-variant-outline",
        to: "adm-config-permissoes",
        resource: "configuracoes",
        action: "access",
      },
      {
        title: "Definições R&S",
        icon: "mdi-shield-account-variant-outline",
        to: "adm-config-definicoes-res",
        resource: "definicoesRes",
        action: "access",
      },
      {
        title: "Controle de acessos",
        icon: "mdi-shield-account-variant-outline",
        to: "adm-config-controle-acesso",
        resource: "controleAcesso",
        action: "access",
      },
    ],
  },
];
