import restApi from "@axios";

export default {
  setCargo({ commit }, data) {
    commit('setCargo', data)
  },
  initialize({ commit }) {
    return new Promise(function (resolve, reject) {
      restApi
        .get("/cargos/initialize")
        .then((response) => {
          if (response.data) {
            commit("setCargos", response.data.data);
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  findByUuid({ commit }, uuid) {
    return new Promise(function (resolve, reject) {
      restApi
        .get(`/cargos/find-by-uuid/${uuid}`)
        .then((response) => {
          if (response.data) {
            commit("setCargo", response.data.data);
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  findByColumns({ commit }, data) {
    return new Promise(function (resolve, reject) {
      restApi
        .post("/cargos/find-by-columns", data)
        .then((response) => {
          if (response.data) {
            commit("setCargosOfByColumns", response.data.data);
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  save({ commit }, data) {
    return new Promise(function (resolve, reject) {
      restApi
        .post(data.url, data)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  remove({ commit }, uuid) {
    return new Promise(function (resolve, reject) {
      restApi
        .delete(`/cargos/${uuid}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
};
