export default {
  getItens(state) {
    return state.itens;
  },
  getEscalaItem: (state) => {
    return state.escala_item;
  },
  getEscalasItens: (state) => {
    return state.escalas_itens;
  },
};
