import restApi from "@axios";

export default {
  setFilial({ commit }, data) {
    commit('setFilial', data)
  },
  initialize({ commit }) {
    return new Promise(function (resolve, reject) {
      restApi.get('/filiais/initialize').then(response => {
        if (response.data) {
          commit('setFiliais', response.data.data)
          resolve(response.data.data)
        } else {
          reject('NAO_AUTORIZADO')
        }
      }).catch(error => {
        console.error(error)
        reject(error)
      })
    })
  },
  findByColumns({ commit }, data) {
    return new Promise(function (resolve, reject) {
      restApi
        .post("/filiais/find-by-columns", data)
        .then((response) => {
          if (response.data) {
            commit("setFiliais", response.data.data);
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  findByUuid({ commit }, uuid) {
    return new Promise(function (resolve, reject) {
      restApi.get(`/filiais/find-by-uuid/${uuid}`).then(response => {
        if (response.data) {
          commit('setFilial', response.data.data)
          resolve(response.data.data)
        } else {
          reject('NAO_AUTORIZADO')
        }
      }).catch(error => {
        console.error(error)
        reject(error)
      })
    })
  },
  save({ commit }, data) {
    return new Promise(function (resolve, reject) {
      restApi.post(data.url, data).then(response => {
        if (response.data) {
          resolve(response.data.data)
        } else {
          reject('NAO_AUTORIZADO')
        }
      }).catch(error => {
        console.error(error)
        reject(error)
      })
    })
  },
  remove({ commit }, uuid) {
    return new Promise(function (resolve, reject) {
      restApi.delete(`/filiais/${uuid}`).then(response => {
        if (response.data) {
          resolve(response.data.data)
        } else {
          reject('NAO_AUTORIZADO')
        }
      }).catch(error => {
        console.error(error)
        reject(error)
      })
    })
  },
  fetchFiliaisWithSetoresCargosTimes({ commit }) {
    return new Promise(function (resolve, reject) {
      restApi
        .get("/filiais/fetch-filiais-with-setores-cargos-times")
        .then((response) => {
          if (response.data) {
            commit("setFiliaisWithSetoresCargosTimes", response.data.data);
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  retornaFiliais({ commit }, data) {
    // commit("setLoading");
    return new Promise(function (resolve, reject) {
      restApi
        .post("/filiais/retorna-filiais", data)
        .then((response) => {
          if (response.data) {
            commit("setFiliais", response.data.data);
            // commit("setLoading");
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .finally(() => {
          // commit("setLoading");
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
};
