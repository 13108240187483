import restApi from '@axios';

export default {
    getDataForFilter({ commit }) {
        return new Promise(function (resolve, reject) {
            restApi.get('/ext/res-tipo-vaga/get-data-for-filter').then(response => {
                if (response.data) {
                    commit('setTipoVagas', response.data.data)
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch((error) => {
                reject(error)
            })
        })
    },

    setTipoVaga({ commit }, data) {
        commit('setTipoVaga', data)
    },
    initialize({ commit }) {
        return new Promise(function (resolve, reject) {
            restApi.get('/res-tipo-vaga/initialize').then(response => {
                if (response.data && typeof response.data.data == 'array') {
                    commit('setTipoVagas', response.data.data)
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },
    save({ commit }, data) {
        return new Promise(function (resolve, reject) {
            restApi.post(data.url, data).then(response => {
                if (response.data) {
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },
    findByUuid({ commit }, uuid) {
        return new Promise(function (resolve, reject) {
            restApi.get(`/res-tipo-vaga/find-by-uuid/${uuid}`).then(response => {
                if (response.data) {
                    commit('setTipoVaga', response.data.data)
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },
    remove({ commit }, uuid) {
        return new Promise(function (resolve, reject) {
            restApi.delete(`/res-tipo-vaga/${uuid}`).then(response => {
                if (response.data) {
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },
    findByColumns({ commit }, data) {
        return new Promise(function (resolve, reject) {
            restApi.post('/res-tipo-vaga/find-by-columns', data).then(response => {
                if (response.data) {
                    commit('setTipoVagas', response.data.data)
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch((error) => {
                reject(error)
            })
        })
    },
}