import restApi from "@axios";

export default {
  setUsuario({ commit }, data) {
    commit('setUsuario', data)
  },
  initializeByCompany({ commit }, uuid) {
    return new Promise(function (resolve, reject) {
      restApi.get(`/usuarios/initialize-by-company/${uuid}`).then(response => {
        if (response.data) {
          commit('setUsuarios', response.data.data)
          resolve(response.data.data)
        } else {
          reject('NAO_AUTORIZADO')
        }
      }).catch(error => {
        console.error(error)
        reject(error)
      })
    })
  },
  getDataByColumn({ commit }, data) {
    return new Promise(function (resolve, reject) {
      restApi
        .post("usuarios/get-data-by-column", data)
        .then((response) => {
          if (response.data) {
            commit("setResponsaveis", response.data.data);
            commit("setUsuarios", response.data.data);
            resolve(response.data.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchCollaboratorsOfResearch({ commit }, data) {
    return new Promise(function (resolve, reject) {
      restApi
        .post("/usuarios/fetch-collaborators-of-research", data)
        .then((response) => {
          if (response.data.data) {
            commit("fetchCollaboratorsOfResearch", response.data.data);
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);

          reject(error);
        });
    });
  },
  buscarGestores({ commit }, data) {
    commit("SET_LOADING");
    return new Promise(function (resolve, reject) {
      restApi
        .post("/usuarios/retorna-gestores", data)
        .then((response) => {
          commit("SET_LOADING");

          if (response.data.data) {
            commit("setResponsaveis", response.data.data);
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);

          reject(error);
        });
    });
  },
  findByUuid({ commit }, uuid) {
    return new Promise(function (resolve, reject) {
      restApi.get(`/usuarios/find-by-uuid/${uuid}`).then(response => {
        if (response.data) {
          resolve(response.data.data)
        } else {
          reject('NAO_AUTORIZADO')
        }
      }).catch(error => {
        console.error(error)
        reject(error)
      })
    })
  },
  save({ commit }, data) {
    return new Promise(function (resolve, reject) {
      restApi.post(data.url, data).then(response => {
        if (response.data) {
          resolve(response.data.data)
        } else {
          reject('NAO_AUTORIZADO')
        }
      }).catch(error => {
        console.error(error)
        reject(error)
      })
    })
  },
  remove({ commit }, uuid) {
    return new Promise(function (resolve, reject) {
      restApi.delete(`/usuarios/${uuid}`).then(response => {
        if (response.data) {
          resolve(response.data.data)
        } else {
          reject('NAO_AUTORIZADO')
        }
      }).catch(error => {
        console.error(error)
        reject(error)
      })
    })
  },
  /**
 * @description Salva a imagem do usuário e retorna a url para o mesmo.
 *
 * @param data
 * @example - { id_usuario: 1, foto: filePhoto }
 * 
 * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
 */
  savePhotoUser({ commit }, data) {
    return new Promise(function (resolve, reject) {
      restApi.post('usuarios/save-photo-user', data).then(response => {
        if (response.data) {
          resolve(response.data.data)
        } else {
          reject('NAO_AUTORIZADO')
        }
      }).catch(error => {
        console.error(error)
        reject(error)
      })
    })
  },

  changeEmailCandidato({ commit }, data) {
    return new Promise(function (resolve, reject) {
      restApi.post('/usuarios/change-email-candidato', data).then(response => {
        if (response.data) {
          resolve(response.data.data)
        } else {
          reject('NAO_AUTORIZADO')
        }
      }).catch(error => {
        console.error(error)
        reject(error)
      })
    })
  },
  changeSenhaCandidato({ commit }, data) {
    return new Promise(function (resolve, reject) {
      restApi.post('/usuarios/change-senha-candidato', data).then(response => {
        if (response.data) {
          resolve(response.data.data)
        } else {
          reject('NAO_AUTORIZADO')
        }
      }).catch(error => {
        console.error(error)
        reject(error)
      })
    })
  },
};
