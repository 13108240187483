export default {
  SALVAR_OPCOES(state, value) {
    state.opcoesNovas = value;
  },
  SALVAR_OPCAO(state, value) {
    state.opcaoCriada = value;
  },
  deletarOpcao(state, uuid) {
    state.opcoes = state.opcoes.filter(
      (element) => element.uuid !== uuid
    );
  },
};