export default {
  getFilial(state) {
    return state.filial;
  },
  getFiliais(state) {
    return state.filiais;
  },
  getFiliaisWithSetoresCargosTimes(state) {
    return state.filiaisWithSetoresCargosTimes;
  },
  getLoading(state)
  {
    return state.loading;
  }
};
