import '@/plugins/vue-composition-api';
import '@/styles/styles.scss';
import '@/plugins';
import '@/plugins/vee-validate';
import '@/plugins/vue-2editor';
import '@/filters/filters';
import Vue from 'vue';
import App from './App.vue';
import './plugins/acl';
import vuetify from './plugins/vuetify'
import router from './router';
import store from './store/index';
// import { sync } from "vuex-router-sync";

Vue.config.productionTip = false

// sync(store, router);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
