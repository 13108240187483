import restApi from "@axios";

export default {
  initialize({ commit, dispatch }, dados) {
    commit("SET_LOADING", true);
    restApi
      .post(dados.value.url)
      .then((response) => {
        commit(`${dados.value.set}`, response.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  salvar({ commit }, dados) {
    return new Promise((resolve, reject) => {
      restApi
        .post(dados.url, dados)
        .then((response) => {
          if (response.data.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.log(error);
          reject("NAO_AUTORIZADO");
        });
    });
  },
  findByUuid({ commit }, url) {
    return new Promise(function (resolve, reject) {
      restApi
        .get(url)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  show({ commit }, url) {
    return new Promise(function (resolve, reject) {
      restApi
        .get(url)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  remover({ commit }, dados) {
    console.log(dados)
    return new Promise((resolve, reject) => {
      restApi
        .delete(dados.url)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }).catch((error) => {
      reject(error);
    });
  },
};
