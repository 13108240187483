
export default {
    contrato: {
        id_filial: null,
        id_proposta: null,
        tipo: null,
        arquivo: null,
        status: 'A'
    },
    contratos: [],
}