const Painel = () => import("../views/Painel.vue");

export default [
  {
    path: "/pco/escalas",
    name: "pco-escalas",
    component: Painel,
    meta: {
      layout: "content",
      requiresAuth: true,
      resource: "pcoEscalas",
      action: "view",
    },
  },
];
