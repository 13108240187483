export default {
  getAvaliacao: (state) => {
    return state.avaliacao;
  },
  getFullDataAvaliacao: (state) => {
    return state.fullDataAvaliacao;
  },
  getAvaliacoes: (state) => {
    return state.avaliacoes;
  },
  getColaboradores: (state) => {
    return state.colaboradores;
  },
  getSelecaoIdsFiliaisSetoresCargosTimes: (state) => {
    return state.selecaoIdsFiliaisSetoresCargosTimes;
  },
  getFiliais: (state) => {
    return state.filiais;
  },
  getPainelMinhasAvaliacoes: (state) => {
    return state.minhasAvaliacoes;
  },
  getPainelAvaliacoes: (state) => {
    return state.avaliacoes;
  },
  getDataPainelDeBordo: (state) => {
    return state.dataPainelDeBordo;
  },
  getDataCompetencias: (state) => {
    return state.dataCompetencias;
  },
  getTabsPainel: (state) => {
    return state.tabsPainel;
  },
  getPermissaoDoUsuario: (state) => {
    return state.permissaoDoUsuario;
  },
  getDadosTotalizadores: (state) => {
    return state.dadostotalizadores;
  },
  getTotalizadores: (state) => {
    return state.totalizadores;
  },
  getTabsVisualizarAvaliacao: (state) => {
    return state.tabsVisualizarAvaliacao;
  },
  getTabsVisualizarConfiguracaoAvaliacao: (state) => {
    return state.tabsVisualizarConfiguracaoAvaliacao;
  },
  getVisualizarAvaliacao: (state) => {
    return state.dataVisualizarAvaliacao;
  },
  getResponsaveis: (state) => {
    return state.responsaveis;
  },
  getStatusAvaliacao: (state) => {
    return state.statusAvaliacao;
  },
  getTiposAvaliacao: (state) => {
    return state.tiposAvaliacao;
  },
  getFiliaisDaAvaliacao(state) {
    return state.filiaisDaAvaliacao;
  },
  getSetoresDaAvaliacao(state) {
    return state.setoresDaAvaliacao;
  },
  getTimesDaAvaliacao(state) {
    return state.timesDaAvaliacao;
  },
  getCargosDaAvaliacao(state) {
    return state.cargosDaAvaliacao;
  },
  getLoadingFormulario(state) {
    return state.loadingFormulario;
  },

  // Comportamento
  getComportamentos(state) {
    return state.comportamentos;
  },

  // Competencias
  getCompetencias(state) {
    return state.competencias;
  },

  // Gerais
  getLoading(state) {
    return state.loading;
  },

  // Modelo
  getModelos(state) {
    return state.modelos;
  },
  getModeloSelecionado(state) {
    return state.modeloSelecionado;
  },
  getAvdComportamental(state) {
    return state.AvdComportamental;
  },
  getAvdTecnica(state) {
    return state.AvdTecnica;
  },
  getCompetenciasComportamentais(state) {
    return state.competenciasComportamentais;
  },
  getCompetenciasComportamentaisSelect(state) {
    return state.competenciasComportamentaisSelect;
  },
  getComportamentosComportamentais(state) {
    return state.comportamentosComportamentais;
  },
  getCompetenciasTecnicas(state) {
    return state.competenciasTecnicas;
  },
  getCompetenciasTecnicasSelect(state) {
    return state.competenciasTecnicas;
  },
  getComportamentosTecnicas(state) {
    return state.comportamentosTecnicas;
  },
  getTabPainel(state) {
    return state.tabPainel;
  },

  // Tabs formulário
  getTabsForm(state) {
    return state.tabsForm;
  },
  getTabFormSelected(state) {
    return state.tabFormSelected;
  },
  getCurrentStepper(state) {
    return state.currentStepper;
  },

  getIsShowStepper(state) {
    return state.isShowStepper;
  },


  
  
};
