export default {
  setor: {
    id_filial: null,
    id_cliente: null,
    id_tipo: null,
    id_responsavel: null,
    nome: "",
    descricao: "",
    status: "",
  },
  setores: [],
};
