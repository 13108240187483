import pesquisaRouter from "../pesquisa/router/index.js";
import modelosRouter from "../modelos/router/index.js";
import gruposRouter from "../grupos/router/index.js";
import escalasRouter from "../escalas/router/index.js";
import dashboardRouter from "../dashboard/router/index.js";

const router = [
  ...pesquisaRouter,
  ...modelosRouter,
  ...gruposRouter,
  ...escalasRouter,
  ...dashboardRouter,
];

export default router;
