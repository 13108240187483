import restApi from '@axios'

export default {
    setModal({ commit }, data) {
        commit('setModal', data)
    },
    resetModal({ commit }) {
        const data = {
            title: '',
            dialog: false,
            hideButtonSave: false,
            hideButtonReturn: true,
            hidePlusButton: true,
            component: null,
            nameComponent: null,
            saveButtonClicked: false,
            returnButtonClicked: false,
            loadingButtonSave: false,
            plusButtonClicked: false,
        }

        commit('setModal', data)
    },
    setCandidato({ commit }, data) {
        commit('setCandidato', data)
    },
    /**
     * @description Muda o card selecionado no state de genericCards.cardSelected
     * 
     * @state genericCards
    */
    setCardSelected({ commit }, data) {
        commit('setCardSelected', data)
    },

    getOportunidades({ commit }, data = Object) {
        return new Promise((resolve, reject) => {
            restApi
                .post(data.url, data.data)
                .then((response) => {
                    if (response.data) {
                        commit("setOportunidades", response.data.data);
                        resolve(response.data.data);
                    } else {
                        reject("NAO_AUTORIZADO");
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        })
    },
    getVagas({ commit }, data) {
        console.log('getVagas')
    },
    getProcessos({ commit }, data) {
        return new Promise((resolve, reject) => {
            restApi
                .get(data.url)
                .then((response) => {
                    if (response.data) {
                        commit("setProcessos", response.data.data);
                        resolve(response.data.data);
                    } else {
                        reject("NAO_AUTORIZADO");
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        })
    },
    /**
     * @description Busca pelos dados gerais do painel do candidato
     * 
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
    */
    getDataPainelCandidato({ commit, dispatch }) {
        return new Promise(function (resolve, reject) {
            restApi.get('/usuarios/get-data-painel-candidato').then(response => {
                if (response.data) {
                    dispatch('resObjetivosProfissionais/setObjetivoProfissional', response.data.data.objetivo_profissional, { root: true })
                    dispatch('resExperienciasProfissionais/setExperienciasProfissionais', [response.data.data.experiencia_profissional.experiencia_profissional], { root: true })
                    dispatch('resExperienciasProfissionais/setQuantidadeExperiencia', response.data.data.experiencia_profissional.quantidade_experiencia, { root: true })
                    dispatch('resCandidatosFormacoes/setCandidatosFormacoes', [response.data.data.formacao_academica.formacao_academica], { root: true })
                    dispatch('resCandidatosFormacoes/setQuantidadeFormacao', response.data.data.formacao_academica.quantidade_formacao, { root: true })
                    dispatch('resCandidatosRedesSociais/setRedesSociais', response.data.data.candidato.redes_sociais, { root: true })
                    commit('setCandidato', response.data.data.candidato);

                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },

    salvarDadosPessoaisCandidato({ commit }, data) {
        return new Promise(function (resolve, reject) {
            restApi.post(`/usuarios/salvar-dados-pessoais-candidato/${data.uuid}`, data.data).then(response => {
                if (response.data) {
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },
    salvarDadosProfissionaisCandidato({ commit }, data) {
        return new Promise(function (resolve, reject) {
            restApi.post(`/usuarios/salvar-dados-profissionais-candidato/${data.uuid}`, data.data).then(response => {
                if (response.data) {
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },
    /**
     * @description Busca por todos os dados do candidato e retorna dividido em Pessoais e Profissionais
     * 
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
    */
    getAllDatasCandidato({ commit, dispatch }) {
        return new Promise(function (resolve, reject) {
            restApi.get('/usuarios/get-all-datas-candidato').then(response => {
                if (response.data) {
                    commit('setProfissionais', response.data.data.profissionais);
                    commit('setPessoais', response.data.data.pessoais);

                    dispatch('resCandidatosIdiomas/setIdiomas', response.data.data.idiomas, { root: true });
                    dispatch('resCandidatosRedesSociais/setRedesSociais', response.data.data.redes_sociais, { root: true });

                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },

    setPessoais({ commit }, data) {
        commit('setPessoais', data)
    },
    setProfissionais({ commit }, data) {
        commit('setProfissionais', data)
    }
}