export default {
  colaborador: {
    nome: null,
    email: null,
    cargo: null,
    setor: null,
    gestor: false,
    id_gestor: null,
    id_nivel: null,
    id_filial: null,
    id_cliente: null,
    data_admissao: null,
    status: "A",
    salario: null,
  },
  colaboradores: [],
  dataSpreadsheets: [], // Array relacionado ao colaboradores registrados através da planilha.
  listaGestores: [],
  listaColaboradores: [],
  
};
