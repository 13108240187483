export default [
  {
    path: "/adm/motivos",
    name: "motivos-adm",
    component: () =>
      import("@/modules/administrativo/modules/motivos/views/MotivosView.vue"),
    meta: {
      layout: "content",
      requiresAuth: true,
      resource: "motivos",
      action: "access",
    },
  },
];
