export default {
  setColaborador(state, data) {
    state.colaborador = data;
  },
  setColaboradores(state, data) {
    state.colaboradores = data;
  },
  setDataSpreadsheets(state, data) {
    state.dataSpreadsheets = data;
  },
  setListaGestrores(state, data) {
    state.listaGestores = data;
  },
  setListaColaboradores(state, data) {
    state.listaColaboradores = data;
  },
};
