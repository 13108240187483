import restApi from "@axios";

export default {
    setContrato({ commit }, data) {
        commit('setContrato', data)
    },
    initialize({ commit }) {
        return new Promise(function (resolve, reject) {
            restApi
                .get("/contratos/initialize")
                .then((response) => {
                    if (response.data) {
                        commit("setContratos", response.data.data);
                        resolve(response.data.data);
                    } else {
                        reject("NAO_AUTORIZADO");
                    }
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
    },
    findByUuid({ commit }, uuid) {
        return new Promise(function (resolve, reject) {
            restApi
                .get(`/contratos/find-by-uuid/${uuid}`)
                .then((response) => {
                    if (response.data) {
                        commit("setContrato", response.data.data);
                        resolve(response.data.data);
                    } else {
                        reject("NAO_AUTORIZADO");
                    }
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
    },
    save({ commit }, data) {
        return new Promise(function (resolve, reject) {
            restApi
                .post(data.url, data.data)
                .then((response) => {
                    if (response.data) {
                        resolve(response.data.data);
                    } else {
                        reject("NAO_AUTORIZADO");
                    }
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
    },
    remove({ commit }, uuid) {
        return new Promise(function (resolve, reject) {
            restApi
                .delete(`/contratos/${uuid}`)
                .then((response) => {
                    if (response.data) {
                        resolve(response.data.data);
                    } else {
                        reject("NAO_AUTORIZADO");
                    }
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
    },
};
