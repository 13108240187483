
export default {
    getFormsOfCollaborator(state) {
        return state.formsOfCollaborator;
    },
    getForm(state) {
        return state.form
    },

    getTechnicalEvaluations(state) {
        return state.technicalEvaluation
    },

    // Formulário de avaliação comportamental
    getCountQuestions(state) {
        return state.countQuestions
    },
    getQuestion(state) {
        return state.question
    }
}