export default {
  dataPesquisas: [],
  dataPesquisaSelecionada: {
    data_hora_inicio: "",
    data_hora_final: "",
    responsavel: {
      nome: "",
    },
  },
  dataResponsaveis: [],
  dataModeloslegiio: [],
  dataModelosPersonalizados: [],
  loading: false,
  dadosPainel: {
    totalizadores: [{}],
    filtros: [],
    favorabilidade: [],
  },
  dadosPesquisaExterna: {},
  loadingPesquisaExterna: false,
  pesquisaExterna: {},
  pesquisaDialogExterna: false,
  habilitarFormulario: false,
  respostas: [],
  questionIndex: 0,
  loadingEstruturaFormulario: false,
  errorPesquisa: false,
  pesquisaFinalizadaDialogExterna: false,
  pesquisaVencidaDialogExterna: false,
  pesquisaExternaVencidaMessage: "",
  filtros: [],
  statisticsData: [],
  mensagemRetorno: "",
  pesquisa: {
    titulo: "",
    descricao: "",
    id_cliente: null,
    id_responsavel: null,
    id_modelo: null,
    data_inicio: "",
    hora_inicio: "",
    data_final: "",
    hora_final: "",
    status: "A",
    anonimo: "",
    arquivo: "",
    tipo: null,
    devolutivas: [],
    perguntas: [],
  },
  tab: [],
  tabPainel: [],
  tabsCategorias: [
    {
      id: 1,
      name: "Fatores externos",
      ref: "FE",
      scopeValidation: "FE",
      disabled: true,
      perguntas: 0,
      component: () =>
        import("@/modules/pco/pesquisa/components/tabs/FatoresExternosTab"),
    },
    {
      id: 2,
      name: "Favorabilidade",
      ref: "FAV",
      scopeValidation: "FAV",
      disabled: true,
      perguntas: 0,
      component: () =>
        import("@/modules/pco/pesquisa/components/tabs/FavorabilidadeTab"),
    },
    {
      id: 3,
      name: "Benefícios",
      scopeValidation: "BEN",
      disabled: true,
      perguntas: 0,
      component: () =>
        import("@/modules/pco/pesquisa/components/tabs/BeneficiosTab"),
    },
    {
      id: 4,
      name: "Motivacional",
      scopeValidation: "MOT",
      disabled: true,
      perguntas: 0,
      component: () =>
        import("@/modules/pco/pesquisa/components/tabs/MotivacionalTab"),
    },
    {
      id: 5,
      name: "Desmotivacional",
      scopeValidation: "DES",
      disabled: true,
      perguntas: 0,
      component: () =>
        import("@/modules/pco/pesquisa/components/tabs/DesmotivacionalTab"),
    },
  ],
  arrayPerguntas: [],
  ultimaPerguntaRespondida: [],
  tabsPainel: [
    {
      id: 1,
      name: "Externas",
      ref: "EXT",
      scopeValidation: "EXT",
      disabled: false,
      component: () =>
        import("@/modules/pco/dashboard/components/tabs/ExternasTab"),
    },
    {
      id: 2,
      name: "Favorabilidade",
      ref: "FAV",
      scopeValidation: "FAV",
      disabled: false,
      component: () =>
        import("@/modules/pco/dashboard/components/tabs/FavorabilidadeTab"),
    },
    {
      id: 3,
      name: "Pearson",
      ref: "PEA",
      scopeValidation: "PEA",
      disabled: false,
      component: () =>
        import("@/modules/pco/dashboard/components/tabs/PearsonTab"),
    },
    {
      id: 4,
      name: "Benefícios",
      ref: "BEN",
      scopeValidation: "BEN",
      disabled: false,
      component: () =>
        import("@/modules/pco/dashboard/components/tabs/BeneficiosTab"),
    },
    {
      id: 5,
      name: "Motivacional",
      ref: "MOT",
      scopeValidation: "MOT",
      disabled: false,
      component: () =>
        import("@/modules/pco/dashboard/components/tabs/MotivacionalTab"),
    },
    {
      id: 6,
      name: "Desmotivacional",
      ref: "DES",
      scopeValidation: "DES",
      disabled: false,
      component: () =>
        import("@/modules/pco/dashboard/components/tabs/DesmotivacionalTab"),
    },
    {
      id: 7,
      name: "Abertas",
      ref: "ABE",
      scopeValidation: "ABE",
      disabled: false,
      component: () =>
        import("@/modules/pco/dashboard/components/tabs/AbertasTab"),
    },
    {
      id: 8,
      name: "Externas x Internas",
      ref: "EXTXABE",
      scopeValidation: "EXTXABE",
      disabled: false,
      component: () =>
        import(
          "@/modules/pco/dashboard/components/tabs/relatorios/ExtarnasAbertasTab"
        ),
    },
  ],
  fidelidade: {},
  loadingsPainel: {
    loadingFidelidade: true,
    loadingExport: false
  },
  totalizadoresPainel: [],
  beneficiosPainel: [],
  motivacionalPainel: [],
  desmotivacionalPainel: [],
  externasPainel: [],
  gruposPainel: [],

  favorabilidadeGeralPainel: [],
  favorabilidadePainel: [],
  dadosPesquisaExternaInternas: [],
  listaPerguntasAbertas: [],

  // Dados ModalCadastroPerguntas
  dialogModalCadastroPerguntas: false,
  categoriaModalCadastroPerguntas: null,

  // Dados ModalDeletePergunta
  dialogDeletePergunta: false,
  dialogCode: false,
  messageCode: "",
  descricaoPesquisa: "",
  componenteInicial: true,
  componenteFormulario: false,
  perguntasFormularioExterno: [],
  devolutivas: [
    { id: "TOT", nome: "Totalizadores" },
    { id: "NPS", nome: "Gráfico NPS" },
    { id: "FAV", nome: "Favorabilidade" },
    { id: "FAG", nome: "Favorabilidade Geral" },
    { id: "PER", nome: "Perguntas" },
    { id: "MPB", nome: "Média ponderada de benefícios" },
    { id: "GRP", nome: "Gráfico de pearson" },
    { id: "LQA", nome: "Lista de questões abertas" },
  ],

  // Configuração pesquisa
  modalPerguntaSelecionada: "FE",
};
