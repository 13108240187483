export default [
  {
    path: "/adm/empresas/informacoes/:uuid/modulos/:modulo/:children?",
    name: "empresas-adm-modulos",
    component: () =>
      import(
        "@/modules/administrativo/modules/empresas/components/modulos/GenericRenderComponents.vue"
      ),
    meta: {
      layout: "administrativo-modulos",
      requiresAuth: true,
      resource: "empresas",
      action: "access",
    },
    props: true,
  },
  //   {
  //     path: "/adm/empresas/informacoes/:uuid/modulos/pco",
  //     name: "empresas-adm-pco",
  //     component: () =>
  //       import(
  //         "@/modules/administrativo/modules/empresas/components/modulos/PCO.vue"
  //       ),
  //     meta: {
  //       layout: "administrativo-modulos",
  //       requiresAuth: true,
  //       resource: "empresas",
  //       action: "access",
  //     },
  //     children: [
  //       {
  //         path: "dashboard",
  //         name: "empresas-adm-pco-dashboard",
  //         component: () => import("@/modules/pco/dashboard/views/Dashboard.vue"),
  //         meta: {
  //           layout: "administrativo-modulos",
  //           requiresAuth: true,
  //           resource: "empresas",
  //           action: "access",
  //         },
  //       },
  //     ],
  //   },
  //   {
  //     path: "/adm/empresas/informacoes/:uuid/modulos/avd",
  //     name: "empresas-adm-avd",
  //     component: () =>
  //       import(
  //         "@/modules/administrativo/modules/empresas/components/modulos/AVD.vue"
  //       ),
  //     meta: {
  //       layout: "administrativo-modulos",
  //       requiresAuth: true,
  //       resource: "empresas",
  //       action: "access",
  //     },
  //   },
  //   {
  //     path: "/adm/empresas/informacoes/:uuid/modulos/clientes",
  //     name: "empresas-adm-clientes",
  //     component: () =>
  //       import(
  //         "@/modules/administrativo/modules/empresas/components/modulos/Clientes.vue"
  //       ),
  //     meta: {
  //       layout: "administrativo-modulos",
  //       requiresAuth: true,
  //       resource: "empresas",
  //       action: "access",
  //     },
  //   },
  //   {
  //     path: "/adm/empresas/informacoes/:uuid/modulos/nine-box",
  //     name: "empresas-adm-nine-box",
  //     component: () =>
  //       import(
  //         "@/modules/administrativo/modules/empresas/components/modulos/MatrizNineBox.vue"
  //       ),
  //     meta: {
  //       layout: "administrativo-modulos",
  //       requiresAuth: true,
  //       resource: "empresas",
  //       action: "access",
  //     },
  //   },
  //   {
  //     path: "/adm/empresas/informacoes/:uuid/modulos/okr",
  //     name: "empresas-adm-okr",
  //     component: () =>
  //       import(
  //         "@/modules/administrativo/modules/empresas/components/modulos/OKR.vue"
  //       ),
  //     meta: {
  //       layout: "administrativo-modulos",
  //       requiresAuth: true,
  //       resource: "empresas",
  //       action: "access",
  //     },
  //   },
  //   {
  //     path: "/adm/empresas/informacoes/:uuid/modulos/plano-de-cargos-e-salarios",
  //     name: "empresas-adm-plano-de-cargos-e-salarios",
  //     component: () =>
  //       import(
  //         "@/modules/administrativo/modules/empresas/components/modulos/PlanoDeCargosESalarios.vue"
  //       ),
  //     meta: {
  //       layout: "administrativo-modulos",
  //       requiresAuth: true,
  //       resource: "empresas",
  //       action: "access",
  //     },
  //   },
  //   {
  //     path: "/adm/empresas/informacoes/:uuid/modulos/recrutamento-e-selecao",
  //     name: "empresas-adm-recrutamento-e-selecao",
  //     component: () =>
  //       import(
  //         "@/modules/administrativo/modules/empresas/components/modulos/RecrutamentoESelecao.vue"
  //       ),
  //     meta: {
  //       layout: "administrativo-modulos",
  //       requiresAuth: true,
  //       resource: "empresas",
  //       action: "access",
  //     },
  //   },
];
