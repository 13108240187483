
export default {
    experienciaProfissional: {
        funcao: '',
        empresa: '',
        descricao: '',
        inicio: null,
        fim: null,
        salario: null,
        trabalho_atual: "0",
        id_area: null,
        id_cargo: null,
    },
    experienciasProfissionais: [],
    quantidadeExperiencia: 0,
}