import restApi from '@axios'
import secureLS from 'secure-ls'

const ls = new secureLS({ isCompression: false, encodingType: '' });

export default {
    setForm({ commit }, data) {
        commit('setForm', data)
    },

    setCountQuestions({ commit }, data) {
        commit('setCountQuestions', data)
    },
    setQuestion({ commit }, data) {
        commit('setQuestion', data)
    },

    getFormsOfCollaborator({ commit }, uuidCollaborator = String) {
        return new Promise(function (resolve, reject) {
            restApi.get(`/ext/avd-formularios/get-forms-of-collaborator/${uuidCollaborator}`).then(response => {
                if (response.data) {
                    commit('setFormsOfCollaborator', response.data.data)
                    resolve(response.data.data)
                }

                reject('NAO_AUTORIZADO')
            }).catch(error => reject(error))
        })
    },
    getFormEvaluation({ dispatch, commit }, data) {
        return new Promise(function (resolve, reject) {
            restApi.get(data.url).then(response => {
                if (response.data) {

                    ls.set(`${response.data.data.tipo}-${response.data.data.uuid}-form`, response.data.data);

                    commit('setForm', response.data.data)
                    commit('setQuestion', response.data.data.data.perguntas[0])
                    dispatch('escalaItens/setEscalasItens', response.data.data.data.escalas, { root: true })

                    resolve(response.data.data)
                }

                reject('NAO_AUTORIZADO')
            }).catch(error => reject(error))
        })
    },
    /**
     * @description Busca por avaliações tecnicas do mês atual.
     * 
     * @param data - Object
     * @example - `{ batteryUuid: 'djfn54-5rrr3e4-dedw22-44dff', managerUuid: 'cgn54-fng584-5fhgb4-fngfj' }`
    */
    getTechnicalEvaluations({ commit }, data = Object) {
        return new Promise(function (resolve, reject) {
            restApi.get(`/ext/avd-formularios/get-technical-evaluation/${data.batteryUuid}/${data.managerUuid}`).then(response => {
                if (response.data) {
                    commit('setTechnicalEvaluations', response.data.data)
                    resolve(response.data.data)
                }

                reject('NAO_AUTORIZADO')
            }).catch(error => reject(error))
        })
    },
    /**
     * @description Busca pelos dados do formulário de avaliação tecnica.
     * 
     * @param data - Object
     * @example - `{ batteryUuid: 'djfn54-5rrr3e4-dedw22-44dff', managerUuid: 'cgn54-fng584-5fhgb4-fngfj', metaUuid: 'cgn54-fng584-5fhgb4-fngfj' }`
    */
    getFormTechnicalEvaluation({ commit }, data = Object) {
        return new Promise(function (resolve, reject) {
            restApi.get(`/ext/avd-formularios/get-form-technical-evaluation/${data.batteryUuid}/${data.managerUuid}/${data.metaUuid}`).then(response => {
                if (response.data) {
                    commit('setTechnicalEvaluations', response.data.data)
                    resolve(response.data.data)
                }

                reject('NAO_AUTORIZADO')
            }).catch(error => reject(error))
        })
    },
    /**
     * @description Salva os dados da avaliação tecnica.
     * 
     * @param data - Object
    */
    saveFormTechnicalEvaluation({ commit }, data = Object) {
        return new Promise(function (resolve, reject) {
            restApi.post('/ext/avd-formularios/save-form-technical-evaluation', data).then(response => {
                if (response.data) {
                    resolve(response.data.data)
                }

                reject('NAO_AUTORIZADO')
            }).catch(error => reject(error))
        })
    },
    /**
     * @description Valida se o usuário é gestor, se tem avaliações.
     * 
     * @param data - Object
    */
    validateFormEvaluation({ commit }, data = Object) {
        return new Promise(function (resolve, reject) {
            restApi.post('/ext/avd-formularios/validate-form-evaluation', data).then(response => {
                if (response.data) {
                    resolve(response.data.data)
                }

                reject('NAO_AUTORIZADO')
            }).catch(error => reject(error))
        })
    }
}