export default {
  setAvaliacao(state, data) {
    state.avaliacao = data;
  },
  setFullDataAvaliacao(state, data) {
    state.fullDataAvaliacao = data;
  },
  setColaboradores(state, data) {
    state.colaboradores = data;
  },
  resetAvaliacao(state, data) {
    state.avaliacao = data;
  },
  setAvaliacoes(state, data) {
    state.avaliacoes = data;
  },
  setFilias(state, data) {
    state.filiais = data;
  },
  setPainelMinhasAvaliacoes(state, data) {
    state.minhasAvaliacoes = data;
  },
  setPainelAvaliacoes(state, data) {
    state.avaliacoes = data;
  },
  setDadosTotalizadores(state, data) {
    state.dadostotalizadores = data;
  },
  setDataPainelDeBordo(state, data) {
    state.dataPainelDeBordo = data;
  },
  buscarVisualizarAvaliacao(state, data) {
    state.dataVisualizarAvaliacao = data;
  },
  setResponsaveis(state, data) {
    state.responsaveis = data;
  },
  setFiliaisDaAvaliacao(state, data) {
    state.filiaisDaAvaliacao = data;
  },
  setSetoresDaAvaliacao(state, data) {
    state.setoresDaAvaliacao = data;
  },
  setTimesDaAvaliacao(state, data) {
    state.timesDaAvaliacao = data;
  },
  setCargosDaAvaliacao(state, data) {
    state.cargosDaAvaliacao = data;
  },
  setUuidDaAvaliacao(state, data) {
    state.uuidDaAvaliacao = data;
  },
  setDataCompetencias(state, data) {
    for (let item of data) {
      state.dataCompetencias.push(item);
    }
  },

  // Comportamento

  setComportamentos(state, data) {
    state.comportamentos = data;
  },

  // Competencias

  setCompetencias(state, data) {
    state.competencias = data;
  },

  // Funções gerais
  setLoading(state) {
    state.loading = !state.loading;
  },

  // Modelo
  setModelos(state, data) {
    state.modelos = data;
  },
  setModeloSelecionado(state, data) {
    state.modeloSelecionado = data;
  },
  resetDatas(state) {
    state.AvdComportamental = [];
    state.AvdTecnica = [];
    state.competenciasComportamentais = [];
    state.comportamentosComportamentais = [];
    state.competenciasTecnicas = [];
    state.comportamentosTecnicas = [];
  },
  setAvdComportamental(state, data) {
    state.AvdComportamental = data;
  },
  setAvdTecnica(state, data) {
    state.setAvdTecnica = data;
  },
  setCompetenciasComportamentais(state, data) {
    state.competenciasComportamentais = data;
  },
  setCompetenciasComportamentaisSelect(state, data) {
    state.competenciasComportamentaisSelect = data;
  },
  resetAvdComportamentalData(state) {
    state.competenciasComportamentaisSelect = [];
    state.AvdComportamental = [];
  },
  setComportamentosComportamentais(state, data) {
    state.comportamentosComportamentais = data;
  },
  setCompetenciasTecnicas(state, data) {
    if(typeof data === "object") {
      state.competenciasTecnicas = Object.values(data);
    } else {
      state.competenciasTecnicas = data;
    }
  },
  setCompetenciasTecnicasSelect(state, data) {
    state.competenciasTecnicasSelect = data;
  },
  resetAvdTecnicaData(state) {
    state.competenciasTecnicasSelect = [];
    state.setAvdTecnica = [];
  },
  setComportamentosTecnicas(state, data) {
    state.comportamentosTecnicas = data;
  },
  setTabPainel(state, data) {
    state.tabPainel = data;
  },

  // Tabs formulário
  setTabsForm(state, data) {
    state.tabsForm = data;
  },
  setTabFormSelected(state, data) {
    state.tabFormSelected = data;
  },
  setCurrentStepper(state, data) {
    state.currentStepper = data;
  },
  setIsShowStepper(state, data) {
    state.isShowStepper = data;
  },

  
  
};
