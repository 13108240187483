export default {
  setEscalas(state, value) {
    state.escalas = value;
  },
  SET_LOADING(state) {
    state.loading = !state.loading;
  },
  setEscala(state, data) {
    state.escala = data;
  },
};
