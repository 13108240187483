const Painel = () => import("../views/Painel.vue");

export default [
  {
    path: "/pco/grupos",
    name: "pco-grupos",
    component: Painel,
    meta: {
      layout: "content",
      requiresAuth: true,
      resource: "pcoGrupos",
      action: "view",
    },
  },
];
