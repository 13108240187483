export default {
  filial: {
    nome: '',
    cnpj: '',
    email: '',
    celular: '',
    telefone: '',
    id_usuario: '',
    descricao: '',
    endereco: {
      cep: '',
      rua: '',
      numero: 0,
      bairro: '',
      complemento: '',
      uf: '',
      cidade: ''
    }
  },
  filiais: [],
  filiaisWithSetoresCargosTimes: [],
  loading: false
};
