
export default {
    getExperienciaProfissional(state) {
        return state.experienciaProfissional
    },
    getExperienciasProfissionais(state) {
        return state.experienciasProfissionais
    },
    getQuantidadeExperiencia(state) {
        return state.quantidadeExperiencia
    }
}