import restApi from '@axios'

export default {
    setIdiomas({ commit }, data) {
        commit('setIdiomas', data)
    },

    remove({ commit }, uuid) {
        return new Promise(function (resolve, reject) {
            restApi.delete(`/res-candidato-idioma/${uuid}`).then(response => {
                if (response.data) {
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.log(error)
                reject(error)
            })
        })
    },
    save({ commit }, data) {
        return new Promise(function (resolve, reject) {
            restApi.post(data.url, data.data).then(response => {
                if (response.data) {
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.log(error)
                reject(error)
            })
        })
    },

}