export default {
  getModelos: (state) => {
    return state.modelos;
  },
  getModelo: (state) => {
    return state.modelo;
  },
  getModeloLegiio: (state) => {
    return state.modeloLegiio;
  },
  getModeloPersonalizado: (state) => {
    return state.modeloPersonalizado;
  },

  // Configurações do módulo -------------------------------------------------
  getLoadings: (state) => {
    return state.loadings;
  },
  getStep: (state) => {
    return state.step;
  },
  getSteps: (state) => {
    return state.steps;
  },
  getDialogModalPergunta: (state) => {
    return state.dialogModalPergunta;
  },
  getCurrentPositionInSteps: (state) => {
    return state.steps[state.step - 1];
  },
  getClickedOnSave: (state) => {
    return state.clickedOnSave;
  },
  getProcessOfSave: (state) => {
    return state.processOfSave;
  },
};
