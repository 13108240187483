import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{attrs:{"app":"","color":"white","elevation":"3"}},[_c('div',{staticClass:"d-flex flex-row align-center"},[(_vm.empresa && _vm.empresa.avatar && _vm.alterAvatarOrName == false)?_c(VImg,{attrs:{"tag":"img","contain":"","max-height":"5em","max-width":"5em","src":_vm.empresa.avatar,"alt":_vm.empresa && _vm.empresa.nome ? _vm.empresa.nome : 'logo'},on:{"error":function($event){_vm.alterAvatarOrName = true}}}):_vm._e(),(_vm.alterAvatarOrName)?_c('h1',{staticClass:"text-h5 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.empresa.nome)+" ")]):_vm._e(),_c('p',{staticClass:"mb-0 ml-2 pa-0 text-no-wrap"},[_vm._v("- Vagas")])],1),_c(VSpacer),(_vm.user)?_c(VBtn,{attrs:{"color":"black","plain":"","link":"","tag":"a","type":"a","to":{ name: 'oportunidade' }}},[_vm._v(" Oportunidades ")]):_vm._e(),(_vm.user)?_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"black","plain":"","link":"","tag":"a","type":"a"}},'v-btn',attrs,false),on),[_vm._v(" Carreiras ")])]}}],null,false,2390336893)},[_c(VList,_vm._l((_vm.items),function(item,index){return _c(VListItem,{key:index,attrs:{"link":""}},[_c(VListItemAvatar,[_c(VImg,{attrs:{"src":item.foto,"contain":"","max-height":"2em","max-width":"2em"}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.nome))])],1)],1)}),1)],1):_vm._e(),(_vm.user)?_c(VMenu,{attrs:{"bottom":"","min-width":"200px","rounded":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"mx-4 grey",staticStyle:{"background-color":"#f6f2f3"},attrs:{"color":"grey","icon":"","x-large":""}},on),[_c(VAvatar,{attrs:{"color":"grey","size":"48"}},[(_vm.user && _vm.user.avatar)?_c(VImg,{attrs:{"src":_vm.user.avatar,"alt":_vm.user.nome}}):_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-account")])],1)],1)]}}],null,false,3773886790)},[_c(VCard,[_c(VListItemContent,{staticClass:"justify-center"},[_c('div',{staticClass:"mx-auto text-center"},[_c('h3',[_vm._v(_vm._s(_vm.user.nome))]),_c(VDivider,{staticClass:"my-3"}),_c(VBtn,{attrs:{"depressed":"","rounded":"","text":"","color":"info"}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v("mdi-pencil")]),_vm._v(" Editar perfil ")],1),_c(VDivider,{staticClass:"my-3"}),_c(VBtn,{attrs:{"depressed":"","rounded":"","text":"","color":"error"},on:{"click":function($event){return _vm.logoutUser()}}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v("mdi-exit-to-app")]),_vm._v(" Sair ")],1)],1)])],1)],1):_c(VBtn,{attrs:{"color":"accent","outlined":"","small":"","tag":"a","to":{ name: 'auth-login' }}},[_vm._v(" Logar/Registar ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }