import restApi from "@axios";

export default {
  initialize({ commit }) {
    return new Promise((resolve, reject) => {
      restApi
        .get("/pco-modelos/initialize")
        .then((response) => {
          if (response.data) {
            commit("setModelos", response.data.data);
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchBasicInformations({ commit }, uuid) {
    const dataLoading = {
      property: "loadingBasicInformations",
      value: true,
    };

    return new Promise((resolve, reject) => {
      commit("setLoadings", dataLoading);
      restApi
        .get(`/pco-modelos/get-modelo-with-responsable/${uuid}`)
        .then((response) => {
          if (response.data) {
            commit("setModelo", response.data.data);
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        })
        .finally(() => {
          dataLoading.value = false;
          commit("setLoadings", dataLoading);
        });
    });
  },
  save({ commit }, dados) {
    return new Promise((resolve, reject) => {
      restApi
        .post(`${dados.url}`, dados)
        .then((response) => {
          if (response.data != "NAO_AUTORIZADO") {
            resolve(response.data);
          }
          reject("NAO_AUTORIZADO");
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      reject(error);
    });
  },
  delete({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      restApi
        .delete(`/pco-modelos/${uuid}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }).catch((error) => {
      reject(error);
    });
  },

  getAllModelosByPadrao({ commit }) {
    return new Promise(function (resolve, reject) {
      restApi
        .get("/pco-modelos/get-all-modelos-by-padrao")
        .then((response) => {
          if (response.data) {
            commit("setModeloLegiio", response.data.data.modelo_legiio);
            commit(
              "setModeloPersonalizado",
              response.data.data.modelo_personalizado
            );
            resolve(response.data.data);
          } else {
            reject(response.data);
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  buscarModelosPorTipo({ commit }, data) {
    return new Promise(function (resolve, reject) {
      restApi
        .post("/pco-modelos/retorna-modelos-por-tipo", data)
        .then((response) => {
          if (response.data.data) {
            commit("setModelos", response.data.data);
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  // Configurações do módulo -------------------------------------------------
  /**
   * @description Seta os valores para determinada propriedade de Loadings definida na propriedade Property de data
   *
   * @param object data
   * @example { property: 'loadingFE', value: true }
   *
   * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
   */
  setLoadings({ commit }, data) {
    commit("setLoadings", data);
  },
  setStep({ commit }, num) {
    commit("setStep", num);
  },
  setDialogModalPergunta({ commit }, bool = Boolean) {
    commit("setDialogModalPergunta", bool);
  },
  setClickedOnSave({ commit }, bool = Boolean) {
    commit("setClickedOnSave", bool);
  },
  resetModelo({ commit }) {
    const data = {};

    commit("resetModelo", data);
  },
  setProcessOfSave({ commit }, bool) {
    commit("setProcessOfSave", bool);
  },
};
