export default {
  setPergunta(state, value) {
    state.pergunta = value;
  },
  setPerguntas(state, value) {
    state.perguntas = value;
  },

  setFE(state, value) {
    state.perguntas.FE = value;
  },
  setFAV(state, value) {
    state.perguntas.FI.FAV = value;
  },
  setBEN(state, value) {
    state.perguntas.FI.BEN = value;
  },
  setDES(state, value) {
    state.perguntas.FI.DES = value;
  },
  setMOT(state, value) {
    state.perguntas.FI.MOT = value;
  },

  resetPergunta(state, data) {
    state.pergunta = data;
  },
  resetPerguntas(state, data) {
    state.perguntas = data;
  },
};
