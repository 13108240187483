export default {
  usuario: {
    nome: '',
    email: '',
    foto: null,
    id_papel: null,
    aniversario: null,
    gestor: 0,
    coordenador: 0,
    senha: '',
    endereco: {
      cep: '',
      rua: '',
      numero: 0,
      bairro: '',
      complemento: '',
      uf: '',
      cidade: ''
    },
    relacionamento: {
      id_filial: null,
      id_setor: null,
      id_cargo: null,
      id_time: null,
    }
  },
  usuarios: [],
  responsaveis: [],
  /**
   * @description Variável utilizada na página de configuração do AVD
   */
  colaboradores: [],
  loading: false,
};
