const Modelo = () => import("../views/Modelo.vue");
const Formulario = () => import("../views/Formulario.vue");

export default [
  {
    path: "/avd/modelo",
    name: "avd-modelo-view",
    component: Modelo,
    meta: {
      layout: "content",
      requiresAuth: true,
      resource: "avaliacao-desempenho",
      action: "access",
    },
  },
  {
    path: "/avd/modelo/formulario/:uuid/:acao",
    name: "avd-modelo-formulario",
    component: Formulario,
    meta: {
      layout: "content",
      requiresAuth: true,
      resource: "avaliacao-desempenho",
      action: "access",
    },
  },
];
