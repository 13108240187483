import restApi from '@axios';

export default {
    setCandidatoFormacao({ commit }, data) {
        commit('setCandidatoFormacao', data)
    },
    setCandidatosFormacoes({ commit }, data) {
        commit('setCandidatosFormacoes', data)
    },
    setQuantidadeFormacao({ commit }, data) {
        commit('setQuantidadeFormacao', data)
    },

    resetCandidatoFormacao({ commit }) {
        const data = {
            id_formacao: null,
            instituicao: '',
            curso: '',
            inicio: null,
            fim: null,
            status: null,
        }

        commit('setCandidatoFormacao', data)
    },

    save({ commit }, data) {
        return new Promise(function (resolve, reject) {
            restApi.post(data.url, data.data).then(response => {
                if (response.data) {
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.log(error)
                reject(error)
            })
        })
    },
    findByColumns({ commit }, data) {
        return new Promise(function (resolve, reject) {
            restApi.post('/res-candidato-formacao/find-by-columns', data).then(response => {
                if (response.data) {
                    commit('setCandidatosFormacoes', response.data.data)
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.log(error)
                reject(error)
            })
        })
    },
    findByUuid({ commit }, uuid) {
        return new Promise(function (resolve, reject) {
            restApi.get(`/res-candidato-formacao/find-by-uuid/${uuid}`).then(response => {
                if (response.data) {
                    commit('setCandidatoFormacao', response.data.data)
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.log(error)
                reject(error)
            })
        })
    },
    remove({ commit }, uuid) {
        return new Promise(function (resolve, reject) {
            restApi.delete(`/res-candidato-formacao/${uuid}`).then(response => {
                if (response.data) {
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.log(error)
                reject(error)
            })
        })
    }
}