import restApi from '@axios'

export default {
    setCodigo({ commit }, data) {
        commit('setCodigo', data)
    },

    gerarCodigo({ commit }, data) {
        return new Promise(function (resolve, reject) {
            restApi.post('/codigo-seguranca', data).then(response => {
                if (response.data.data) {
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    }
}