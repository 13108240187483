export default {
  getColaborador(state) {
    return state.colaborador;
  },
  getColaboradores(state) {
    return state.colaboradores;
  },
  getDataSpreadsheets(state) {
    return state.dataSpreadsheets;
  },
  getListaGestores(state) {
    return state.listaGestores;
  },
  getListaColaboradores(state) {
    return state.listaColaboradores;
  },
};
