export default {
  //EMPRESA
  getEmpresas: (state) => {
    return state.empresas;
  },
  getEmpresaSelecionada: (state) => {
    return state.empresaSelecionada;
  },
  getEmpresa: (state) => {
    return state.empresa;
  },
  //FILIAL
  getEmpresaFiliais: (state) => {
    if (state.empresaFiliais == undefined) {
      return [];
    } else {
      return state.empresaFiliais;
    }
  },
  getEmpresaFiliaisSelect: (state) => {
    return state.empresaFiliaisSelect;
  },
  getLoading: (state) => {
    return state.loading;
  },
  // RESPONSAVEIS
  getEmpresaResponsaveis: (state) => {
    return state.empresaResponsaveis;
  },
  //CONTRATO
  getEmpresaContratos: (state) => {
    if (state.empresaContratos == undefined) {
      return [];
    } else {
      return state.empresaContratos;
    }
  },
  //PROPOSTA
  getEmpresaPropostas: (state) => {
    return state.empresaPropostas;
  },
  // SETORES
  getEmpresaSetores: (state) => {
    if (state.empresaSetores == undefined) {
      return [];
    } else {
      return state.empresaSetores;
    }
  },
  getEmpresaSetoresSelect: (state) => {
    return state.empresaSetoresSelect;
  },
  // CARGOS
  getEmpresaCargos: (state) => {
    if (state.empresaCargos == undefined) {
      return [];
    } else {
      return state.empresaCargos;
    }
  },
  getEmpresaCargosSelect: (state) => {
    return state.empresaCargosSelect;
  },
  // COMPETÊNCIAS
  getEmpresaCompetencias: (state) => {
    return state.empresaCompetencias;
  },
  // TIMES
  getEmpresaTimes: (state) => {
    if (state.empresaTimes == undefined) {
      return [];
    } else {
      return state.empresaTimes;
    }
  },
  // USUARIOS
  getEmpresaUsuarios: (state) => {
    if (state.empresaUsuarios == undefined) {
      return [];
    } else {
      return state.empresaUsuarios;
    }
  },
  getEmpresaUsuariosSelect: (state) => {
    if (state.empresaUsuariosSelect == undefined) {
      return [];
    } else {
      return state.empresaUsuariosSelect;
    }
  },
  getEmpresaUsuariosCoordenadores: (state) => {
    if (state.empresaUsuarios == undefined) {
      return [];
    } else {
      let arrayCoordenadores = [];
      state.empresaUsuarios.forEach((usuario) => {
        if (usuario.coordenador === 1 || usuario.gestor === 1)
          arrayCoordenadores.push(usuario);
      });
      return arrayCoordenadores;
    }
  },
  // GRUPOS DE ACESSO
  getEmpresaGruposDeAcesso: (state) => {
    return state.empresaGruposDeAcesso;
  },
  // GESTORES
  getEmpresaGestores: (state) => {
    let gestores = [];
    state.empresaUsuarios.forEach((usuario) => {
      if (usuario.gestor == 1) gestores.push(usuario);
    });
    return gestores;
  },
  // GRUPOS DE ACESSO
  getEmpresaGruposDeAcesso: (state) => {
    return state.empresaGruposDeAcesso;
  },
  // LOADINGS FORMULARIO EXTERNO EMPRESA
  getLoadingModalCnpj: (state) => {
    return state.loadings.loadingModalCnpj;
  },
  getLoadingModalDados: (state) => {
    return state.loadings.loadingModalDados;
  },
  getLoadingFinalizacao: (state) => {
    return state.loadings.loadingFinalizacao;
  },
  getDialogModalCnpj: (state) => {
    return state.dialogModalCnpj;
  },
  getTabsFormularioEmpresa: (state) => {
    return state.tabsFormularioEmpresa;
  },
  getTabsInformacoesEmpresa: (state) => {
    return state.tabsInformacoesEmpresa;
  },
  getTabsModulosEmpresa: (state) => {
    return state.tabsModulosEmpresa;
  },
  getTabsPcoCrudsEmpresa: (state) => {
    return state.pcoCruds;
  },
  getTabsAvdCrudsEmpresa: (state) => {
    return state.avdCruds;
  },
  getEmpresasAtivas: (state) => {
    return state.empresasAtivas;
  },
  getListaEmpresas: (state) => {
    return state.listaEmpresa;
  },
  getRoles: (state) => {
    return state.roles;
  },
  getModulosEmpresa: (state) => {
    return state.modulosEmpresa;
  },

  getTabsPcoCrudsEmpresa: (state) => {
    return state.pcoCruds;
  },

  // LAYOUT EMPRESA MODULOS
  getDatasLayoutModulos: (state) => {
    return state.datasLayoutModulos;
  },
};