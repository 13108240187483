const Clientes = () => import("../views/Clientes.vue");

export default [
  {
    path: "/clientes",
    name: "Clientes",
    component: Clientes,
    meta: {
      layout: "content",
      requiresAuth: true,
      resource: "cliente",
      action: "access",
    },
  },
];
