export default {
  modelos: [],
  modelo: {},
  modeloPersonalizado: [],
  modeloLegiio: [],

  // Configurações do módulo -------------------------------------------------
  // Step do stepper do formulário
  step: 1,
  /**
   * @description Renderiza o stepper e seus componentes no formulário do pcoModelo
   *
   * Role
   * - limitOfQuestions: Valida se existe algum limite de perguntas no step
   * - validateToProceed: Valida se é necessário fazer uma validação com Validator na etapa especifica do step antes de prosseguir
   * - validateToGoBack: Valida se é necessário fazer uma validação com Validator na etapa especifica do step antes de retroceder
   * - saveModelByProcess: Valida é necessário salvar o modelo antes de prosseguir
   *
   * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
   */
  steps: [
    {
      id: 1,
      title: "Informações básicas",
      description: "Dados para identificação do modelo.",
      component: () =>
        import(
          "@/modules/pco/modelos/components/formularios/BasicInformations.vue"
        ),
      role: {
        limitOfQuestions: null,
        validateToProceed: true,
        validateToGoBack: false,
        saveModelByProcess: true,
      },
    },
    {
      id: 2,
      title: "Fatores externos",
      type: "FE",
      description:
        'Os Fatores Externos são perguntas de qualquer tipo: texto aberto, opção única e multipla escolha. As de opção únida e multipla escolha serão utilizadas para filtrar os resultados dos Fatores Internos (Favorabilidade, Benefícios, Motivacional e Desmotivacional), então investigar categorias como idade, escolaridade, estado civil seriam interessantes aqui. As de texto aberto serão apresentados de forma individual para análise qualitativa, poderia ser algo como "Dê sugestões para melhorar a empresa".',
      form: () =>
        import("@/modules/pco/modelos/components/formularios/FormFE.vue"),
      role: {
        limitOfQuestions: null,
        validateToProceed: false,
        validateToGoBack: false,
        saveModelByProcess: false,
      },
    },
    {
      id: 3,
      title: "Fatores Internos - Favorabilidade",
      type: "FI",
      subcategory: "FAV",
      description:
        'O Fator Interno Favorabilidade é investigado através de perguntas do tipo "opção única" onde se apresenta uma mesma escala de opções para todas as perguntas. Seu resultado apresenta a favorabilidade, ou desfavorabilidade do que foi descrito na pergunta. Cada pergunta deve ser uma frase afirmativa para que o colaborador possa interpretar e marcar a opção de interesse, e também deve pertencer a um Grupo coerente com a frase descrita. Exemplo: <br> Pergunta: Conheço os objetivos e estratégias da minha área de trabalho. <br> Escala: Concordo...Discordo <br> Grupo: Planejamento.',
      form: () =>
        import(
          "@/modules/pco/modelos/components/formularios/FormFIFavorabilidade.vue"
        ),
      role: {
        limitOfQuestions: null,
        validateToProceed: false,
        validateToGoBack: false,
        saveModelByProcess: false,
      },
    },
    {
      id: 4,
      title: "Fatores Internos - Benefícios",
      type: "FI",
      subcategory: "BEN",
      description:
        'O Fator Interno Benefícios é investigado através de uma única pergunta do tipo "multipla escolha" onde se lista nas opções todos os benefícios ofertados pela empresa. A descrição da pergunta deve orientar o colaborador a selecionar em ordem de prioridade cada um dos benefícios. OBS: Não é possível limitar a quantidade de opções a serem selecionadas.',
      form: () =>
        import(
          "@/modules/pco/modelos/components/formularios/FormFIBeneficio.vue"
        ),
      role: {
        limitOfQuestions: 1,
        validateToProceed: false,
        validateToGoBack: false,
        saveModelByProcess: false,
      },
    },
    {
      id: 5,
      title: "Fatores Internos - Desmotivacional",
      type: "FI",
      subcategory: "DES",
      description:
        'O Fator Interno Desmotivacional é investigado através de uma única pergunta do tipo "multipla escolha" onde se lista nas opções todos as possíveis razões que possam justificar a insatisafação dos colaboradores. OBS: É possível limitar a quantidade de opções a serem selecionadas.',
      form: () =>
        import(
          "@/modules/pco/modelos/components/formularios/FormFIDesmotivacional.vue"
        ),
      role: {
        limitOfQuestions: 1,
        validateToProceed: false,
        validateToGoBack: false,
        saveModelByProcess: false,
      },
    },
    {
      id: 6,
      title: "Fatores Internos - Motivacional",
      type: "FI",
      subcategory: "MOT",
      description:
        'O Fator Interno Motivacional é investigado através de uma única pergunta do tipo "multipla escolha" onde se lista nas opções todos as possíveis razões que possam justificar a satisfação dos colaboradores. OBS: É possível limitar a quantidade de opções a serem selecionadas.',
      form: () =>
        import(
          "@/modules/pco/modelos/components/formularios/FormFIMotivacional.vue"
        ),
      role: {
        limitOfQuestions: 1,
        validateToProceed: false,
        validateToGoBack: false,
        saveModelByProcess: false,
      },
    },
  ],
  // Manipula o aparecimento ou não do component ModalPergunta
  dialogModalPergunta: false,
  clickedOnSave: false,
  loadings: {
    loadingModalPergunta: false,
    loadingBasicInformations: false,
    loadingFE: false,
    loadingFAV: false,
    loadingBEN: false,
    loadingDES: false,
    loadingMOT: false,
  },
  // Muda quando uma pergunta estiver em processo de salvamento/atualização.
  processOfSave: false,
};
