
import restApi from '@axios'

export default {
    setExperiencia({ commit }, data) {
        commit('setExperiencia', data)
    },
    initialize({ commit }) {
        return new Promise(function (resolve, reject) {
            restApi.get('/res-tempo-experiencia/initialize').then(response => {
                if (response.data) {
                    commit('setExperiencias', response.data.data)
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },
    save({ commit }, data) {
        return new Promise(function (resolve, reject) {
            restApi.post(data.url, data).then(response => {
                if (response.data) {
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },
    findByUuid({ commit }, uuid) {
        return new Promise(function (resolve, reject) {
            restApi.get(`/res-tempo-experiencia/find-by-uuid/${uuid}`).then(response => {
                if (response.data) {
                    commit('setExperiencia', response.data.data)
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },
    remove({ commit }, uuid) {
        return new Promise(function (resolve, reject) {
            restApi.delete(`/res-tempo-experiencia/${uuid}`).then(response => {
                if (response.data) {
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },
}