export default {
  cliente: {
    nome: null,
    email: null,
    razao_social: null,
    inscricao_estadual: null,
    inscricao_municipal: null,
    celular: null,
    telefone: null,
    abertura: null,
    descricao: null,
    logotipo: null,
    status: "A",
  },
  clientes: [],
  loading: false,
};
