import ExternaEmpresasModuleRouter from "../modules/router/externo";
import ModulosEmpresaRouter from "../components/modulos/router";

export default [
  {
    path: "/adm/empresas",
    name: "empresas-adm",
    component: () =>
      import("@/modules/administrativo/modules/empresas/components/Painel.vue"),
    meta: {
      layout: "content",
      requiresAuth: true,
      resource: "configuracoes",
      action: "access",
    },
  },
  {
    path: "/adm/empresas/informacoes/:uuid",
    name: "empresas-adm-info",
    component: () =>
      import(
        "@/modules/administrativo/modules/empresas/components/Informacoes.vue"
      ),
    meta: {
      layout: "content",
      requiresAuth: true,
      resource: "configuracoes",
      action: "access",
    },
  },
  {
    path: "/adm/empresas/informacoes/cargos/competencias",
    name: "empresas-adm-info-cargos-competencias",
    component: () =>
      import(
        "@/modules/administrativo/modules/empresas/components/Competencias.vue"
      ),
    meta: {
      layout: "content",
      requiresAuth: true,
      resource: "configuracoes",
      action: "access",
    },
  },
  {
    path: "/ext",
    component: () => import("../views/ExternoEmpresaView.vue"),
    meta: {
      name: "externo-formulario-view",
      requiresAuth: false,
    },
    children: [...ExternaEmpresasModuleRouter],
  },
  ...ModulosEmpresaRouter,
];
