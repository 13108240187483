
export default [
    {
        path: '/ext/empresas/formulario/cadastro/:uid',
        name: 'ext-empresas-formulario-cadastro',
        component: () => import('@/modules/externo/modules/empresas/views/Formulario.vue'),
        meta: {
            layout: 'blank',
            resource: 'Public',
        }
    }
    
]