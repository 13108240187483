import Formulario from "../views/Formulario.vue";

export default [
  {
    path: "cadastro-empresa",
    name: "formulario-pesquisa",
    meta: {
      requiresAuth: false,
    },
    component: Formulario,
}
];