
export default [
    {
        path: '/res/configuracao/cargos',
        name: 'cargo-res',
        meta: {
            layout: "content",
            requiresAuth: true,
            resource: 'res-configuracao',
            action: 'access',
        },
        component: () => import(`@/modules/res/configuracao/views/Cargo.vue`),
    },
    {
        path: '/res/configuracao/categorias',
        name: 'categoria-res',
        meta: {
            layout: "content",
            requiresAuth: true,
            resource: 'res-configuracao',
            action: 'access',
        },
        component: () => import(`@/modules/res/configuracao/views/Categoria.vue`),
    },
    {
        path: '/res/configuracao/competencias',
        name: 'competencia-res',
        meta: {
            layout: "content",
            requiresAuth: true,
            resource: 'res-configuracao',
            action: 'access',
        },
        component: () => import(`@/modules/res/configuracao/views/Competencia.vue`),
    },
    {
        path: '/res/configuracao/cursos',
        name: 'curso-res',
        meta: {
            layout: "content",
            requiresAuth: true,
            resource: 'res-configuracao',
            action: 'access',
        },
        component: () => import(`@/modules/res/configuracao/views/Curso.vue`),
    },
    {
        path: '/res/configuracao/area-atuacoes',
        name: 'area-atuacao-res',
        meta: {
            layout: "content",
            requiresAuth: true,
            resource: 'res-configuracao',
            action: 'access',
        },
        component: () => import(`@/modules/res/configuracao/views/AreaAtuacao.vue`),
    },
    {
        path: '/res/configuracao/deficiencias',
        name: 'deficiencia-res',
        meta: {
            layout: "content",
            requiresAuth: true,
            resource: 'res-configuracao',
            action: 'access',
        },
        component: () => import(`@/modules/res/configuracao/views/Deficiencia.vue`),
    },
    {
        path: '/res/configuracao/estados-civies',
        name: 'estado-civil-res',
        meta: {
            layout: "content",
            requiresAuth: true,
            resource: 'res-configuracao',
            action: 'access',
        },
        component: () => import(`@/modules/res/configuracao/views/EstadoCivil.vue`),
    },
    {
        path: '/res/configuracao/formacoes',
        name: 'formacao-res',
        meta: {
            layout: "content",
            requiresAuth: true,
            resource: 'res-configuracao',
            action: 'access',
        },
        component: () => import(`@/modules/res/configuracao/views/Formacao.vue`),
    },
    {
        path: '/res/configuracao/idiomas',
        name: 'idioma-res',
        meta: {
            layout: "content",
            requiresAuth: true,
            resource: 'res-configuracao',
            action: 'access',
        },
        component: () => import(`@/modules/res/configuracao/views/Idioma.vue`),
    },
    {
        path: '/res/configuracao/tipo-vagas',
        name: 'tipo-vaga-res',
        meta: {
            layout: "content",
            requiresAuth: true,
            resource: 'res-configuracao',
            action: 'access',
        },
        component: () => import(`@/modules/res/configuracao/views/TipoVaga.vue`),
    },
]