import restApi from '@axios'

export default {
    /**
     * @description Envia requisição para a duplicação da avaliação e dados relacionados.
     * 
     * @param data - Object
     * @example - `{ uuid: 'fndj54-5tjf-4idhfnuj3-dfjdf', data: { configuracao_comportamental: false, competencia: false, meta: false } }`
     * 
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
    */
    duplicateEvaluation({ commit }, data) {
        return new Promise(function (resolve, reject) {
            restApi.post(`/avd/avaliacoes/duplicate-evaluation/${data.uuid}`, data.data).then(response => {
                if (response.data) {
                    resolve(response.data.data)
                }

                reject('NAO_AUTORIZADO')
            }).catch(error =>
                reject(error)
            )
        })
    }
}