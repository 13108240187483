const Comportamento = () => import("../views/Comportamento.vue");

export default [
  {
    path: "/avd/comportamentos",
    name: "avd-comportamentos-view",
    component: Comportamento,
    meta: {
      layout: "content",
      requiresAuth: true,
      resource: "avaliacao-desempenho",
      action: "access",
    },
  },
];
