export default {
  getCliente: (state) => {
    return state.cliente;
  },
  getClientes: (state) => {
    return state.clientes;
  },
  getLoading: (state) => {
    return state.loading;
  },
};
