export default {
  setCliente(state, value) {
    state.cliente = value;
  },
  setClientes(state, value) {
    state.clientes = value;
  },
  SET_LOADING(state) {
    state.loading = !state.loading;
  },
};
