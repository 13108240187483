export default {
  //EMPRESA
  setEmpresas(state, value) {
    state.empresas = value;
  },
  setEmpresaSelecionada(state, value) {
    state.empresaSelecionada = value;
  },
  setEmpresa(state, value) {
    state.empresa = value;
  },
  setDeletarEmpresa(state, uuid) {
    state.empresas = state.empresas.filter((empresa) => empresa.uuid !== uuid);
  },
  //FILIAL
  setEmpresaFiliais(state, value) {
    state.empresaFiliais = value;
  },
  setEmpresaFiliaisSelect(state, value) {
    if (value.every((f) => f.status !== null && f.status !== undefined)) {
      let array = value.filter((f) => f.status === "A");
      array.push({ id: 0, nome: "Matriz" });
      state.empresaFiliaisSelect = array;
    } else {
      state.empresaFiliaisSelect = value;
      state.empresaFiliaisSelect.push({ id: 0, nome: "Matriz" });
    }
  },
  // RESPONSAVEIS
  setEmpresaResponsaveis(state, value) {
    state.empresaResponsaveis = value;
  },
  //CONTRATO
  setEmpresaContratos(state, value) {
    state.empresaContratos = value;
  },
  //PROPOSTA
  setEmpresaPropostas(state, value) {
    state.empresaPropostas = value;
  },
  // LOADING
  SET_LOADING(state) {
    state.loading = !state.loading;
  },
  // SETORES
  setEmpresaSetores(state, value) {
    state.empresaSetores = value;
  },
  setEmpresaSetoresSelect(state, value) {
    if (value.every((f) => f.status !== null && f.status !== undefined)) {
      let array = value.filter((f) => f.status === "A");
      state.empresaSetoresSelect = array;
    } else {
      state.empresaSetoresSelect = value;
    }
  },
  // CARGOS
  setEmpresaCargos(state, value) {
    state.empresaCargos = value;
  },
  setEmpresaCargosSelect(state, value) {
    if (value.every((f) => f.status !== null && f.status !== undefined)) {
      let array = value.filter((f) => f.status === "A");
      state.empresaCargosSelect = array;
    } else {
      state.empresaCargosSelect = value;
    }
  },
  deletarEmpresaSetores(state, value) {
    state.empresaCargos = state.empresaCargos.filter(
      (element) => element.uuid !== value
    );
  },
  // COMPETÊNCIAS
  setEmpresaCompetencias(state, value) {
    state.empresaCompetencias = value;
  },
  // TIMES
  setEmpresaTimes(state, value) {
    state.empresaTimes = value;
  },
  // USUARIOS
  setEmpresaUsuarios(state, value) {
    state.empresaUsuarios = value;
  },
  setEmpresaUsuariosSelect(state, value) {
    if (value.every((f) => f.status !== null && f.status !== undefined)) {
      let array = value.filter((f) => f.status === "A");
      state.empresaUsuariosSelect = array;
    } else {
      state.empresaUsuariosSelect = value;
    }
  },
  // GRUPOS DE ACESSO
  setEmpresaGruposDeAcesso(state, value) {
    state.empresaGruposDeAcesso = value;
  },
  // LOADINGS FORMULARIO EXTERNO EMPRESA
  setLoadingModalCnpj(state, value) {
    state.loadings.loadingModalCnpj = value;
  },
  setLoadingModalDados(state, value) {
    state.loadings.loadingModalDados = value;
  },
  setLoadingFinalizacao(state, value) {
    state.loadings.loadingFinalizacao = value;
  },
  setDialogModalCnpj(state, value) {
    state.dialogModalCnpj = value;
  },
  setEmpresasAtivas(state, value) {
    state.empresasAtivas = value;
  },
  setListaEmpresas(state, value) {
    state.listaEmpresa = value;
  },
  setRoles(state, value) {
    state.roles = value;
  },

  // LAYOUT EMPRESA MODULOS
  setDatasLayoutModulos(state, data) {
    state.datasLayoutModulos = data;
  },
};
