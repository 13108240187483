import restApi from '@axios'

export default {
    setObjetivoProfissional({ commit }, data) {
        commit('setObjetivoProfissional', data)
    },
    /**
    * @description Salva Objetivo profissional
    * 
    * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
   */
    save({ commit }, data) {
        return new Promise(function (resolve, reject) {
            restApi.post(data.url, data.data).then(response => {
                if (response.data) {
                    resolve(response.data.data)
                } else {
                    reject('NAO_AUTORIZADO')
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },
}