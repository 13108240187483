export default {
  SET_LOADING(state) {
    state.loading = !state.loading;
  },
  SET_PESQUISAS(state, data) {
    state.dataPesquisas = data;
  },
  SET_PESQUISA(state, data) {
    state.pesquisa = data;
  },
  SET_RESPONSAVEIS(state, data) {
    state.dataResponsaveis = data;
  },
  SET_PESQUISA_SELECIONADA(state, data) {
    state.dataPesquisaSelecionada = data;
  },
  SET_MODELOS_LEGIIO(state, data) {
    state.dataModeloslegiio = data;
  },
  SET_MODELOS_PERSONALIZADOS(state, data) {
    state.dataModelosPersonalizados = data;
  },
  SET_DADOS_PAINEL(state, data) {
    state.dadosPainel = data;
  },
  setDadosPesquisaExterna(state, value) {
    state.dadosPesquisaExterna = value;
  },
  setLoadingPesquisaExterna(state, value) {
    state.loadingPesquisaExterna = value;
  },
  setPesquisaExterna(state, value) {
    state.pesquisaExterna = value;
  },
  setPesquisaDialogExterna(state, value) {
    state.pesquisaDialogExterna = value;
  },
  setHabilitarFormulario(state, value) {
    state.habilitarFormulario = value;
  },
  setRespostas(state, value) {
    state.respostas = value;
  },
  setErrorPesquisa(state, value) {
    state.errorPesquisa = value;
  },
  setPesquisaFinalizadaDialogExterna(state, value) {
    state.pesquisaFinalizadaDialogExterna = value;
  },
  setPesquisaVencidaDialogExterna(state, value) {
    state.pesquisaVencidaDialogExterna = value;
  },
  setPesquisaExternaVencidaMessage(state, value) {
    state.pesquisaExternaVencidaMessage = value;
  },
  setFiltros(state, value) {
    state.filtros = value;
  },
  setPesquisasEstatisticsData(state, value) {
    state.statisticsData = value;
  },
  setArrayPerguntas(state, value) {
    state.arrayPerguntas = value;
  },
  setTab(state, value) {
    state.tab = value;
  },
  atualizaTabs(state, value) {
    let index;
    if (value && value != null && value.FE && value.FE.length > 0) {
      index = state.tabsCategorias.findIndex(
        (element) => element.scopeValidation == "FE"
      );
      if (state.tabsCategorias[index] !== undefined) {
        state.tabsCategorias[index].disabled = false;
        state.tabsCategorias[index].perguntas = value.FE.length;
      }
      state.tab.push(index);
    }
    if (value && value != null && value.FI.FAV && value.FI.FAV.length > 0) {
      index = state.tabsCategorias.findIndex(
        (element) => element.scopeValidation == "FAV"
      );
      if (state.tabsCategorias[index] !== undefined) {
        state.tabsCategorias[index].disabled = false;
        state.tabsCategorias[index].perguntas = value.FI.FAV.length;
      }
      state.tab.push(index);
    }
    if (value && value != null && value.FI.BEN && value.FI.BEN.length > 0) {
      index = state.tabsCategorias.findIndex(
        (element) => element.scopeValidation == "BEN"
      );
      if (state.tabsCategorias[index] !== undefined) {
        state.tabsCategorias[index].disabled = false;
        state.tabsCategorias[index].perguntas = value.FI.BEN.length;
      }
      state.tab.push(index);
    }
    if (value && value != null && value.FI.MOT && value.FI.MOT.length > 0) {
      index = state.tabsCategorias.findIndex(
        (element) => element.scopeValidation == "MOT"
      );
      if (state.tabsCategorias[index] !== undefined) {
        state.tabsCategorias[index].disabled = false;
        state.tabsCategorias[index].perguntas = value.FI.MOT.length;
      }
      state.tab.push(index);
    }
    if (value && value != null && value.FI.DES && value.FI.DES.length > 0) {
      index = state.tabsCategorias.findIndex(
        (element) => element.scopeValidation == "DES"
      );
      if (state.tabsCategorias[index] !== undefined) {
        state.tabsCategorias[index].disabled = false;
        state.tabsCategorias[index].perguntas = value.FI.DES.length;
      }
      state.tab.push(index);
    }
  },
  setTotalizadoresPainel(state, value) {
    state.totalizadoresPainel = value;
  },
  setBeneficiosPainel(state, value) {
    state.beneficiosPainel = value;
  },
  setExternasPainel(state, value) {
    state.externasPainel = value;
  },
  setMotivacionalPainel(state, value) {
    state.motivacionalPainel = value;
  },
  setDesmotivacionalPainel(state, value) {
    state.desmotivacionalPainel = value;
  },
  setGruposPainel(state, value) {
    state.gruposPainel = value;
  },
  setFavorabilidadeGeralPainel(state, value) {
    state.favorabilidadeGeralPainel = value;
  },

  setFidelidadePainel(state, value) {
    state.fidelidade = value;
  },
  setLoadingFidelidade(state, value) {
    state.loadingsPainel.loadingFidelidade = value;
  },
  setFavorabilidadePainel(state, value) {
    state.favorabilidadePainel = value;
  },
  setDadosPesquisaExternaInternas(state, value) {
    state.dadosPesquisaExternaInternas = value;
  },
  setListaPerguntasAbertas(state, value) {
    state.listaPerguntasAbertas = value;
  },

  // ModalCadastroPerguntas
  setDialogModalCadastroPerguntas(state, bool) {
    state.dialogModalCadastroPerguntas = bool;
  },
  setCategoriaModalCadastroPerguntas(state, data) {
    state.categoriaModalCadastroPerguntas = data;
  },

  // ModalDeletePergunta
  setDialogDeletePergunta(state, bool) {
    state.dialogDeletePergunta = bool;
  },
  setPesquisaCodigoDialog(state, value) {
    state.dialogCode = value;
  },
  setPesquisaCodigoExternaMessage(state, value) {
    state.messageCode = value;
  },
  setPesquisaExternaDescricao(state, value) {
    state.descricaoPesquisa = value;
  },
  setPesquisaExternaComponenteInicial(state, value) {
    state.componenteInicial = value;
  },
  setPesquisaExternaComponenteFormulario(state, value) {
    state.componenteFormulario = value;
  },
  setPesquisaExternaPerguntas(state, value) {
    state.perguntasFormularioExterno = value;
  },
  setPesquisaExternaIndex(state, value) {
    state.questionIndex = value;
  },
  setPCOLoadingEstruturaFormulario(state, value) {
    state.loadingEstruturaFormulario = value;
  },
  setPCOUltimaPerguntaRespondida(state, value) {
    state.ultimaPerguntaRespondida = value;
  },
  setPcoMensagemRetorno(state, value) {
    state.mensagemRetorno = value;
  },

  // Configuração pesquisa
  setModalPerguntaSelecionada(state, data) {
    state.modalPerguntaSelecionada = data;
  },
  setLoadingExportacao(state, value) {
    state.loadingsPainel.loadingExport= value;
  },
};
