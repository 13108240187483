import restApi from "@axios";
import SecureLS from "secure-ls";
let ls = new SecureLS();
export default {
  buscaMenus({ commit }) {
    return new Promise((resolve, reject) => {
      restApi
        .get("/grupo-acesso/initialize")
        .then((response) => {
          commit("setMenus", response.data.data.menu);
          ls.set("menu", response.data.data.menu);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {});
    });
  },
  carregarMenus({ commit }) {
    return new Promise((resolve, reject) => {
      commit("setLoadingMenu", true);
      restApi
        .get("/menu/busca-menus-cadastrados")
        .then((response) => {
          commit("setListaMenus", response.data.data.menus);
          let listaMenu = response.data.data.menus.map(function (elem) {
            return { id: elem.id, nome: elem.nome };
          });
          commit("setItensMenus", listaMenu);

          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("setLoadingMenu", false);
        });
    });
  },
  carregarMenusPorColunas({ commit }, columns = Array) {
    return new Promise(function (resolve, reject) {
      restApi
        .post("/menu/buscar-menus-por-coluna", { columns })
        .then((response) => {
          if (response) {
            commit("setMenusPorColunas", response.data.data);
            resolve(response);
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  salvarMenu({ commit }, dados) {
    return new Promise(function (resolve, reject) {
      restApi
        .post(`${dados.menuItem.url}`, dados.menuItem)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  },
  removerMenu({ commit }, uuid) {
    return new Promise(function (resolve, reject) {
      restApi
        .delete("/menu/" + uuid)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  },
  carregarGruposAcesso({ commit }) {
    return new Promise((resolve, reject) => {
      commit("setLoadingGrupoAcesso", true);
      restApi
        .get("/grupo-acesso/busca-grupos-acesso")
        .then((response) => {
          commit("setGruposAcesso", response.data.data.grupos);
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("setLoadingGrupoAcesso", false);
        });
    });
  },
  salvarGrupoAcesso({ commit }, dados) {
    return new Promise((resolve, reject) => {
      restApi
        .post(`${dados.item.url}`, dados.item)
        .then((response) => {
          console.log(response);
          resolve(response.data.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  removerGrupoAcesso({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      restApi
        .delete("/grupo-acesso/" + uuid)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  buscarSetores({ commit }) {
    return new Promise((resolve, reject) => {
      commit("setLoadingSetores", true);
      restApi
        .get("/setor")
        .then((response) => {
          commit("setSetores", response.data.data.data);
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("setLoadingSetores", false);
        });
    });
  },
  buscarCargos({ commit }) {
    return new Promise((resolve, reject) => {
      commit("setLoadingCargos", true);
      restApi
        .get("/cargos")
        .then((response) => {
          commit("setCargos", response.data.data.data);
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("setLoadingCargos", false);
        });
    });
  },
  buscarEquipes({ commit }) {
    return new Promise((resolve, reject) => {
      commit("setLoadingEquipes", true);
      restApi
        .get("/equipes")
        .then((response) => {
          commit("setEquipes", response.data.data.data);
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("setLoadingEquipes", false);
        });
    });
  },
  buscarPapeis({ commit }) {
    return new Promise((resolve, reject) => {
      commit("setLoadingPapeis", true);
      restApi
        .get("/papel/initialize")
        .then((response) => {
          commit("setPapeis", response.data.data);
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("setLoadingPapeis", false);
        });
    });
  },
  salvarPapel({ commit }, dados) {
    return new Promise((resolve, reject) => {
      restApi
        .post(`${dados.objeto.url}`, dados.objeto)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  },
  removerPapel({ commit }, id) {
    return new Promise((resolve, reject) => {
      restApi
        .delete("/papel/" + id)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  },
  setMenus({ commit }, value) {
    commit("setMenus", value);
  },
  buscarPermissoes({ commit }) {
    return new Promise((resolve, reject) => {
      commit("setLoadingPermissoes", true);
      restApi
        .get("/permissao/initialize")
        .then((response) => {
          commit("setPermissoes", response.data.data.permissoes);
          commit("setLoadingPermissoes", false);
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("setLoadingPermissoes", false);
        });
    });
  },
  salvarPermissao({ commit }, dados) {
    return new Promise((resolve, reject) => {
      restApi
        .post(`${dados.objeto.url}`, dados.objeto)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  removerPermissao({ commit }, id) {
    return new Promise((resolve, reject) => {
      restApi
        .delete("/permissao/" + id)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
};
