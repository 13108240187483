import restApi from "@axios";

export default {
  setTime({ commit }, data) {
    commit('setTime', data)
  },
  initialize({ commit }) {
    return new Promise(function (resolve, reject) {
      restApi
        .get("/times/initialize")
        .then((response) => {
          if (response.data) {
            commit("setTimes", response.data.data);
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  findByColumns({ commit }, data) {
    return new Promise(function (resolve, reject) {
      restApi
        .post("/times/find-by-columns", data)
        .then((response) => {
          if (response.data) {
            commit("setTimes", response.data.data);
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  findByUuid({ commit }, uuid) {
    return new Promise(function (resolve, reject) {
      restApi
        .get(`/times/find-by-uuid/${uuid}`)
        .then((response) => {
          if (response.data) {
            commit("setTime", response.data.data);
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  save({ commit }, data) {
    return new Promise(function (resolve, reject) {
      restApi
        .post(data.url, data)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  remove({ commit }, uuid) {
    return new Promise(function (resolve, reject) {
      restApi
        .delete(`/times/${uuid}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject("NAO_AUTORIZADO");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
};
